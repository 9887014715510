import React from 'react';
import CourseProgressNav from './CourseProgressNav';

const MobileMenu = ({ 
  isOpen, 
  onClose, 
  progress, 
  modules, 
  openModules, 
  toggleModule, 
  handleLessonClick, 
  completedLessons, 
  course 
}) => {
  return (
    <div
      className={`fixed top-0 left-0 bottom-0 w-full z-50 h-full bg-white overflow-y-auto transition-all shadow-lg ${
        isOpen ? 'block' : 'hidden'
      }`}
    >
      <div
        className="fixed inset-0 bg-white-800 opacity-70"
        onClick={onClose}
        aria-label="Close Menu"
      />
      <nav className="relative flex flex-col py-6 px-6 w-full h-auto">
        <div className="flex mb-8 items-center justify-end">
          <button
            className="navbar-close"
            onClick={onClose}
            aria-label="Close Menu"
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 18L18 6M6 6L18 18"
                stroke="#111827"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        
        <CourseProgressNav
          progress={progress}
          modules={modules}
          openModules={openModules}
          toggleModule={toggleModule}
          handleLessonClick={handleLessonClick}
          completedLessons={completedLessons}
          course={course}
          isMobile={true}
          toggleMobileMenu={onClose}
        />
      </nav>
    </div>
  );
};

export default MobileMenu;

import { Card, CardContent } from "./card";
import { Brain, ShieldCheck, Code, Database, Layers } from "lucide-react";

const careerPaths = [
  { name: "Cyber Security", icon: <ShieldCheck size={40} />, description: "Protect systems from cyber threats and vulnerabilities." },
  { name: "Artificial Intelligence", icon: <Brain size={40} />, description: "Build smart systems using AI and machine learning techniques." },
  { name: "Java Development", icon: <Code size={40} />, description: "Create robust applications with Java programming." },
  { name: "Data Engineering", icon: <Database size={40} />, description: "Manage and optimize data workflows and infrastructure." },
  { name: "Data Structures & Algorithms", icon: <Layers size={40} />, description: "Master DSA for problem-solving and efficient coding." },
];

export default function CareerPath() {
  return (
    <div className="min-h-screen bg-gray-100 lg:-mt-16 p-10 flex flex-col items-center">
      <header className="w-full flex justify-between items-center mb-8 p-6 bg-white shadow-md rounded-lg">
        <div>
          <h1 className="text-3xl font-bold text-gray-800">Career Tracks</h1>
          <p className="text-gray-600">Explore the most popular career paths and elevate your skills to the next level.</p>
        </div>
        <div>
          <svg width="100" height="50" viewBox="0 0 100 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 40 L20 30 L40 35 L60 20 L80 25 L100 10" stroke="#4A90E2" strokeWidth="3" fill="none" />
          </svg>
        </div>
      </header>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {careerPaths.map((path, index) => (
          <Card key={index} className="p-6 bg-white shadow-lg rounded-2xl hover:scale-105 transition-transform duration-300">
            <CardContent className="flex flex-col items-center text-center">
              <div className="text-blue-600 mb-4">{path.icon}</div>
              <h2 className="text-xl font-semibold mb-2">{path.name}</h2>
              <p className="text-gray-600">{path.description}</p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}

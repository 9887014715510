import React, { useEffect, useState } from 'react';
import { useUser } from '../../utils/UserContext';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase'; 
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { FaBookReader } from 'react-icons/fa';

const formatURL = (courseTitle) => courseTitle.replace(/\s/g, '-');

const generateRandomGradient = (course_title) => {
  let hash = 0;
  for (let i = 0; i < course_title.length; i++) {
    hash = course_title.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h1 = hash % 360;
  const h2 = (hash + 100) % 360;
  return {
    color1: `hsl(${h1}, 70%, 70%)`,
    color2: `hsl(${h2}, 70%, 50%)`,
  };
};

const MyCourses = () => {
    const { user } = useUser();
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourses = async () => {
            if (user) {
                setIsLoading(true);
                try {
                    const userEnrollmentsRef = collection(db, `users/${user.uid}/Enrollments`);
                    const snapshot = await getDocs(userEnrollmentsRef);

                    const enrolledCoursesID = snapshot.docs
                      .flatMap(doc => doc.data().enrolledCourses)
                      .filter(Boolean);

                    if (enrolledCoursesID.length === 0) {
                        setCourses([]);
                        setIsLoading(false);
                        return;
                    }

                    const coursesDetails = await Promise.all(enrolledCoursesID.map(async (enrolledCourseID) => {
                        // Retrieve the course document by its document ID
                        const courseDocRef = doc(db, 'courses', enrolledCourseID);
                        const courseDoc = await getDoc(courseDocRef);
                    
                        if (!courseDoc.exists()) {
                          console.warn(`No course found with enrolled courseID: ${enrolledCourseID}`);
                          return null;
                        }
                    
                        const courseData = { id: courseDoc.id, ...courseDoc.data() };
                    
                        // Fetch progress for each course
                        const enrollmentRef = doc(db, 'enrollments', `${user.uid}_${courseData.id}`);
                        const enrollmentDoc = await getDoc(enrollmentRef);
                        const progress = enrollmentDoc.exists() ? enrollmentDoc.data().progress : 0;
                        
                        // Generate category for filtering
                        const category = progress === 100 ? 'completed' : 
                                        progress > 0 ? 'inProgress' : 'notStarted';
                    
                        return { 
                            ...courseData, 
                            progress,
                            category,
                            lastAccessed: enrollmentDoc.exists() ? enrollmentDoc.data().lastAccessed : null
                        };
                    }));

                    setCourses(coursesDetails.filter(course => course !== null));
                } catch (error) {
                    console.error('Error fetching courses:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        fetchCourses();
    }, [user]);

    const handleCourseClick = (course) => {
        navigate(`/course/${formatURL(course.course_title)}`);
    };

    const renderStatusBadge = (category) => {
        switch(category) {
            case 'completed':
                return <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">Completed</span>;
            case 'inProgress':
                return <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">In Progress</span>;
            default:
                return <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full">Not Started</span>;
        }
    };

    return (
        <div className="container mx-auto px-4 py-8 min-h-screen">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
                <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4 sm:mb-0">My Learning</h1>
            </div>

            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-700"></div>
                </div>
            ) : courses.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {courses.map((course) => {
                        const { color1, color2 } = generateRandomGradient(course.course_title);
                        return (
                            <div 
                                key={course.id} 
                                className="bg-white rounded-xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 border border-gray-200 hover:shadow-xl"
                                onClick={() => handleCourseClick(course)}
                            >
                                <div 
                                    className="h-32 p-6 flex items-center justify-center"
                                    style={{ background: `linear-gradient(135deg, ${color1}, ${color2})` }}
                                >
                                    <h2 className="text-xl font-bold text-white text-center line-clamp-2">{course.course_title}</h2>
                                </div>
                                
                                <div className="p-4">
                                    <div className="flex justify-between items-center mb-3">
                                        {renderStatusBadge(course.category)}
                                        <div className="text-sm font-semibold text-gray-700">{course.progress || 0}% complete</div>
                                    </div>
                                    
                                    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                                        <div className="h-2.5 rounded-full" 
                                            style={{ 
                                                width: `${course.progress}%`,
                                                backgroundColor: course.progress === 100 ? '#16a34a' : '#3b82f6'
                                            }}>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="flex items-center justify-between">
                                        <button className="text-blue-700 hover:text-blue-800 text-sm font-medium flex items-center">
                                            <FaBookReader className="mr-1" /> 
                                            {course.progress < 100 ? 'Continue Learning' : 'Review Course'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="bg-white rounded-xl shadow-md p-8 text-center max-w-md mx-auto mt-8">
                    <div className="text-blue-800 text-5xl mb-4">
                        <FaBookReader className="mx-auto" />
                    </div>
                    <h2 className="text-2xl font-bold text-gray-800 mb-2">No courses enrolled yet</h2>
                    <p className="text-gray-600 mb-6">Start learning by enrolling in your first course</p>
                    <button 
                        onClick={() => navigate('/all-courses')} 
                        className="px-6 py-2.5 bg-blue-800 text-white font-medium text-sm rounded-lg hover:bg-blue-700 transition"
                    >
                        Browse Courses
                    </button>
                </div>
            )}
        </div>
    );
};

export default MyCourses;
import React from 'react';
import ReactMarkdown from 'react-markdown';

const ChatMessage = ({ message }) => {
  const isAI = message.sender === 'ai';
  
  return (
    <div className={`flex ${isAI ? 'justify-start' : 'justify-end'} mb-4 sm:mb-6 animate-fadeIn w-full`}>
      <div 
        className={`max-w-[90%] rounded-lg overflow-hidden ${
          isAI 
            ? 'text-gray-800' 
            : 'bg-blue-600 text-white'
        }`}
      >
        <div className={`prose max-w-none p-3 sm:p-4 break-words ${isAI ? 'text-gray-800' : 'text-white'}`}>
          <ReactMarkdown
            components={{
              code({node, inline, className, children, ...props}) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <div className="rounded-md overflow-hidden my-2">
                    <div className="flex items-center justify-between py-1.5 px-4 bg-gray-800 text-xs text-gray-200">
                      <span>{match[1]}</span>
                      <button 
                        onClick={() => navigator.clipboard.writeText(String(children).replace(/\n$/, ''))}
                        className="text-gray-400 hover:text-white transition-colors"
                        title="Copy code"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 0 0 1 2-2h9a2 0 0 1 2 2v1"></path>
                        </svg>
                      </button>
                    </div>
                    <pre className="bg-gray-900 p-4 overflow-x-auto">
                      <code className={`${match[1]} text-sm text-gray-100`} {...props}>
                        {String(children).replace(/\n$/, '')}
                      </code>
                    </pre>
                  </div>
                ) : (
                  <code className={`${className} ${isAI ? 'bg-gray-100 text-blue-700 px-1 py-0.5 rounded text-sm' : 'bg-blue-700 text-blue-100 px-1 py-0.5 rounded text-sm'}`} {...props}>
                    {children}
                  </code>
                );
              },
              ul({node, ...props}) { 
                return <ul className="list-disc pl-6 space-y-2 my-4" {...props} />;
              },
              ol({node, ...props}) { 
                return <ol className="list-decimal pl-6 space-y-2 my-4" {...props} />;
              },
              li({node, ...props}) { 
                return <li className="my-1" {...props} />;
              },
              p({node, ...props}) { 
                return <p className="my-3 leading-relaxed" {...props} />;
              },
              // Fix for heading-has-content warnings by ensuring children are passed as content
              h1({node, children, ...props}) { 
                return <h1 className={`text-xl font-bold my-3 ${isAI ? 'text-gray-900' : 'text-white'}`} {...props}>{children}</h1>;
              },
              h2({node, children, ...props}) { 
                return <h2 className={`text-lg font-bold my-3 ${isAI ? 'text-gray-900' : 'text-white'}`} {...props}>{children}</h2>;
              },
              h3({node, children, ...props}) { 
                return <h3 className={`text-base font-bold my-2 ${isAI ? 'text-gray-900' : 'text-white'}`} {...props}>{children}</h3>;
              },
              blockquote({node, ...props}) { 
                return <blockquote className={`border-l-4 ${isAI ? 'border-blue-300 bg-blue-50' : 'border-blue-400 bg-blue-700'} pl-4 py-1 my-4 italic`} {...props} />;
              },
              // Fix for anchor-has-content warning by ensuring children are passed as content
              a({node, children, ...props}) { 
                return <a className={`${isAI ? 'text-blue-600 hover:text-blue-800' : 'text-blue-200 hover:text-blue-100'} underline`} target="_blank" rel="noopener noreferrer" {...props}>{children}</a>;
              },
              table({node, ...props}) { 
                return <div className="overflow-x-auto my-4">
                  <table className="min-w-full divide-y divide-gray-300 border border-gray-300" {...props} />
                </div>;
              },
              thead({node, ...props}) { 
                return <thead className={`${isAI ? 'bg-gray-100' : 'bg-blue-700'}`} {...props} />;
              },
              th({node, ...props}) { 
                return <th className="px-3 py-2 text-left text-xs font-medium uppercase tracking-wider" {...props} />;
              },
              td({node, ...props}) { 
                return <td className="px-3 py-2 text-sm" {...props} />;
              }
            }}
          >
            {message.text}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;

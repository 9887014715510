import React, { useState, useEffect, useRef } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import CourseCard from "./CourseCard";
import { FiSearch } from "react-icons/fi";

const AllCourses = () => {
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const filterRef = useRef(null);
  // const [aiQueryText, setAiQueryText] = useState("");
  // const [selectedPrompt, setSelectedPrompt] = useState(null);

  // Close filter popup when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        // No longer needed since we removed filterPopupVisible
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true);
      try {
        const coursesCollection = collection(db, "courses");
        const coursesSnapshot = await getDocs(coursesCollection);
        const coursesList = coursesSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          // Filter to only include "Active" courses
          .filter(course => course.status === "Active");
        
        setCourses(coursesList);
        setFilteredCourses(coursesList);
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourses();
  }, []);

  // Move handleFilterChange inside useEffect to fix the warning
  useEffect(() => {
    // Filter function inside the effect to avoid dependency issues
    const handleFilterChange = () => {
      let filtered = [...courses];

      // Apply search filter
      if (searchText) {
        const lowercaseSearch = searchText.toLowerCase();
        filtered = filtered.filter(course => 
          course.course_title?.toLowerCase().includes(lowercaseSearch) ||
          course.overview?.toLowerCase().includes(lowercaseSearch) ||
          course.category?.toLowerCase().includes(lowercaseSearch)
        );
      }

      // Apply category filter
      if (selectedCategory) {
        filtered = filtered.filter(course => course.category === selectedCategory);
      }

      // Only include active courses
      filtered = filtered.filter(course => course.status === "Active");

      setFilteredCourses(filtered);
    };

    // Call the function
    handleFilterChange();
  }, [searchText, selectedCategory, courses]); // Proper dependencies, without the function itself

  const clearFilters = () => {
    setSelectedCategory(null);
    setSearchText("");
  };

  return (
    <div className="bg-white min-h-screen pb-16 lg:-mt-16">
      <div className="bg-gray-900 justify-center text-center py-8 md:py-12 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-3xl md:text-5xl font-bold text-white mb-2">
              Explore the catalog
            </h1>
            <p className="text-lg text-white mb-8">
              Select from predefined courses or search for personalized recommendations
            </p>
            
            {/* Conversation Starter Buttons - Centered 
            <div className="mb-8">
              <ul className="flex flex-wrap justify-center gap-3">
                {[
                  "I'm new to coding, what courses should I take?",
                  "What can I learn for my career?",
                  "What projects can help me showcase my skills?",
                  "How can I earn certificates?"
                ].map((prompt, index) => (
                  <li key={index}>
                    <button
                      onClick={() => handlePromptClick(prompt)}
                      className={`flex items-center gap-2 py-2 px-4 rounded-full text-sm font-medium transition-colors ${
                        selectedPrompt === prompt 
                          ? "bg-blue-600 text-white" 
                          : "bg-blue-100 text-blue-700 hover:bg-blue-200"
                      }`}
                    >
                      <SparkleIcon />
                      {prompt}
                    </button>
                  </li>
                ))}
              </ul>
            </div> */}
            
            {/* AI Assistant Input - Centered 
            <div className="mb-8 mx-auto max-w-2xl">
              <form onSubmit={handleAiSubmit} className="flex items-stretch">
                <div className="relative flex-1 rounded-l-lg border border-gray-300">
                  <input
                    type="text"
                    value={aiQueryText}
                    onChange={(e) => setAiQueryText(e.target.value)}
                    placeholder="Ask our AI Learning Assistant"
                    className="w-full p-3 px-4 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
                <div className="flex relative">
                  <button
                    type="submit"
                    disabled={!aiQueryText.trim()}
                    className={`rounded-r-lg p-3 px-5 flex items-center justify-center transition-colors ${
                      aiQueryText.trim() 
                        ? "bg-blue-600 hover:bg-blue-700 text-white" 
                        : "bg-gray-200 text-gray-400 cursor-not-allowed"
                    }`}
                    title="Submit message"
                  >
                    <FiSend size={20} />
                  </button>
                </div>
              </form>
              <div className="mt-3 text-sm text-white flex justify-center items-center">
                <span>Not sure where to begin?&nbsp;</span>
                <a href="#sorting-quiz" className="text-blue-300 font-medium hover:underline flex items-center">
                  Take our quiz →
                </a>
              </div>
            </div> */}
          </div> 
        </div> 
        
        {/* Improved decorative background elements for better balance */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -top-20 left-1/2 transform -translate-x-1/2 w-64 h-64 rounded-full bg-blue-500 opacity-10 blur-xl"></div>
          <div className="absolute -top-20 -right-20 w-64 h-64 rounded-full bg-blue-400 opacity-5"></div>
          <div className="absolute -bottom-40 -left-20 w-96 h-96 rounded-full bg-blue-400 opacity-5"></div>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-80 h-80 rounded-full bg-blue-500 opacity-10 blur-xl"></div>
        </div>
      </div>
      
      {/* Main Content Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 pb-8">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-blue-700"></div>
          </div>
        ) : filteredCourses.length > 0 ? (
          <div className="w-full">
            {/* Grid layout with 3 columns on larger screens, 2 on medium, 1 on small */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {filteredCourses.map((course) => (
                <CourseCard key={course.id || course.course_title} course={course} />
              ))}
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-xl p-8 text-center shadow-md border border-gray-200">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-100 text-gray-500 mb-4">
              <FiSearch size={24} />
            </div>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">No courses found</h2>
            {(searchText || selectedCategory) && (
              <button 
                onClick={clearFilters}
                className="px-6 py-2 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors"
              >
                Clear filters
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// Sparkle Icon component
/* const SparkleIcon = () => (
  <svg 
    viewBox="0 0 16 16" 
    fill="currentColor" 
    xmlns="http://www.w3.org/2000/svg" 
    className="w-4 h-4"
  >
    <path 
      clipRule="evenodd" 
      d="M11.773.762a.5.5 0 01.968 0c.22.841.462 1.358.823 1.74.364.385.896.685 1.803.99a.5.5 0 010 .947c-.906.305-1.44.605-1.803.99-.361.382-.603.898-.823 1.74a.5.5 0 01-.967 0c-.22-.842-.463-1.358-.824-1.74-.363-.385-.896-.685-1.803-.99a.5.5 0 010-.948c.907-.304 1.44-.604 1.803-.988.361-.383.603-.9.823-1.74V.762zM5.368 3.548a.5.5 0 01.967 0c.397 1.522.847 2.516 1.554 3.267.708.752 1.722 1.311 3.354 1.861a.5.5 0 010 .948c-1.632.55-2.646 1.109-3.354 1.861-.707.751-1.157 1.745-1.554 3.267a.5.5 0 01-.967 0c-.397-1.522-.847-2.516-1.553-3.267-.709-.752-1.723-1.312-3.355-1.861a.5.5 0 010-.948c1.632-.55 2.647-1.109 3.355-1.861.706-.751 1.156-1.745 1.553-3.267zm7.9 6.765a.5.5 0 00-.483.37c-.16.6-.332.948-.576 1.2-.246.256-.617.466-1.282.684a.5.5 0 000 .95c.665.218 1.036.428 1.282.684.244.253.416.6.576 1.2a.5.5 0 00.966 0c.16-.6.331-.947.575-1.2.247-.256.617-.466 1.282-.684a.5.5 0 000-.95c-.665-.218-1.035-.428-1.282-.684-.244-.252-.415-.6-.575-1.2a.5.5 0 00-.483-.37z"
    />
  </svg>
); */

export default AllCourses;

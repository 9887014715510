import React, { useState } from "react";
import JobCard from "./jobCards";
import { sampleJobs as jobs } from "./jobsData";
import { FaSearch } from "react-icons/fa";

const JobsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterPopupVisible, setFilterPopupVisible] = useState(false);
  const [selectedCategory] = useState(null);
  const [typeFilter, settypeFilter] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const toggleSearch = () => {
    setFilterPopupVisible(!filterPopupVisible);
  };

  const handleFilterChange = (type) => {
    settypeFilter(type);
  };

  const filteredJobs = jobs.filter((job) => {
    const matchesCategory = selectedCategory ? job.category === selectedCategory : true;
    const matchesSearch = job.title.toLowerCase().includes(searchTerm) ||
      job.company.toLowerCase().includes(searchTerm) ||
      job.mode.toLowerCase().includes(searchTerm) ||
      job.category.toLowerCase().includes(searchTerm) ||
      job.skills.some(skill => skill.toLowerCase().includes(searchTerm));
    const matchestype = typeFilter ? job.type === typeFilter : true;
    return matchesCategory && matchesSearch && matchestype;
  });

  return (
    <div className="p-8 min-h-screen lg:-mt-16 relative">
      <div className="absolute top-4 right-4">
        <button
          className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full shadow hover:bg-gray-300 transition duration-200 ease-in-out transform hover:scale-110"
          title="Filter"
          onClick={toggleSearch}
        >
          <FaSearch />
        </button>

        {filterPopupVisible && (
          <div className="absolute mt-2 right-0 w-64 bg-white border rounded-lg shadow-lg p-4 z-50 transition duration-200 ease-in-out transform origin-top-right scale-100 lg:mr-10">
            <h3 className="text-lg font-semibold mb-2">Search</h3>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-full px-3 py-2 border rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-600"
              style={{ padding: '10px' }}
            />
            <button
              className="w-full bg-blue-800 text-white py-2 rounded mb-4 hover:bg-blue-700 transition duration-200 ease-in-out"
            >
              Search
            </button>
            <div className="flex justify-between gap-2">
              <button
                className={`w-full py-2 rounded mb-4 ${typeFilter === 'Job' ? 'bg-blue-800 text-white' : 'bg-gray-200 text-black'} hover:bg-blue-700 hover:text-white transition duration-200 ease-in-out`}
                onClick={() => handleFilterChange('Job')}
              >
                Jobs
              </button>
              <button
                className={`w-full py-2 rounded mb-4 ${typeFilter === 'Internship' ? 'bg-blue-800 text-white' : 'bg-gray-200 text-black'} hover:bg-blue-700 hover:text-white transition duration-200 ease-in-out`}
                onClick={() => handleFilterChange('Internship')}
              >
                Internships
              </button>
            </div>
          </div>
        )}
      </div>

      {typeFilter !== "Internship" && (
        <>
          <h2 className="text-2xl font-bold">Jobs</h2>
          <p className="text-gray-600">Find the jobs that fit your career aspirations.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
            {filteredJobs.filter(job => job.type === "Job").map((job) => (
              <JobCard key={job.id} job={job} />
            ))}
          </div>
        </>
      )}

      {typeFilter !== "Job" && (
        <>
          <h2 className="text-2xl font-bold mt-12">Internships</h2>
          <p className="text-gray-600">Explore internship opportunities to kickstart your career.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
            {filteredJobs.filter(job => job.type === "Internship").map((job) => (
              <JobCard key={job.id} job={job} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default JobsPage;
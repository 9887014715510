import React from "react";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../hooks/useScreenSize";

// Function to generate a random gradient based on the job title
const generateRandomGradient = (jobTitle) => {
  let hash = 0;
  for (let i = 0; i < jobTitle.length; i++) {
    hash = jobTitle.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h1 = hash % 360;
  const h2 = (hash + 100) % 360;
  return {
    color1: `hsl(${h1}, 70%, 70%)`,
    color2: `hsl(${h2}, 70%, 50%)`,
  };
};

// Function to format the date to dd/mm/yyyy
const formatDate = (dateString) => {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-GB', options);
};

// Function to format the URL
const formatUrl = (text) => typeof text === 'string' ? text.replace(/\s+/g, '-').toLowerCase() : '';

// JobCard component to display individual job details
const JobCard = ({ job }) => {
  const navigate = useNavigate();
  const isMobile = useScreenSize();
  const { color1, color2 } = generateRandomGradient(job.title);
  const gradientId = `grad-${job.id}`;

  const handleCardClick = () => {
    navigate(`/${formatUrl(job.company)}/${formatUrl(job.title)}/${job.id}`);
  };

  if (isMobile) {
    return (
      <div onClick={handleCardClick} className="w-full sm:w-96 flex-none rounded-lg overflow-hidden shadow-lg border border-gray-200 transition-transform duration-300 hover:scale-105 mx-auto my-6 cursor-pointer bg-white">
        <div className="flex h-32">
          <div className="w-2/5 relative" style={{ background: `linear-gradient(to right, ${color1}, ${color2})` }}>
            <svg className="absolute inset-0 w-full h-full" viewBox="0 0 400 200" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient id={`${gradientId}-mobile`} x1="0%" y1="0%" x2="100%">
                  <stop offset="0%" stopColor={color1} />
                  <stop offset="100%" stopColor={color2} />
                </linearGradient>
              </defs>
              <rect width="100%" height="100%" fill={`url(#${gradientId}-mobile)`} />
            </svg>
            <div className="absolute inset-0 flex items-center justify-center p-3">
              <img src={job.logo} alt={job.company} className="w-full h-full object-contain" />
            </div>
          </div>
          <div className="w-3/5 p-4 flex flex-col justify-between">
            <div>
              <h3 className="text-sm font-bold text-gray-800 mb-1 line-clamp-2">{job.title}</h3>
              <p className="text-xs text-gray-600">{job.company}</p>
            </div>
            <div className="flex justify-end items-center text-xs text-gray-700">
              <svg className="inline w-4 h-4 mr-1 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10m-9 4h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
              </svg>
              <span>{formatDate(job.date)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Desktop view (original JobCard layout)
  return (
    <div onClick={handleCardClick} className="w-full sm:w-96 flex-none rounded-lg overflow-hidden shadow-lg border border-gray-200 transition-transform duration-300 hover:scale-105 mx-auto my-6 cursor-pointer bg-white">
      <div className="relative h-48" style={{ background: `linear-gradient(to right, ${color1}, ${color2})` }}>
        <svg className="absolute inset-0 w-full h-full" viewBox="0 0 400 200" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%">
              <stop offset="0%" stopColor={color1} />
              <stop offset="100%" stopColor={color2} />
            </linearGradient>
          </defs>
          <rect width="100%" height="100%" fill={`url(#${gradientId})`} />
          <circle cx="80" cy="60" r="40" fill="rgba(255, 255, 255, 0.2)" />
          <circle cx="300" cy="140" r="30" fill="rgba(255, 255, 255, 0.3)" />
        </svg>
        <div className="absolute top-4 right-4 w-16 h-16 bg-white p-2 rounded-lg shadow-md flex items-center justify-center">
          <img src={job.logo} alt={job.company} className="w-full h-full object-contain" />
        </div>
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
          <h3 className="text-lg font-bold text-white cursor-pointer max-w-full break-words mb-4">
            {job.title}
          </h3>
        </div>
      </div>

      <div className="p-4">
        <p className="text-sm text-gray-700 line-clamp-3">{job.description.substring(0, 100)}...</p>
        <div className="flex justify-end items-center text-xs mt-4 text-gray-700">
          <svg className="inline w-4 h-4 mr-1 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10m-9 4h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
          </svg>
          <span>{formatDate(job.date)}</span>
        </div>
      </div>
    </div>
  );
};

export default JobCard;

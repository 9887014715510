import React from "react";
import { FaArrowRight, FaCalendar, FaChartBar, FaClipboardList, FaLaptopCode, FaLayerGroup } from "react-icons/fa";

const Dashboard = () => {
  return (
    <div className="flex min-h-screen bg-[#f9efe7]">
      {/* Sidebar */}
      <aside className="w-64 p-6 bg-white shadow-md">
        <h1 className="text-xl font-bold mb-6">Dashboard</h1>
        <nav className="space-y-4">
          <button className="flex items-center gap-3 text-lg font-medium text-black bg-gray-200 p-3 rounded-lg w-full">
            <FaLayerGroup /> Dashboard
          </button>
          <button className="flex items-center gap-3 text-lg text-gray-700 hover:text-black hover:bg-gray-100 p-3 rounded-lg w-full">
            <FaClipboardList /> My Learning
          </button>
          <button className="flex items-center gap-3 text-lg text-gray-700 hover:text-black hover:bg-gray-100 p-3 rounded-lg w-full">
            <FaCalendar /> Events
          </button>
          <button className="flex items-center gap-3 text-lg text-gray-700 hover:text-black hover:bg-gray-100 p-3 rounded-lg w-full">
            <FaLaptopCode /> Projects <span className="text-xs bg-black text-white px-2 py-1 rounded-full ml-auto">Upgrade</span>
          </button>
          <button className="flex items-center gap-3 text-lg text-gray-700 hover:text-black hover:bg-gray-100 p-3 rounded-lg w-full">
            <FaChartBar /> Workspaces
          </button>
        </nav>
        <div className="mt-6 p-4 bg-yellow-100 rounded-lg">
          <h3 className="font-semibold text-lg">Upgrade your plan</h3>
          <p className="text-sm text-gray-700">Go deeper and learn job-ready skills. Take assessments, earn certificates.</p>
          <button className="mt-3 bg-black text-white px-4 py-2 rounded-lg w-full">Upgrade Plan</button>
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-1 p-8">
        {/* Keep Learning Section */}
        <section className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-xl font-bold">Keep Learning</h2>
          <div className="mt-4 p-4 border-l-4 border-yellow-500 bg-gray-100">
            <p className="text-sm font-medium">3% Complete</p>
            <h3 className="text-lg font-bold">Introduction to Cybersecurity</h3>
            <p className="text-sm text-gray-600">Current Module: What is Cybersecurity?</p>
            <div className="mt-4 flex justify-between items-center">
              <button className="text-blue-600 underline">View all learning in progress</button>
              <button className="bg-blue-700 text-white px-4 py-2 rounded-lg flex items-center gap-2">
                Resume <FaArrowRight />
              </button>
            </div>
          </div>
        </section>

        {/* Progress Section */}
        <section className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-bold">Follow Your Progress</h2>
          <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* XP Added */}
            <div className="p-4 bg-gray-100 rounded-lg">
              <h3 className="text-sm font-semibold">Skills</h3>
              <p className="text-lg font-bold">+30 XP newly added</p>
            </div>

            {/* No Weekly Target */}
            <div className="p-4 bg-gray-100 rounded-lg text-center">
              <p className="text-sm font-semibold">No weekly target set yet</p>
              <button className="mt-2 bg-purple-600 text-white px-4 py-2 rounded-lg">Set Target</button>
            </div>

            {/* Goal */}
            <div className="p-4 bg-gray-100 rounded-lg text-center">
              <p className="text-sm font-semibold">Your Goal</p>
              <p className="text-gray-600 text-sm">Grow in my existing role</p>
              <button className="mt-2 text-blue-600 underline">Edit</button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;

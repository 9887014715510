import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { auth, db, model } from '../../firebase';
import { collection, getDocs, doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { formatUrl, calculateProgress, handleChatSubmit, generateNotes } from '../../utils/lessonHelpers';
import NotesSection from '../notes/NotesSection';
import ChatSection from '../chat/ChatSection';
import CourseProgress from './lesson/CourseProgress';
import MobileMenu from './lesson/MobileMenu';
import useVideoData from '../../hooks/useVideoData';
import { FaCheckCircle, FaLightbulb, FaComments, FaBook, FaCode, FaVideo, FaFileAlt } from 'react-icons/fa';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

const CourseLesson = () => {
  const { course_title, module_title, lesson_slug } = useParams();
  const [course, setCourse] = useState(null);
  const [currentModule, setCurrentModule] = useState(null);
  const [lesson, setLesson] = useState(null);
  const [activeTab, setActiveTab] = useState('description');
  const [isCompleted, setIsCompleted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openModules, setOpenModules] = useState([]);
  const [modules, setModules] = useState([]);
  const [completedLessons, setCompletedLessons] = useState([]);
  const [generatingNotes, setGeneratingNotes] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [noteLevel, setNoteLevel] = useState('beginner'); 
  const [noteTone, setNoteTone] = useState('formal'); 
  const [noteLength, setNoteLength] = useState('short'); 
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isFetchingNotes, setIsFetchingNotes] = useState(false);
  const [notesFetched, setNotesFetched] = useState(false); 
  const [isGeneratedCourse, setIsGeneratedCourse] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState);
  };

  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const formattedCourseTitle = course_title.replace(/-/g, ' ').toLowerCase();
  const formattedModuleTitle = module_title.replace(/-/g, ' ').toLowerCase();

  const fetchNotes = useCallback(async () => {
    if (!course || !lesson || !currentModule || !userId || isFetchingNotes) {
        return;
    }

    setIsFetchingNotes(true);

    try {
        const noteId = `${currentModule.id}_${lesson.id}`;

        const notesRef = doc(db, 'enrollments', `${userId}_${course.id}`, 'generatedNotes', noteId);
        const notesDoc = await getDoc(notesRef);
        
        if (notesDoc.exists()) {
            const notesData = notesDoc.data();
            const updatedNotesData = {
               ...notesData,
               lessonId: notesData.lessonId || lesson.id,
               moduleId: notesData.moduleId || currentModule.id,
            };
            setLesson(prevLesson => ({
                ...prevLesson,
                notes: updatedNotesData.notes,
                noteData: updatedNotesData,
                noteLevel: updatedNotesData.noteLevel || prevLesson.noteLevel,
                noteTone: updatedNotesData.noteTone || prevLesson.noteTone,
                noteLength: updatedNotesData.noteLength || prevLesson.noteLength,
            }));
        } else {
            setLesson(prevLesson => ({
                ...prevLesson,
                notes: null,
                noteData: null
            }));
        }
    } catch (error) {
        // Error handling without console log
    } finally {
        setIsFetchingNotes(false);
        setNotesFetched(true); 
    }
  }, [course, lesson, currentModule, userId, isFetchingNotes]);


useEffect(() => {
    const isAuthenticated = !!auth.currentUser;
    if (!course || !lesson || !currentModule || !isAuthenticated) return;
    
    if (notesFetched) return; 

    fetchNotes();
}, [course, lesson, currentModule, fetchNotes, notesFetched]);

useEffect(() => {
    if (lesson?.id) {
        setNotesFetched(false);
    }
}, [lesson?.id]);

const markAsComplete = async () => {
    if (!userId || !course || !currentModule || !lesson) return;

    const lessonKey = `${course.id}_${currentModule.id}_${lesson.id}`;

    if (completedLessons.includes(lessonKey)) {
      setIsCompleted(true);
      return;
    }

    try {
      const enrollmentPath = `enrollments/${userId}_${course.id}`;
      const enrollmentRef = doc(db, enrollmentPath);

      const updatedCompletedLessons = [...completedLessons, lessonKey];
      const newProgress = calculateProgress(updatedCompletedLessons, modules);

      // Find current indices
      const currentModuleIndex = modules.findIndex(m => m.id === currentModule.id);
      const currentLessonIndex = currentModule.lessons.findIndex(l => l.id === lesson.id);

      let nextLessonKey = null;
      let nextModuleId = currentModule.id;
      let updateData = {};

      // Check if there are more lessons in current module
      if (currentLessonIndex < currentModule.lessons.length - 1) {
        // Next lesson in same module
        const nextLesson = currentModule.lessons[currentLessonIndex + 1];
        nextLessonKey = `${course.id}_${currentModule.id}_${nextLesson.id}`;
        
        updateData = {
          completedLessons: arrayUnion(lessonKey),
          progress: newProgress,
          currentLesson: nextLessonKey,
          currentModule: currentModule.id
        };

        await updateDoc(enrollmentRef, updateData);
        window.location.href = `/${formatUrl(course.category)}/${formatUrl(course.course_title)}/${formatUrl(currentModule.module_title)}/${formatUrl(nextLesson.lesson_slug)}`;
      } 
      // If current lesson is last in module, check for next module
      else if (currentModuleIndex < modules.length - 1) {
        // Move to first lesson of next module
        const nextModule = modules[currentModuleIndex + 1];
        const firstLessonOfNextModule = nextModule.lessons[0];
        nextLessonKey = `${course.id}_${nextModule.id}_${firstLessonOfNextModule.id}`;
        nextModuleId = nextModule.id;

        // Update both completion and next module/lesson in one operation
        updateData = {
          completedLessons: arrayUnion(lessonKey),
          progress: newProgress,
          currentLesson: nextLessonKey,
          currentModule: nextModuleId
        };

        await updateDoc(enrollmentRef, updateData);
        window.location.href = `/${formatUrl(course.category)}/${formatUrl(course.course_title)}/${formatUrl(nextModule.module_title)}/${formatUrl(firstLessonOfNextModule.lesson_slug)}`;
      } 
      // If this is the last lesson of the last module
      else {
        updateData = {
          completedLessons: arrayUnion(lessonKey),
          progress: newProgress,
          currentLesson: 'COURSE_COMPLETED',
          currentModule: currentModule.id
        };

        await updateDoc(enrollmentRef, updateData);
      }

      // Update local state
      setCompletedLessons(updatedCompletedLessons);
      setProgress(newProgress);
      setIsCompleted(true);

    } catch (error) {
      // Error handling without console log
    }
  };

  const toggleModule = (moduleId) => {
    setOpenModules((prevOpenModules) =>
      prevOpenModules.includes(moduleId)
        ? prevOpenModules.filter((id) => id !== moduleId)
        : [...prevOpenModules, moduleId]
    );
  };

  const handleLessonClick = (lessonItem, moduleItem) => {
    if (!course || !moduleItem || !lessonItem || !currentModule) {
      return;
    }

    const lessonKey = `${course.id}_${moduleItem.id}_${lessonItem.id}`;
    const currentLessonIndex = currentModule.lessons.findIndex(l => l.id === lessonItem.id);
    const selectedLessonIndex = moduleItem.lessons.findIndex(l => l.id === lessonItem.id);
    const isModuleUnlocked = currentModule.id === moduleItem.id || currentModule.id > moduleItem.id;
    const isLessonCompleted = completedLessons.includes(lessonKey);
    const isLessonUnlocked = selectedLessonIndex <= currentLessonIndex;
    const isCurrentModuleComplete = currentModule.lessons.every(l => completedLessons.includes(`${course.id}_${currentModule.id}_${l.id}`));
    if (!isLessonCompleted && !isLessonUnlocked && isModuleUnlocked) {
      return;
    }
    if (moduleItem.id > currentModule.id && !isCurrentModuleComplete) {
      return;
    }
    window.location.href = `/${formatUrl(course.category)}/${formatUrl(course.course_title)}/${formatUrl(moduleItem.module_title)}/${formatUrl(lessonItem.lesson_slug)}`;
  };

  const generateNotesForLesson = async () => {
    if (!course || !currentModule || !lesson || !userId) {
      return;
    }
    setGeneratingNotes(true);
    try {
      await generateNotes(
        lesson,
        currentModule,
        course,
        userId,
        noteLevel,
        noteTone,
        noteLength,
        model,
        db,
        setLesson
      );
    } catch (error) {
      // Error handling without console log
    } finally {
      setGeneratingNotes(false);
    }
  };

  const handleChatSubmitWrapper = async (e) => {
    e.preventDefault();
    if (!chatInput.trim()) return;
    await handleChatSubmit(chatInput, lesson, model, setChatMessages, setChatInput);
  };
  
useEffect(() => {
    const fetchCourseData = async () => {
        try {
            if (!auth.currentUser) {
                return;
            }
            
            const userId = auth.currentUser.uid;
            let courseData = null;
            let isGenerated = false;

            // First, check if this is a user-generated course
            if (userId) {
                // Try to find the course in the user's GeneratedCourses subcollection
                const userGeneratedCoursesRef = collection(db, 'users', userId, 'GeneratedCourses');
                const userCoursesSnapshot = await getDocs(userGeneratedCoursesRef);
                const userCourseDoc = userCoursesSnapshot.docs.find(
                    (doc) => doc.data().course_title.toLowerCase() === formattedCourseTitle
                );

                if (userCourseDoc) {
                    // If found in user's collection, use this course data
                    courseData = { id: userCourseDoc.id, ...userCourseDoc.data() };
                    isGenerated = true;
                    setIsGeneratedCourse(true);
                }
            }

            // If not found in user's collection, check the main courses collection
            if (!courseData) {
                const coursesRef = collection(db, 'courses');
                const coursesSnapshot = await getDocs(coursesRef);
                const courseDoc = coursesSnapshot.docs.find(
                    (doc) => doc.data().course_title.toLowerCase() === formattedCourseTitle
                );

                if (!courseDoc) {
                    return;
                }

                courseData = { id: courseDoc.id, ...courseDoc.data() };
            }

            setCourse(courseData);

            // Build the correct path to modules based on whether this is a user-generated course
            let modulesPath;
            if (isGenerated) {
                modulesPath = collection(db, 'users', userId, 'GeneratedCourses', courseData.id, 'modules');
            } else {
                modulesPath = collection(db, 'courses', courseData.id, 'modules');
            }

            const modulesSnapshot = await getDocs(modulesPath);
            const modulesData = [];

            for (const moduleDoc of modulesSnapshot.docs) {
                const moduleData = { id: moduleDoc.id, ...moduleDoc.data() };

                // Build the correct path to lessons
                let lessonsPath;
                if (isGenerated) {
                    lessonsPath = collection(
                        db, 
                        'users', 
                        userId, 
                        'GeneratedCourses', 
                        courseData.id, 
                        'modules', 
                        moduleDoc.id, 
                        'lessons'
                    );
                } else {
                    lessonsPath = collection(
                        db, 
                        'courses', 
                        courseData.id, 
                        'modules', 
                        moduleDoc.id, 
                        'lessons'
                    );
                }

                const lessonsSnapshot = await getDocs(lessonsPath);
                const lessons = lessonsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                moduleData.lessons = lessons;
                modulesData.push(moduleData);

                if (moduleData.module_title.toLowerCase() === formattedModuleTitle) {
                    setCurrentModule(moduleData);
                    const lessonDoc = lessons.find(
                        (lesson) => lesson.lesson_slug.toLowerCase() === lesson_slug
                    );

                    if (!lessonDoc) {
                        return;
                    }
                    
                    setLesson(prevLesson => ({
                        ...lessonDoc,
                        notes: prevLesson?.notes || null,
                        noteData: prevLesson?.noteData || null,
                        noteLevel: prevLesson?.noteLevel || lessonDoc.noteLevel,
                        noteTone: prevLesson?.noteTone || lessonDoc.noteTone,
                        noteLength: prevLesson?.noteLength || lessonDoc.noteLength
                    }));
                    setOpenModules((prevOpenModules) => [...prevOpenModules, moduleData.id]);
                }
            }

            setModules(modulesData);

            const enrollmentDocRef = doc(db, 'enrollments', `${userId}_${courseData.id}`);
            const enrollmentDoc = await getDoc(enrollmentDocRef);
            if (enrollmentDoc.exists()) {
                const enrollmentData = enrollmentDoc.data();
                setCompletedLessons(enrollmentData.completedLessons || []);
                setProgress(calculateProgress(enrollmentData.completedLessons || [], modulesData));
                if (enrollmentData.completedLessons.includes(`${courseData.id}_${currentModule?.id}_${lesson?.id}`)) {
                    setIsCompleted(true);
                }
            }
        } catch (error) {
            // Error handling without console log
        }
    };

    fetchCourseData();
  }, [formattedCourseTitle, formattedModuleTitle, lesson_slug, currentModule?.id, lesson?.id]);

  const { loading: videoLoading } = useVideoData(
    lesson?.videoID, 
    isGeneratedCourse && lesson?.content_type === 'video' ? lesson?.videoUrl : null
  );

  // Helper function to extract video ID from various YouTube URL formats
  const extractVideoId = (url) => {
    if (!url) return null;
    
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const renderContentByType = () => {
    // Get content type from lesson, default to 'video' if not specified
    const type = lesson?.content_type || 'video';
    
    if (type === 'reading') {
      return (
        <div className="bg-white border border-gray-200 rounded-xl p-6 shadow-md">
          <div className="flex items-center mb-4 text-blue-600">
            <FaFileAlt className="mr-2 text-xl" />
            <h3 className="font-medium">Reading Material</h3>
          </div>
          <div className="prose max-w-none">
            <div className="text-base md:text-lg">
              {lesson?.reading_material?.content ? (
                <Markdown 
                  rehypePlugins={[rehypeRaw]} 
                  remarkPlugins={[remarkGfm]}
                  className="markdown-content"
                  components={{
                    // Improve list rendering
                    ul: ({node, ...props}) => <ul className="list-disc pl-6 my-4" {...props}/>,
                    ol: ({node, ...props}) => <ol className="list-decimal pl-6 my-4" {...props}/>,
                    // Enhance code blocks
                    code: ({node, ...props}) => <code className="bg-gray-100 px-1 py-0.5 rounded" {...props}/>,
                    // Table formatting
                    table: ({node, ...props}) => <table className="min-w-full border border-gray-300 my-6 table-auto" {...props}/>,
                    thead: ({node, ...props}) => <thead className="bg-gray-100" {...props}/>,
                    th: ({node, ...props}) => <th className="border border-gray-300 px-4 py-2 text-left font-semibold" {...props}/>,
                    tbody: ({node, ...props}) => <tbody {...props}/>,
                    tr: ({node, ...props}) => <tr className="border-b border-gray-300" {...props}/>,
                    td: ({node, ...props}) => <td className="border border-gray-300 px-4 py-2" {...props}/>
                  }}
                >
                  {lesson?.reading_material?.content
                    .replace(/```markdown\s*/g, '')
                    .replace(/```\s*$/g, '')}
                </Markdown>
              ) : 'No reading content available.'}
            </div>
          </div>
        </div>
      );
    } else if (type === 'case _study') {
      return (
        <div className="bg-white border border-gray-200 rounded-xl p-6 shadow-md">
          <div className="flex items-center mb-4 text-blue-600">
            <FaFileAlt className="mr-2 text-xl" />
            <h3 className="font-medium">Case Study</h3>
          </div>
          <div className="prose max-w-none">
            <div className="text-base md:text-lg">
              {lesson?.case_study?.content ? (
                <Markdown 
                  rehypePlugins={[rehypeRaw]} 
                  remarkPlugins={[remarkGfm]}
                  className="markdown-content"
                  components={{
                    // Improve list rendering
                    ul: ({node, ...props}) => <ul className="list-disc pl-6 my-4" {...props}/>,
                    ol: ({node, ...props}) => <ol className="list-decimal pl-6 my-4" {...props}/>,
                    // Enhance code blocks
                    code: ({node, ...props}) => <code className="bg-gray-100 px-1 py-0.5 rounded" {...props}/>,
                    // Table formatting
                    table: ({node, ...props}) => <table className="min-w-full border border-gray-300 my-6 table-auto" {...props}/>,
                    thead: ({node, ...props}) => <thead className="bg-gray-100" {...props}/>,
                    th: ({node, ...props}) => <th className="border border-gray-300 px-4 py-2 text-left font-semibold" {...props}/>,
                    tbody: ({node, ...props}) => <tbody {...props}/>,
                    tr: ({node, ...props}) => <tr className="border-b border-gray-300" {...props}/>,
                    td: ({node, ...props}) => <td className="border border-gray-300 px-4 py-2" {...props}/>
                  }}
                >
                  {lesson?.reading_material?.content
                    .replace(/```markdown\s*/g, '')
                    .replace(/```\s*$/g, '')}
                </Markdown>
              ) : 'No reading content available.'}
            </div>
          </div>
        </div>
      );
    }
    else if (type === 'practice') {
      return (
        <div className="bg-white border border-gray-200 rounded-xl p-6 shadow-md">
          <div className="flex items-center mb-4 text-green-600">
        <FaCode className="mr-2 text-xl" />
        <h3 className="font-medium">Practice Exercise</h3>
          </div>
          <div className="prose max-w-none">
        <div 
          className="text-base md:text-lg space-y-4"
          dangerouslySetInnerHTML={{ __html: lesson?.practice_exercise.title || 'No practice content available.' }}
        />
        <p>Tasks: {lesson?.practice_exercise.tasks}</p>
          </div>
        </div>
      );
    } else {
      // Video content type - enlarged player size
      const videoId = lesson?.videoID || extractVideoId(lesson?.videoUrl || '');
      
      return (
        <div className="rounded-xl shadow-md">
          {/* Full-width video container with maximized size */}
          <div className="aspect-w-16 aspect-h-9 w-full h-full rounded-xl">
            {videoId ? (
              <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                title={lesson?.lesson_title || "Video Content"}
                className="w-full h-full rounded-lg"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
              ></iframe>
            ) : videoLoading ? (
              <div className="flex justify-center items-center h-full bg-gray-100 rounded-lg">
                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-blue-600"></div>
              </div>
            ) : (
              <div className="flex justify-center items-center h-full bg-gray-100 rounded-lg">
                <p className="text-gray-600">No video available for this lesson.</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'description':
        return (
          <div className="animate-fade-in">
            <div className="prose max-w-none">
              <div className="text-base md:text-lg space-y-4">
                {lesson?.description || 'Description is not available right now.'}
              </div>
            </div>
            <div className="flex flex-wrap gap-3 items-center mt-8">
              <button
                onClick={markAsComplete}
                className={`px-6 py-3 text-white rounded-lg flex items-center justify-center transition-all transform hover:scale-105 ${
                  isCompleted 
                  ? 'bg-green-800 cursor-default' 
                  : 'bg-blue-800 hover:bg-blue-800 shadow-md hover:shadow-lg'
                }`}
                disabled={isCompleted}
              >
                {isCompleted ? (
                  <>
                    <FaCheckCircle className="mr-2" /> Completed
                  </>
                ) : (
                  <>
                    <FaCheckCircle className="mr-2" /> Mark as Complete
                  </>
                )}
              </button>
            </div>
          </div>
        );
      case 'notes':
        return (
          <div className="animate-fade-in">
            <NotesSection
              lesson={lesson}
              noteLevel={noteLevel}
              setNoteLevel={setNoteLevel}
              noteTone={noteTone}
              setNoteTone={setNoteTone}
              noteLength={noteLength}
              setNoteLength={setNoteLength}
              generateNotesForLesson={generateNotesForLesson}
              generatingNotes={generatingNotes}
            />
          </div>
        );
      case 'chat':
        return (
          <div className="h-[calc(100vh-300px)] sm:h-[500px] overflow-hidden animate-fade-in">
            <ChatSection
              chatMessages={chatMessages}
              chatInput={chatInput}
              setChatInput={setChatInput}
              handleChatSubmit={handleChatSubmitWrapper}
            />
          </div>
        );
      default:
        return null;
    }
  };

  if (!course || !lesson || !currentModule) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="inline-block animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-600"></div>
          <p className="mt-4 text-lg font-medium text-gray-700">Loading lesson content...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen pb-24 bg-gray-100 text-gray-900">
      {isMobileMenuOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden" 
          onClick={() => setIsMobileMenuOpen(false)}
        ></div>
      )}

      <header className="sticky top-0 z-20 bg-white shadow-sm lg:-mt-16">
        <div className="container mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div>
                <h1 className="text-xl font-semibold text-gray-800">
                  {course.course_title}
                  {isGeneratedCourse && (
                    <span className="ml-2 text-xs px-2.5 py-0.5 bg-blue-100 text-blue-800 rounded-full">
                      Generated
                    </span>
                  )}
                </h1>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <div className="hidden sm:block">
                <div className="flex items-center space-x-2">
                  <span className="text-sm font-medium text-gray-500">Progress:</span>
                  <div className="relative w-24 h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className="absolute top-0 left-0 h-full bg-blue-500 rounded-full" 
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                  <span className="text-sm font-medium text-gray-700">{progress}%</span>
                </div>
              </div>

              <button
                className="sm:hidden text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={toggleMobileMenu}
                aria-label="Open navigation menu"
              >
              <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
              </button>
            </div>
          </div>
          
          <div className="sm:hidden mt-2">
            <div className="w-full bg-gray-200 rounded-full h-1.5">
              <div className="h-1.5 bg-blue-500 rounded-full" style={{ width: `${progress}%` }}></div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        modules={modules}
        openModules={openModules}
        toggleModule={toggleModule}
        handleLessonClick={handleLessonClick}
        completedLessons={completedLessons}
        course={course}
        currentModule={currentModule}
        lesson={lesson}
        progress={progress}
      />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-16">
        <div className="grid lg:grid-cols-[75%,auto] gap-8">
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold text-gray-900">{lesson.lesson_title}</h1>
              <div className="flex items-center">
                {lesson?.content_type === 'reading' && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    <FaFileAlt className="mr-1" /> Reading
                  </span>
                )}
                {lesson?.content_type === 'practice' && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    <FaCode className="mr-1" /> Practice
                  </span>
                )}
                {(!lesson?.content_type || lesson?.content_type === 'video') && (
                  <span className="flex items-center mb-4 text-red-600">
                  <FaVideo className="mr-2 text-xl" />
                  <h3 className="font-medium">Video</h3>
                  </span>
                )}
              </div>
            </div>
            
            {renderContentByType()}
            
            <div className="bg-white border border-gray-200 rounded-xl overflow-hidden shadow-md">
              <div className="flex border-b border-gray-200">
                {[
                  { id: 'description', label: 'Description', icon: <FaBook className="mr-2" /> },
                  { id: 'notes', label: 'Notes', icon: <FaLightbulb className="mr-2" /> },
                  { id: 'chat', label: 'Chat', icon: <FaComments className="mr-2" /> }
                ].map(tab => (
                  <button
                    key={tab.id}
                    className={`flex items-center py-3 px-4 sm:px-6 font-medium transition-colors relative ${
                      activeTab === tab.id
                        ? 'text-blue-600 border-blue-600'
                        : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                    }`}
                    onClick={() => setActiveTab(tab.id)}
                  >
                    {tab.icon}
                    {tab.label}
                    {activeTab === tab.id && (
                      <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600"></div>
                    )}
                  </button>
                ))}
              </div>

              <div className="p-4">{renderContent()}</div>
            </div>
          </div>

          <div className="hidden lg:block">
            <CourseProgress 
              progress={progress}
              modules={modules}
              openModules={openModules}
              toggleModule={toggleModule}
              handleLessonClick={handleLessonClick}
              completedLessons={completedLessons}
              course={course}
              currentModule={currentModule}
              lesson={lesson}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLesson;
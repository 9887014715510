import React from 'react';
import { PlayCircle } from 'lucide-react';
import { formatDuration } from '../../../utils/DurationCalculator';

const CourseProgressNav = ({ 
  progress, 
  modules, 
  openModules, 
  toggleModule, 
  handleLessonClick, 
  completedLessons, 
  course,
  isMobile = false,
  toggleMobileMenu = null 
}) => {
  return (
    <div className="space-y-4">
      <div className="mb-6">
        <p className="text-gray-600 mb-2">Course Progress</p>
        <p className="text-blue-800 mb-4">{progress}% Complete</p>
        <div className="w-full bg-gray-200 rounded-full h-1">
          <div 
            className="bg-blue-800 h-1 rounded-full transition-all duration-500" 
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      <div className="space-y-4">
        {modules.map((moduleItem) => (
          <div key={moduleItem.id} className="border rounded-lg overflow-hidden">
            <button
              className="w-full text-left px-4 py-2 bg-gray-100 text-gray-800 font-semibold flex justify-between items-center"
              onClick={() => toggleModule(moduleItem.id)}
            >
              <span className="flex-grow">{moduleItem.module_title}</span>
            </button>
            {openModules.includes(moduleItem.id) && (
              <div className="px-4 py-2 bg-white">
                {moduleItem.lessons?.length > 0 ? (
                  <ul className="mt-2 text-gray-600 space-y-2 pl-4">
                    {moduleItem.lessons.map((lessonItem) => (
                      <li key={lessonItem.id} className="flex justify-between items-center py-2 border-b">
                        <button
                          onClick={() => {
                            handleLessonClick(lessonItem, moduleItem);
                            if (isMobile && toggleMobileMenu) toggleMobileMenu();
                          }}
                          className={`flex items-center space-x-3 text-base ${
                            completedLessons.includes(`${course.id}_${moduleItem.id}_${lessonItem.id}`)
                              ? 'text-green-600'
                              : 'text-gray-700 hover:text-blue-600 transition-colors'
                          }`}
                        >
                          <PlayCircle className={`w-5 h-5 flex-shrink-0 ${
                            completedLessons.includes(`${course.id}_${moduleItem.id}_${lessonItem.id}`)
                              ? 'text-green-500'
                              : 'text-gray-500'
                          }`} />
                          <span className="pl-2 flex-grow text-left">{lessonItem.lesson_title}</span>
                        </button>
                        <span className="text-sm text-gray-500 flex-shrink-0">{formatDuration(lessonItem.duration)}</span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="mt-2 text-gray-500 pl-4">No lessons available.</p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseProgressNav;

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getVertexAI, getGenerativeModel } from "firebase/vertexai";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBu2S8CF3gCFpqDjGmWujda7PlsQaXIoCw",
    authDomain: "1xSkills.com",
    projectId: "xskills-e47b7",
    storageBucket: "xskills-e47b7.firebasestorage.app",
    messagingSenderId: "901081334620",
    appId: "1:901081334620:web:f9550f353684f7e87ab799"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore('xskillscfdb200723');
const storage = getStorage(app);

const vertexAI = getVertexAI(app);

export const model = getGenerativeModel(vertexAI, { model: "gemini-2.0-flash-lite-001" });

const isAuthenticated = () => {
    return new Promise((resolve) => {
        onAuthStateChanged(auth, (user) => {
            resolve(!!user);
        });
    });
};

export { auth, db, storage, isAuthenticated };
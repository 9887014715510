import { db, model } from "../firebase";
import {
  collection,
  query,
  getDocs,
  getDoc,
  doc,
  where,
  addDoc,
  serverTimestamp,
  setDoc,
  arrayUnion
} from "firebase/firestore";

class CourseBuilder {
  constructor() {
    this.videoCollection = collection(db, "videos");
    this.courseCollection = collection(db, "courses");
    this.userCollection = collection(db, "users");
  }

  /**
   * Fetches all available videos from Firestore
   */
  async getAllVideos() {
    try {
      const videosSnapshot = await getDocs(this.videoCollection);
      return videosSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error("Error fetching videos:", error);
      throw error;
    }
  }

  /**
   * Fetches videos by specified category
   * @param {string} category - The category to filter videos by
   */
  async getVideosByCategory(category) {
    try {
      const q = query(
        this.videoCollection,
        where("category", "==", category)
      );
      const videosSnapshot = await getDocs(q);
      return videosSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error(`Error fetching videos in category ${category}:`, error);
      throw error;
    }
  }

  /**
   * Fetches videos by specified skill level
   * @param {string} level - The skill level to filter videos by (beginner, intermediate, advanced)
   */
  async getVideosByLevel(level) {
    try {
      const q = query(
        this.videoCollection,
        where("level", "==", level)
      );
      const videosSnapshot = await getDocs(q);
      return videosSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error(`Error fetching videos with level ${level}:`, error);
      throw error;
    }
  }

  /**
   * Fetches videos by specified topic
   * @param {string} topic - The topic to filter videos by
   */
  async getVideosByTopic(topic) {
    try {
      const q = query(
        this.videoCollection,
        where("topic", "==", topic)
      );
      const videosSnapshot = await getDocs(q);
      return videosSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error(`Error fetching videos for topic ${topic}:`, error);
      throw error;
    }
  }

  /**
   * Gets user preferences and learning history
   * @param {string} userId - The user's ID
   */
  async getUserProfile(userId) {
    try {
      const userDocRef = doc(this.userCollection, userId);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        throw new Error("User not found");
      }
      
      return userDoc.data();
    } catch (error) {
      console.error("Error fetching user profile:", error);
      throw error;
    }
  }

  /**
   * Analyzes content to extract keywords and topics
   * @param {Array} videos - Array of video objects
   */
  analyzeVideoContent(videos) {
    // This would ideally use a more sophisticated NLP approach
    // But for now, we'll use a simplified keyword extraction
    const topicsMap = {};
    
    videos.forEach(video => {
      // Extract topics from title, description, and tags
      const content = `${video.title} ${video.description || ''} ${(video.tags || []).join(' ')}`.toLowerCase();
      
      // Simple keyword extraction (this would be replaced with proper NLP)
      const keywords = content.split(/\s+/)
        .filter(word => word.length > 3) // Filter out short words
        .filter(word => !['this', 'that', 'with', 'from', 'have', 'what'].includes(word)); // Filter common words
      
      keywords.forEach(keyword => {
        if (topicsMap[keyword]) {
          topicsMap[keyword]++;
        } else {
          topicsMap[keyword] = 1;
        }
      });
    });
    
    // Sort topics by frequency
    const sortedTopics = Object.entries(topicsMap)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10) // Top 10 topics
      .map(entry => entry[0]);
    
    return sortedTopics;
  }

  /**
   * Creates course modules based on video topics and sequence
   * @param {Array} videos - Array of video objects
   * @param {Array} topics - Array of main topics
   */
  organizeIntoModules(videos, topics) {
    // Group videos by primary topics
    const moduleGroups = {};
    
    // Initialize groups with our main topics
    topics.forEach(topic => {
      moduleGroups[topic] = [];
    });
    
    // Place videos into appropriate topic groups
    videos.forEach(video => {
      const videoContent = `${video.title} ${video.description || ''} ${(video.tags || []).join(' ')}`.toLowerCase();
      
      // Find the best matching topic for this video
      let bestMatch = null;
      let highestRelevance = 0;
      
      topics.forEach(topic => {
        // Simple relevance score - count occurrences of the topic
        const topicOccurrences = (videoContent.match(new RegExp(topic, 'g')) || []).length;
        if (topicOccurrences > highestRelevance) {
          highestRelevance = topicOccurrences;
          bestMatch = topic;
        }
      });
      
      // If we found a match, add the video to that topic group
      if (bestMatch && highestRelevance > 0) {
        moduleGroups[bestMatch].push(video);
      }
    });
    
    // Convert groups to proper module objects
    const modules = Object.entries(moduleGroups)
      .filter(([_, videos]) => videos.length > 0)
      .map(([topic, videos], index) => {
        return {
          id: `M${101 + index}`, // Changed to M101, M102, etc.
          module_title: this.generateModuleTitle(topic),
          module_description: `Learn about ${topic} through carefully selected videos`,
          order: index + 1,
          lessons: videos.map((video, lessonIndex) => ({
            id: `L${101 + lessonIndex}`, // Changed to L101, L102, etc.
            lesson_title: video.title,
            lesson_slug: this.generateSlug(video.title),
            video_url: video.url,
            duration: video.duration || "Unknown",
            order: lessonIndex + 1,
            description: video.description || `Lesson on ${topic}`,
            resources: video.resources || []
          }))
        };
      });
    
    return modules;
  }

  /**
   * Creates course modules based on topics with mixed content types
   * @param {Array} videos - Array of video objects
   * @param {Array} topics - Array of main topics
   * @param {string} level - Skill level for the course
   */
  async organizeIntoEnhancedModules(videos, topics, level) {
    // Group videos by primary topics
    const moduleGroups = {};
    
    // Initialize groups with our main topics
    topics.forEach(topic => {
      moduleGroups[topic] = [];
    });
    
    // Place videos into appropriate topic groups
    videos.forEach(video => {
      const videoContent = `${video.title} ${video.description || ''} ${(video.topic || '')} ${(video.topicTaught || '')}`.toLowerCase();
      
      // Find the best matching topic for this video
      let bestMatch = null;
      let highestRelevance = 0;
      
      topics.forEach(topic => {
        // Simple relevance score - count occurrences of the topic
        const topicWords = topic.toLowerCase().split(' ');
        let relevanceScore = 0;
        
        topicWords.forEach(word => {
          if (word.length > 3) { // Only consider meaningful words
            const regex = new RegExp(`\\b${word}\\b`, 'g');
            const matches = (videoContent.match(regex) || []).length;
            relevanceScore += matches;
          }
        });
        
        if (relevanceScore > highestRelevance) {
          highestRelevance = relevanceScore;
          bestMatch = topic;
        }
      });
      
      // If we found a match, add the video to that topic group
      if (bestMatch && highestRelevance > 0) {
        moduleGroups[bestMatch].push(video);
      }
    });
    
    // Convert groups to module objects with enhanced content
    const modulePromises = Object.entries(moduleGroups)
      .filter(([_, videos]) => videos.length > 0)
      .map(async ([topic, videos], index) => {
        // Generate enhanced content for this topic using AI
        const readingMaterial = await this.generateReadingContent(topic, level);
        const caseStudy = await this.generateCaseStudy(topic, level);
        const practiceExercise = await this.generatePracticeExercise(topic, level);
        
        // Create lessons array with mixed content types
        const lessons = [];
        let lessonCounter = 0;
        
        // Add introduction reading first
        lessons.push({
          id: `L${101 + lessonCounter++}`, // Using new ID format
          lesson_title: `Introduction to ${topic}`,
          lesson_slug: this.generateSlug(`Introduction to ${topic}`),
          content_type: 'reading',
          order: lessons.length + 1,
          description: `An introduction to the key concepts of ${topic}`,
          reading_material: readingMaterial,
          duration: `${readingMaterial.estimatedReadingTime} mins`
        });
        
        // Add videos
        videos.forEach((video) => {
          lessons.push({
            id: `L${101 + lessonCounter++}`, // Using new ID format
            lesson_title: video.title || `Video: ${topic} - Part ${lessonCounter}`,
            lesson_slug: this.generateSlug(video.title || `Video ${topic} Part ${lessonCounter}`),
            content_type: 'video',
            video_url: video.youtubeUrl || '',
            videoID: video.videoID || video.id,
            duration: video.duration || "Unknown",
            order: lessons.length + 1,
            description: video.description || `Video lesson on ${topic}`,
            instructor: video.instructor || 'Expert Instructor',
            thumbnail: video.thumbnail || ''
          });
        });
        
        // Add case study in the middle (or at an appropriate point)
        if (lessons.length > 3) {
          lessons.splice(Math.floor(lessons.length / 2), 0, {
            id: `L${101 + lessonCounter++}`, // Using new ID format
            lesson_title: caseStudy.title,
            lesson_slug: this.generateSlug(caseStudy.title),
            content_type: 'case_study',
            order: lessons.length + 1,
            description: `A real-world case study applying ${topic}`,
            case_study: caseStudy,
            duration: `${caseStudy.estimatedCompletionTime} mins`
          });
        } else {
          lessons.push({
            id: `L${101 + lessonCounter++}`, // Using new ID format
            lesson_title: caseStudy.title,
            lesson_slug: this.generateSlug(caseStudy.title),
            content_type: 'case_study',
            order: lessons.length + 1,
            description: `A real-world case study applying ${topic}`,
            case_study: caseStudy,
            duration: `${caseStudy.estimatedCompletionTime} mins`
          });
        }
        
        // Add practice exercise at the end
        lessons.push({
          id: `L${101 + lessonCounter++}`, // Using new ID format
          lesson_title: practiceExercise.title,
          lesson_slug: this.generateSlug(practiceExercise.title),
          content_type: 'practice',
          order: lessons.length + 1,
          description: `Hands-on practice with ${topic}`,
          practice_exercise: practiceExercise,
          duration: `${practiceExercise.estimatedCompletionTime} mins`
        });
        
        // Generate a summary reading using AI
        const summaryPrompt = `Create a concise summary of key points about "${topic}" for ${level} level learners. Include best practices for implementation in real-world scenarios. Format as a short markdown document.`;
        let summaryContent;
        try {
          summaryContent = await this.generateAIContent(summaryPrompt, {
            temperature: 0.5,
            maxOutputTokens: 600
          });
        } catch (error) {
          console.error(`Error generating summary for ${topic}:`, error);
          summaryContent = `This reading summarizes the key points about ${topic} and provides best practices for implementation in real-world scenarios.`;
        }
        
        const summaryReading = {
          title: `${topic} - Summary and Best Practices`,
          content: summaryContent,
          estimatedReadingTime: Math.ceil(summaryContent.split(/\s+/).length / 200) || 5 // ~200 words per minute with fallback
        };
        
        lessons.push({
          id: `L${101 + lessonCounter++}`, // Using new ID format
          lesson_title: summaryReading.title,
          lesson_slug: this.generateSlug(summaryReading.title),
          content_type: 'reading',
          order: lessons.length + 1,
          description: `A summary of ${topic} with best practices`,
          reading_material: summaryReading,
          duration: `${summaryReading.estimatedReadingTime} mins`
        });
        
        // Order lessons properly
        lessons.forEach((lesson, idx) => {
          lesson.order = idx + 1;
        });
        
        // Return the complete module
        return {
          id: `M${101 + index}`, // Changed to M101, M102, etc.
          module_title: this.generateModuleTitle(topic),
          module_description: `Master ${topic} through videos, readings, case studies, and practical exercises`,
          order: index + 1,
          lessons: lessons
        };
      });
    
    // Wait for all module generation to complete
    return Promise.all(modulePromises);
  }

  /**
   * Generates a readable module title from a topic keyword
   * @param {string} topic - Topic keyword
   */
  generateModuleTitle(topic) {
    // Capitalize first letter of each word
    const formattedTopic = topic
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    
    return `${formattedTopic} Fundamentals`;
  }

  /**
   * Generates URL-friendly slug from a title
   * @param {string} title - The title to convert to a slug
   */
  generateSlug(title) {
    return title
      .toLowerCase()
      .replace(/[^\w\s-]/g, '') // Remove special characters
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-'); // Remove consecutive hyphens
  }

  /**
   * Generate AI content using Firebase's Vertex AI model
   * @param {string} prompt - The prompt for content generation
   * @param {Object} options - Additional options for the generation
   */
  async generateAIContent(prompt, options = {}) {
    try {
      const defaultOptions = {
        temperature: 0.7,
        maxOutputTokens: 800,
        topK: 40,
        topP: 0.95,
      };
      
      const generationOptions = { ...defaultOptions, ...options };
      
      // Ensure model is initialized
      if (!model) {
        throw new Error("AI model not initialized");
      }
      
      const result = await model.generateContent({
        contents: [{ role: "user", parts: [{ text: prompt }] }],
        generationConfig: generationOptions,
      });
      
      if (!result || !result.response) {
        throw new Error("Empty response from AI model");
      }
      
      return result.response.text();
    } catch (error) {
      console.error("Error generating AI content:", error);
      // Return fallback content if AI generation fails
      return `Generated content about the requested topic. (AI generation failed: ${error.message})`;
    }
  }

  /**
   * Create a structured prompt for a specific content type
   * @param {string} contentType - Type of content to generate (reading, case_study, practice)
   * @param {string} topic - The topic for the content
   * @param {string} level - The skill level
   */
  createStructuredPrompt(contentType, topic, level) {
    switch (contentType) {
      case 'reading':
        return `Create an educational reading about "${topic}" for ${level} level learners.
        Include:
        - A comprehensive explanation of key concepts
        - Theoretical background
        - Important principles
        - Examples where appropriate
        
        Format your response as a Markdown document with proper headings and sections.
        Keep the content focused, informative, and appropriate for ${level} learners.`;
      
      case 'case_study':
        return `Create a detailed case study about applying "${topic}" in a real-world scenario for ${level} level learners.
        Include:
        - A realistic business scenario
        - The problem that needed solving
        - How "${topic}" was applied to solve the problem
        - The outcome and lessons learned
        
        Format your response as a structured case study with clear sections.`;
      
      case 'practice':
        return `Create a practice exercise about "${topic}" for ${level} level learners.
        Include:
        - Clear instructions
        - 3-5 specific tasks for the learner to complete
        - Helpful hints
        - A sample solution or approach
        
        Make the exercises challenging but appropriate for ${level} level learners.`;
      
      default:
        return `Generate educational content about "${topic}" for ${level} level learners.`;
    }
  }

  /**
   * Parse AI-generated content into structured format
   * @param {string} content - The AI-generated content
   * @param {string} contentType - Type of content (reading, case_study, practice)
   * @param {string} topic - The topic of the content
   */
  parseAIGeneratedContent(content, contentType, topic) {
    // This function extracts structured data from AI-generated content
    
    switch (contentType) {
      case 'reading':
        // Extract title or use default
        let title = content.match(/^#\s(.+?)$/m)?.[1] || `Understanding ${topic}`;
        
        return {
          title: title,
          content: content,
          estimatedReadingTime: Math.ceil(content.split(/\s+/).length / 200) // ~200 words per minute
        };
      
      case 'case_study':
        return {
          title: content.match(/^#\s(.+?)$/m)?.[1] || `${topic} in Practice: A Case Study`,
          scenario: this.extractSection(content, "Scenario") || 
                   this.extractSection(content, "Background") || 
                   "This case study focuses on applying " + topic + " in a real-world scenario.",
          problem: this.extractSection(content, "Problem") || 
                  this.extractSection(content, "Challenge") || 
                  "The problem involves implementing " + topic + " to solve a business challenge.",
          solution: this.extractSection(content, "Solution") || 
                   this.extractSection(content, "Approach") || 
                   "The solution demonstrates effectively utilizing " + topic + " principles.",
          outcome: this.extractSection(content, "Outcome") || 
                  this.extractSection(content, "Results") || 
                  "The outcome highlights the benefits of applying " + topic + ".",
          estimatedCompletionTime: Math.floor(Math.random() * 20) + 10 // 10-30 minutes
        };
      
      case 'practice':
        // Extract tasks from content (either from a list or a section)
        let taskMatches = Array.from(content.matchAll(/[*-]\s(.+?)$/gm)).map(m => m[1]);
        if (taskMatches.length === 0) {
          // Try to extract from a Tasks/Exercises section
          const tasksSection = this.extractSection(content, "Tasks") || 
                               this.extractSection(content, "Exercises");
          if (tasksSection) {
            taskMatches = Array.from(tasksSection.matchAll(/[*-]\s(.+?)$/gm)).map(m => m[1]);
          }
        }
        
        // Extract hints similarly
        let hintMatches = [];
        const hintsSection = this.extractSection(content, "Hints") || 
                             this.extractSection(content, "Tips");
        if (hintsSection) {
          hintMatches = Array.from(hintsSection.matchAll(/[*-]\s(.+?)$/gm)).map(m => m[1]);
        }
        
        return {
          title: content.match(/^#\s(.+?)$/m)?.[1] || `Practice: Applying ${topic}`,
          instructions: this.extractSection(content, "Instructions") || 
                        "Complete the following tasks to practice your skills with " + topic + ".",
          tasks: taskMatches.length > 0 ? 
                taskMatches : 
                [`Task 1: Implement a basic feature using ${topic}`,
                 `Task 2: Debug an issue related to ${topic}`,
                 `Task 3: Optimize a solution involving ${topic}`],
          hints: hintMatches.length > 0 ? 
                hintMatches : 
                [`Consider the core principles of ${topic} when approaching the tasks.`],
          solution: this.extractSection(content, "Solution") || 
                    this.extractSection(content, "Sample Solution") || 
                    "Apply the concepts you've learned about " + topic + " to solve these challenges.",
          estimatedCompletionTime: Math.floor(Math.random() * 25) + 15 // 15-40 minutes
        };
      
      default:
        return { content };
    }
  }
  
  /**
   * Extract a section from markdown content
   * @param {string} content - The markdown content
   * @param {string} sectionName - The name of the section to extract
   */
  extractSection(content, sectionName) {
    const regex = new RegExp(`##\\s*${sectionName}\\s*\n([\\s\\S]*?)(?=\\n##\\s|$)`, 'i');
    const match = content.match(regex);
    return match ? match[1].trim() : null;
  }

  /**
   * Generate AI content for reading material using Vertex AI
   * @param {string} topic - The topic for the reading material
   * @param {string} level - The skill level
   */
  async generateReadingContent(topic, level) {
    try {
      const prompt = this.createStructuredPrompt('reading', topic, level);
      const content = await this.generateAIContent(prompt, {
        temperature: 0.6, // Lower temperature for more factual content
        maxOutputTokens: 1000 // Allow longer content for readings
      });
      
      return this.parseAIGeneratedContent(content, 'reading', topic);
    } catch (error) {
      console.error("Error generating reading material:", error);
      // Fallback to placeholder content
      const complexity = level === 'Beginner' ? 'basic' : level === 'Intermediate' ? 'intermediate' : 'advanced';
      return {
        title: `Understanding ${topic}`,
        content: `This is AI-generated ${complexity} reading material about ${topic}. It would include comprehensive explanations of key concepts, theoretical background, and important principles related to ${topic}.`,
        estimatedReadingTime: Math.floor(Math.random() * 10) + 5 // 5-15 minutes
      };
    }
  }

  /**
   * Generate AI content for case study using Vertex AI
   * @param {string} topic - The topic for the case study
   * @param {string} level - The skill level
   */
  async generateCaseStudy(topic, level) {
    try {
      const prompt = this.createStructuredPrompt('case_study', topic, level);
      const content = await this.generateAIContent(prompt, {
        temperature: 0.7, // Balanced temperature for creativity and relevance
        maxOutputTokens: 1200 // Allow longer content for case studies
      });
      
      return this.parseAIGeneratedContent(content, 'case_study', topic);
    } catch (error) {
      console.error("Error generating case study:", error);
      // Fallback to placeholder content
      const complexity = level === 'Beginner' ? 'simple' : level === 'Intermediate' ? 'moderate' : 'complex';
      return {
        title: `${topic} in Practice: A Case Study`,
        scenario: `This is an AI-generated ${complexity} case study focused on applying ${topic} in a real-world scenario.`,
        problem: `The problem involves implementing ${topic} to solve a business challenge.`,
        solution: `The solution demonstrates how to effectively utilize ${topic} principles to address the issue.`,
        outcome: `The outcome highlights the benefits and lessons learned from applying ${topic}.`,
        estimatedCompletionTime: Math.floor(Math.random() * 20) + 10 // 10-30 minutes
      };
    }
  }

  /**
   * Generate AI content for practice exercise using Vertex AI
   * @param {string} topic - The topic for the practice
   * @param {string} level - The skill level
   */
  async generatePracticeExercise(topic, level) {
    try {
      const prompt = this.createStructuredPrompt('practice', topic, level);
      const content = await this.generateAIContent(prompt, {
        temperature: 0.6, // Balanced temperature for creativity and relevance
        maxOutputTokens: 800 // Shorter content for practice exercises
      });
      
      return this.parseAIGeneratedContent(content, 'practice', topic);
    } catch (error) {
      console.error("Error generating practice exercise:", error);
      // Fallback to placeholder content
      const difficulty = level === 'Beginner' ? 'basic' : level === 'Intermediate' ? 'intermediate' : 'challenging';
      return {
        title: `Practice: Applying ${topic}`,
        instructions: `This is an AI-generated ${difficulty} practice exercise for ${topic}.`,
        tasks: [
          `Task 1: Implement a basic feature using ${topic}`,
          `Task 2: Debug an issue related to ${topic}`,
          `Task 3: Optimize a solution involving ${topic}`
        ],
        hints: [
          `Consider the core principles of ${topic} when approaching Task 1.`,
          `Common issues with ${topic} include [specific challenges].`,
          `For optimization, focus on [relevant aspects] of ${topic}.`
        ],
        solution: `Here's a sample solution approach for the practice exercises.`,
        estimatedCompletionTime: Math.floor(Math.random() * 25) + 15 // 15-40 minutes
      };
    }
  }

  /**
   * Enrolls a user in a newly generated course
   * @param {string} userId - The user's ID
   * @param {Object} course - The generated course object
   * @param {Object} firstModule - The first module of the course
   * @param {Object} firstLesson - The first lesson of the first module
   */
  async enrollUserInCourse(userId, course, firstModule, firstLesson) {
    try {
      // Create enrollment document in the enrollments collection
      const enrollmentDocRef = doc(db, 'enrollments', `${userId}_${course.id}`);
      
      const enrollmentData = {
        userId: userId,
        courseId: course.id,
        title: course.course_title,
        category: course.category || 'other',
        startDate: new Date().toISOString(),
        status: 'active',
        progress: 0,
        lastAccessed: new Date().toISOString(),
        currentLesson: `${course.id}_${firstModule.id}_${firstLesson.id}`,
        isGeneratedCourse: true
      };
      
      await setDoc(enrollmentDocRef, enrollmentData, { merge: true });
      
      // Update user's Enrollments subcollection
      const userRef = doc(db, 'users', userId);
      const userEnrollmentsRef = collection(userRef, 'Enrollments');
      await setDoc(
        doc(userEnrollmentsRef, userId),
        {
          enrolledCourses: arrayUnion(course.id),
          enrollmentID: arrayUnion(`${userId}_${course.id}`),
        },
        { merge: true }
      );
      
      return enrollmentData;
    } catch (error) {
      console.error("Error enrolling user in course:", error);
      throw error;
    }
  }

  /**
   * Generate a course based on user preferences and available videos
   * @param {string} userId - The user's ID
   * @param {Object} preferences - User's course preferences
   */
  async generatePersonalizedCourse(userId, preferences) {
    try {
      // Get all videos since we're no longer filtering by category
      let videos = await this.getAllVideos();
      
      // Filter by skill level if specified
      if (preferences.level && videos.length > 0 && preferences.level !== 'All Levels') {
        videos = videos.filter(video => 
          video.level === preferences.level || !video.level
        );
      }
      
      // If we have enough videos, generate the course
      if (videos.length >= 3) {
        // Analyze video content to extract main topics
        const mainTopics = this.analyzeVideoContent(videos);
        
        // Organize videos into coherent modules
        const modules = this.organizeIntoModules(videos, mainTopics);
        
        // Generate course title if not provided
        const courseTitle = preferences.title || `Course on ${mainTopics[0] || 'Mixed Topics'}`;
        
        // Create course metadata with default values for removed fields
        const courseData = {
          course_title: courseTitle,
          category: 'Mixed', // Default category
          level: preferences.level || 'All Levels',
          description: `An AI-generated course on ${mainTopics.slice(0, 3).join(', ')}.`, // Auto-generated description
          overview: `This AI-generated course covers ${mainTopics.slice(0, 3).join(', ')} and more.`,
          duration: this.calculateTotalDuration(modules),
          createdAt: serverTimestamp(),
          createdBy: userId,
          isPersonalized: true,
          thumbnail: '',
          skills: mainTopics.slice(0, 5),
          prerequisite: 'None',
          certificate: 'No',
          language: preferences.language || 'English'
        };
        
        // Save course to user's collection only (NOT to main courses collection)
        const userGeneratedCoursesRef = collection(
          db, 
          'users', 
          userId, 
          'GeneratedCourses'
        );
        
        const courseRef = await addDoc(userGeneratedCoursesRef, courseData);
        
        // Add modules to the user's course
        for (const module of modules) {
          const moduleRef = doc(
            collection(db, 'users', userId, 'GeneratedCourses', courseRef.id, 'modules'),
            module.id
          );
          await setDoc(moduleRef, {
            module_title: module.module_title,
            module_description: module.module_description,
            order: module.order
          });
          
          // Add lessons to the module (in user's collection)
          for (const lesson of module.lessons) {
            const lessonRef = doc(
              collection(
                db, 
                'users', 
                userId, 
                'GeneratedCourses', 
                courseRef.id, 
                'modules', 
                module.id, 
                'lessons'
              ),
              lesson.id
            );
            await setDoc(lessonRef, lesson);
          }
        }
        
        // Get the first module and first lesson for enrollment
        const firstModule = modules[0];
        const firstLesson = firstModule.lessons[0];
        
        // Enroll the user in the newly created course
        const enrollmentData = await this.enrollUserInCourse(
          userId, 
          { 
            id: courseRef.id, 
            ...courseData 
          }, 
          firstModule, 
          firstLesson
        );
        
        // Return the created course ID with enrollment data
        return {
          id: courseRef.id,
          ...courseData,
          modules,
          enrollmentData
        };
      } else {
        throw new Error("Not enough videos available to generate a course");
      }
    } catch (error) {
      console.error("Error generating personalized course:", error);
      throw error;
    }
  }

  /**
   * Generate a comprehensive personalized course based on user preferences
   * @param {string} userId - The user's ID
   * @param {Object} preferences - User's course preferences including topic and level
   */
  async generateComprehensiveCourse(userId, preferences) {
    try {
      console.log("Starting course generation with preferences:", preferences);
      
      // Get relevant videos based on the specified topic or keywords
      let relevantVideos = [];
      
      if (preferences.topic) {
        console.log(`Finding videos for topic: ${preferences.topic}`);
        
        // Try exact topic match first
        const topicVideos = await this.getVideosByTopic(preferences.topic);
        if (topicVideos && topicVideos.length > 0) {
          console.log(`Found ${topicVideos.length} videos with exact topic match`);
          relevantVideos = [...topicVideos];
        }
        
        // If we still need more videos, do a keyword search
        if (relevantVideos.length < 5) {
          console.log("Searching for additional videos based on keywords");
          const allVideos = await this.getAllVideos();
          
          // Create regex patterns from the topic keywords
          const keywords = preferences.topic
            .toLowerCase()
            .split(/\s+/)
            .filter(word => word.length > 3);
          
          // Find videos containing any of the keywords in their title/description
          const keywordVideos = allVideos.filter(video => {
            if (relevantVideos.some(rv => rv.id === video.id)) {
              return false; // Skip videos we already have
            }
            
            const content = `${video.title || ''} ${video.description || ''} ${video.topic || ''} ${video.topicTaught || ''}`.toLowerCase();
            return keywords.some(keyword => content.includes(keyword));
          });
          
          console.log(`Found ${keywordVideos.length} additional videos from keyword search`);
          relevantVideos = [...relevantVideos, ...keywordVideos];
        }
      }
      
      // If no specific topic or not enough videos found, get videos by level
      if (relevantVideos.length < 3 && preferences.level && preferences.level !== 'All Levels') {
        console.log(`Finding videos for level: ${preferences.level}`);
        const levelVideos = await this.getVideosByLevel(preferences.level);
        
        // Add only new videos that we don't already have
        const newLevelVideos = levelVideos.filter(video => 
          !relevantVideos.some(rv => rv.id === video.id)
        );
        
        console.log(`Found ${newLevelVideos.length} videos for level ${preferences.level}`);
        relevantVideos = [...relevantVideos, ...newLevelVideos];
      }
      
      // If still not enough videos, get a limited number of all videos
      if (relevantVideos.length < 3) {
        console.log("Not enough specific videos, adding some general videos");
        const allVideos = await this.getAllVideos();
        
        // Add only new videos that we don't already have, limit to 10
        const newVideos = allVideos
          .filter(video => !relevantVideos.some(rv => rv.id === video.id))
          .slice(0, 10);
        
        console.log(`Added ${newVideos.length} general videos`);
        relevantVideos = [...relevantVideos, ...newVideos];
      }
      
      // Limit total videos to 15 max to keep course focused
      relevantVideos = relevantVideos.slice(0, 15);
      
      console.log(`Final video count for course: ${relevantVideos.length}`);
      
      // If we have enough videos, generate the course
      if (relevantVideos.length >= 3) {
        // Generate an AI title for the course based on the videos and preferences
        const courseTitle = await this.generateAICourseTitle(relevantVideos, preferences);
        
        // Extract the most relevant topics from the videos
        const mainTopics = this.analyzeVideoContent(relevantVideos);
        
        // Add the user's requested topic if specified
        if (preferences.topic && !mainTopics.includes(preferences.topic.toLowerCase())) {
          mainTopics.unshift(preferences.topic.toLowerCase());
        }
        
        console.log("Starting enhanced module organization with AI content generation...");
        
        // Organize videos into enhanced modules with mixed content types
        const modules = await this.organizeIntoEnhancedModules(
          relevantVideos, 
          mainTopics.slice(0, 3), // Use top 3 topics for modules
          preferences.level || 'All Levels'
        );
        
        console.log("AI content generation completed for all modules");
        
        // Generate course overview with AI
        const overview = await this.generateAICourseOverview(relevantVideos, preferences, mainTopics);
        
        // Generate skills with AI
        const skills = await this.generateAICourseSkills(relevantVideos, preferences, mainTopics);
        
        // Calculate total lessons count
        let totalLessons = 0;
        modules.forEach(module => {
          totalLessons += module.lessons.length;
        });
        
        // Calculate total duration
        const duration = this.calculateTotalDuration(modules);
        
        // Create course metadata following the specified schema
        const courseData = {
          courseID: `AI_${Date.now().toString(36)}`,
          course_title: courseTitle,
          category: preferences.topic ? preferences.topic.split(' ')[0] : 'Mixed',
          certificates: preferences.certificates || true,
          duration: duration,
          overview: overview,
          platform: "AI Generated",
          skills: skills,
          status: "published",
          createdTime: serverTimestamp(),
          modifiedTime: serverTimestamp(),
          lessons: totalLessons,
          modules: modules.length,
          isGeneratedCourse: true
        };
        
        console.log("Course metadata created, saving to Firestore");
        
        // Save course to Firestore in the user's Generated Courses subcollection
        const userGeneratedCoursesRef = collection(
          db, 
          'users', 
          userId, 
          'GeneratedCourses'
        );
        
        const courseRef = await addDoc(userGeneratedCoursesRef, courseData);
        console.log(`Course document created with ID: ${courseRef.id}`);
        
        // Add modules to the user's course
        for (const module of modules) {
          const moduleData = {
            moduleID: module.id,
            module_title: module.module_title,
            overview: `${module.module_description}`,
            duration: this.calculateModuleDuration(module.lessons)
          };
          
          const userModuleRef = doc(
            collection(
              db, 
              'users', 
              userId, 
              'GeneratedCourses', 
              courseRef.id, 
              'modules'
            ),
            module.id
          );
          
          await setDoc(userModuleRef, moduleData);
          
          // Add lessons to the user's module
          for (const lesson of module.lessons) {
            const userLessonRef = doc(
              collection(
                db, 
                'users', 
                userId, 
                'GeneratedCourses', 
                courseRef.id, 
                'modules', 
                module.id, 
                'lessons'
              ),
              lesson.id
            );
            await setDoc(userLessonRef, lesson);
          }
        }
        
        // Get the first module and first lesson for enrollment
        const firstModule = modules[0];
        const firstLesson = firstModule.lessons[0];
        
        // Enroll the user in the newly created course
        const enrollmentData = await this.enrollUserInCourse(
          userId, 
          { 
            id: courseRef.id, 
            ...courseData 
          }, 
          firstModule, 
          firstLesson
        );
        
        console.log("Course creation and enrollment complete");
        
        // Return the created course with modules and enrollment data
        return {
          id: courseRef.id,
          ...courseData,
          modules,
          enrollmentData
        };
      } else {
        throw new Error("Not enough videos available to generate a comprehensive course");
      }
    } catch (error) {
      console.error("Error generating comprehensive course:", error);
      throw error;
    }
  }

  /**
   * Generate an AI-created title for the course
   * @param {Array} videos - The videos in the course
   * @param {Object} preferences - User preferences
   */
  async generateAICourseTitle(videos, preferences) {
    try {
      // Extract video titles and topics for context
      const videoTitles = videos.slice(0, 5).map(v => v.title).filter(Boolean);
      const prompt = `
        Create a concise, engaging title for an educational course about ${preferences.topic || 'various topics'}.
        The course is for ${preferences.level || 'all level'} learners.
        The course will include videos on these topics: ${videoTitles.join(', ')}
        
        Generate only the title, no quotes or explanation. The title should be less than 8 words and catchy.
      `;
      
      const titleResponse = await this.generateAIContent(prompt, {
        temperature: 0.8,
        maxOutputTokens: 50
      });
      
      // Clean up the response - remove quotes and periods
      let title = titleResponse.replace(/["'.]/g, '').trim();
      
      // If the AI didn't generate a good title, use a fallback
      if (!title || title.length < 3 || title.length > 60) {
        if (preferences.topic) {
          title = `Mastering ${preferences.topic} - ${preferences.level || 'Complete'} Course`;
        } else {
          const mainTopic = videoTitles[0]?.split(' ').slice(0, 3).join(' ') || 'Professional Skills';
          title = `${mainTopic} Masterclass`;
        }
      }
      
      return title;
    } catch (error) {
      console.error("Error generating course title:", error);
      return preferences.topic ? 
        `${preferences.topic} ${preferences.level || 'Complete'} Course` : 
        'Professional Development Course';
    }
  }

  /**
   * Generate an AI-created overview for the course
   * @param {Array} videos - The videos in the course
   * @param {Object} preferences - User preferences
   * @param {Array} mainTopics - Main topics in the course
   */
  async generateAICourseOverview(videos, preferences, mainTopics) {
    try {
      // Extract video titles for context
      const videoTitles = videos.slice(0, 5).map(v => v.title).filter(Boolean);
      const prompt = `
        Create a concise, informative overview for an educational course about ${preferences.topic || mainTopics.slice(0, 3).join(', ')}.
        The course is designed for ${preferences.level || 'all level'} learners.
        The course covers: ${mainTopics.slice(0, 5).join(', ')}
        Some video topics include: ${videoTitles.join(', ')}
        
        Write 2-3 sentences that explain what learners will gain from this course.
        Keep it concise, engaging, and focused on outcomes.
      `;
      
      const overviewResponse = await this.generateAIContent(prompt, {
        temperature: 0.7,
        maxOutputTokens: 150
      });
      
      return overviewResponse.trim();
    } catch (error) {
      console.error("Error generating course overview:", error);
      return `This course covers ${mainTopics.slice(0, 3).join(', ')} and provides practical knowledge for ${preferences.level || 'all'} level learners. You'll learn key concepts and gain applicable skills.`;
    }
  }

  /**
   * Generate AI-created skills for the course
   * @param {Array} videos - The videos in the course
   * @param {Object} preferences - User preferences
   * @param {Array} mainTopics - Main topics in the course
   */
  async generateAICourseSkills(videos, preferences, mainTopics) {
    try {
      const prompt = `
        Create a list of 5-7 specific skills that learners will gain from a course about ${preferences.topic || mainTopics.slice(0, 3).join(', ')}.
        The course is for ${preferences.level || 'all level'} learners.
        
        Format your response as a JSON array of strings, each representing one skill.
        Each skill should be concise (3-6 words) and start with a verb.
        Example: ["Build responsive websites", "Optimize database queries"]
      `;
      
      const skillsResponse = await this.generateAIContent(prompt, {
        temperature: 0.7,
        maxOutputTokens: 200
      });
      
      // Try to parse as JSON first
      try {
        const skills = JSON.parse(skillsResponse);
        if (Array.isArray(skills) && skills.length > 0) {
          return skills.slice(0, 7); // Limit to max 7 skills
        }
      } catch (e) {
        // If not valid JSON, extract skills using regex
        const skillMatches = skillsResponse.match(/["'](.+?)["']/g);
        if (skillMatches && skillMatches.length > 0) {
          return skillMatches
            .map(match => match.replace(/["']/g, '').trim())
            .filter(skill => skill.length > 0)
            .slice(0, 7);
        }
      }
      
      // If we still don't have skills, extract from main topics
      return mainTopics.slice(0, 5).map(topic => `Master ${topic}`);
    } catch (error) {
      console.error("Error generating course skills:", error);
      return mainTopics.slice(0, 5).map(topic => `Master ${topic}`);
    }
  }

  /**
   * Calculate duration for a specific module
   * @param {Array} lessons - Lessons in the module
   */
  calculateModuleDuration(lessons) {
    let totalMinutes = 0;
    
    lessons.forEach(lesson => {
      if (lesson.duration) {
        if (typeof lesson.duration === 'string') {
          // Handle different duration formats
          if (lesson.duration.includes(':')) {
            // Format MM:SS
            const parts = lesson.duration.split(':');
            if (parts.length === 2) {
              const minutes = parseInt(parts[0]);
              totalMinutes += minutes;
            }
          } else if (lesson.duration.includes('min')) {
            // Format "X mins"
            const match = lesson.duration.match(/(\d+)/);
            if (match && match[1]) {
              totalMinutes += parseInt(match[1]);
            }
          } else {
            // Try to parse as integer
            const mins = parseInt(lesson.duration);
            if (!isNaN(mins)) {
              totalMinutes += mins;
            }
          }
        }
      }
    });
    
    // Format module duration
    if (totalMinutes < 60) {
      return `${totalMinutes} mins`;
    } else {
      const hours = Math.floor(totalMinutes / 60);
      const mins = totalMinutes % 60;
      return mins > 0 ? `${hours} hr ${mins} mins` : `${hours} hr`;
    }
  }

  /**
   * Calculate total course duration from all modules and lessons including non-video content
   * @param {Array} modules - Course modules with lessons
   */
  calculateTotalDuration(modules) {
    let totalMinutes = 0;
    
    modules.forEach(module => {
      module.lessons.forEach(lesson => {
        if (lesson.duration) {
          if (typeof lesson.duration === 'string') {
            // Handle different duration formats
            if (lesson.duration.includes(':')) {
              // Format MM:SS
              const parts = lesson.duration.split(':');
              if (parts.length === 2) {
                const minutes = parseInt(parts[0]);
                totalMinutes += minutes;
              }
            } else if (lesson.duration.includes('min')) {
              // Format "X mins"
              const match = lesson.duration.match(/(\d+)/);
              if (match && match[1]) {
                totalMinutes += parseInt(match[1]);
              }
            } else {
              // Try to parse as integer
              const mins = parseInt(lesson.duration);
              if (!isNaN(mins)) {
                totalMinutes += mins;
              }
            }
          }
        }
      });
    });
    
    // Format total duration
    if (totalMinutes < 60) {
      return `${totalMinutes} mins`;
    } else {
      const hours = Math.floor(totalMinutes / 60);
      const mins = totalMinutes % 60;
      return mins > 0 ? `${hours} hr ${mins} mins` : `${hours} hr`;
    }
  }

  /**
   * Organize videos into modules for visual learning
   * @param {Array} videos - Array of video objects
   * @param {string} topic - The topic of the course
   */
  organizeVideosIntoModules(videos, topic) {
    if (!videos || !Array.isArray(videos)) {
      throw new Error("Invalid videos array");
    }

    return [
      {
        id: 'M101',
        module_title: `Introduction to ${topic}`,
        lessons: videos.map((video, index) => ({
          id: `L${101 + index}`,
          lesson_title: video.title,
          video_url: video.url,
          description: video.description,
          thumbnail: video.thumbnail,
        })),
      },
    ];
  }

  /**
   * Generate a fully autonomous course from scratch
   * @param {string} userId - The user's ID
   * @param {Object} preferences - User's course preferences
   */
  async generateAutonomousCourse(userId, preferences) {
    try {
      const { topic, level, language, duration, learningType } = preferences;
      
      // Ensure required fields exist
      if (!topic) {
        throw new Error("Topic is required to generate a course");
      }
      
      console.log("Starting autonomous course generation for:", topic);
      
      // 1. Generate course metadata first (without videos)
      const courseStructure = await this.generateCourseStructure(topic, level, language, duration);
      
      // 2. Generate module structure
      const moduleStructure = await this.generateModuleStructure(
        courseStructure.title, 
        topic, 
        level, 
        learningType,
        duration
      );
      
      // 3. Populate modules with content based on learning type
      const populatedModules = await this.populateModulesWithContent(
        moduleStructure,
        topic,
        level,
        learningType
      );
      
      // 4. Create the complete course data, ensuring no undefined values
      const courseData = {
        course_title: courseStructure.title || `Course on ${topic}`,
        category: topic ? topic.split(' ')[0] : 'General',
        level: level || 'All Levels',
        duration: courseStructure.duration || '1 hr',
        language: language || 'English',
        overview: courseStructure.description || `A comprehensive course about ${topic}`,
        // Ensure skills is always an array
        skills: Array.isArray(courseStructure.skills) ? courseStructure.skills : [`Learn ${topic}`],
        // Ensure modules don't contain undefined values
        modules: populatedModules.map(module => {
          // Remove any potential undefined values from module objects
          const cleanModule = { ...module };
          Object.keys(cleanModule).forEach(key => {
            if (cleanModule[key] === undefined) {
              delete cleanModule[key];
            }
          });
          return cleanModule;
        }),
        createdAt: new Date().toISOString(),
        createdBy: userId,
        isGeneratedCourse: true,
        status: "published",
        createdTime: serverTimestamp(),
        modifiedTime: serverTimestamp()
      };
      
      // 5. Save to Firestore
      const courseRef = await addDoc(collection(db, 'users', userId, 'GeneratedCourses'), courseData);
      
      // 6. Create module documents, ensuring no undefined values in each module
      for (const module of populatedModules) {
        const moduleData = {
          moduleID: module.id || `M${Date.now()}`,
          module_title: module.module_title || `Module on ${topic}`,
          overview: module.module_description || `Content about ${topic}`,
          duration: this.calculateModuleDuration(module.lessons) || '10 mins'
        };
        
        const userModuleRef = doc(
          collection(db, 'users', userId, 'GeneratedCourses', courseRef.id, 'modules'),
          module.id || `M${Date.now()}`
        );
        
        await setDoc(userModuleRef, moduleData);
        
        // 7. Create lesson documents, ensuring no undefined values in each lesson
        for (const lesson of module.lessons) {
          // Create a clean lesson object without undefined values
          const cleanLesson = { ...lesson };
          Object.keys(cleanLesson).forEach(key => {
            if (cleanLesson[key] === undefined) {
              delete cleanLesson[key];
            }
          });
          
          const userLessonRef = doc(
            collection(
              db, 
              'users', 
              userId, 
              'GeneratedCourses', 
              courseRef.id, 
              'modules', 
              module.id || `M${Date.now()}`, 
              'lessons'
            ),
            lesson.id || `L${Date.now()}`
          );
          
          await setDoc(userLessonRef, cleanLesson);
        }
      }
      
      // 8. Enroll the user in the course
      const firstModule = populatedModules[0];
      const firstLesson = firstModule.lessons[0];
      
      const enrollmentData = await this.enrollUserInCourse(
        userId, 
        { id: courseRef.id, ...courseData }, 
        firstModule, 
        firstLesson
      );
      
      return {
        id: courseRef.id,
        ...courseData,
        enrollmentData
      };
    } catch (error) {
      console.error("Error generating autonomous course:", error);
      throw error;
    }
  }

  /**
   * Generate the high-level course structure using AI
   * @param {string} topic - The main topic of the course
   * @param {string} level - The target skill level
   * @param {string} language - The language for the course
   * @param {string} duration - The target duration of the course
   */
  async generateCourseStructure(topic, level, language, duration) {
    try {
      // Generate a course title
      const titlePrompt = `
        Create a concise, engaging title for an educational course about ${topic}.
        The course is for ${level || 'all level'} learners in ${language || 'English'}.
        Generate only the title, no quotes or explanation. The title should be less than 8 words and catchy.
      `;
      
      const title = await this.generateAIContent(titlePrompt, {
        temperature: 0.8,
        maxOutputTokens: 50
      });
      
      // Generate a course description
      const descriptionPrompt = `
        Create a concise, informative overview for an educational course about ${topic}.
        The course is designed for ${level || 'all level'} learners.
        Write 2-3 sentences that explain what learners will gain from this course.
        Keep it concise, engaging, and focused on outcomes.
      `;
      
      const description = await this.generateAIContent(descriptionPrompt, {
        temperature: 0.7,
        maxOutputTokens: 150
      });
      
      // Generate course skills
      const skillsPrompt = `
        Create a list of 5-7 specific skills that learners will gain from a course about ${topic}.
        The course is for ${level || 'all level'} learners.
        Format your response as a JSON array of strings, each representing one skill.
        Each skill should be concise (3-6 words) and start with a verb.
        Example: ["Build responsive websites", "Optimize database queries"]
      `;
      
      const skillsResponse = await this.generateAIContent(skillsPrompt, {
        temperature: 0.7,
        maxOutputTokens: 200
      });
      
      // Parse skills
      let skills = [];
      try {
        skills = JSON.parse(skillsResponse);
        if (!Array.isArray(skills)) {
          skills = [];
        }
      } catch (e) {
        // If not valid JSON, extract skills using regex
        const skillMatches = skillsResponse.match(/["'](.+?)["']/g);
        if (skillMatches && skillMatches.length > 0) {
          skills = skillMatches
            .map(match => match.replace(/["']/g, '').trim())
            .filter(skill => skill.length > 0);
        }
      }
      
      // Determine approximate duration based on preference
      let estimatedHours = 1;
      if (duration.includes('Medium')) {
        estimatedHours = 3;
      } else if (duration.includes('Long')) {
        estimatedHours = 5;
      }
      
      const formattedDuration = estimatedHours > 1 ? `${estimatedHours} hrs` : `${estimatedHours} hr`;
      
      return {
        title: title.trim().replace(/["'.]/g, ''),
        description: description.trim(),
        skills: skills.slice(0, 7),
        duration: formattedDuration
      };
    } catch (error) {
      console.error("Error generating course structure:", error);
      // Provide fallback structure
      return {
        title: `Mastering ${topic}`,
        description: `This course covers essential concepts of ${topic} and provides practical knowledge for ${level || 'all'} level learners.`,
        skills: [`Master ${topic}`, `Apply ${topic} concepts`, `Solve ${topic} problems`],
        duration: '2 hrs'
      };
    }
  }

  /**
   * Generate module structure for the course
   * @param {string} courseTitle - The title of the course
   * @param {string} topic - The main topic of the course
   * @param {string} level - The target skill level
   * @param {string} learningType - The preferred learning type
   * @param {string} duration - The target duration of the course
   */
  async generateModuleStructure(courseTitle, topic, level, learningType, duration) {
    try {
      // Determine how many modules based on duration
      let moduleCount = 3;
      if (duration.includes('Medium')) {
        moduleCount = 4;
      } else if (duration.includes('Long')) {
        moduleCount = 5;
      }
      
      // Generate module structure with AI
      const modulePrompt = `
        Create a logical structure for a course about ${topic} titled "${courseTitle}".
        The course should have ${moduleCount} modules that build upon each other.
        For each module, provide:
        1. A clear, concise title
        2. A brief description of what the module covers
        3. 3-5 key lesson topics for the module
        
        Respond with a JSON array with objects that have the following structure:
        [
          {
            "title": "Module title",
            "description": "Brief description",
            "lessons": ["Lesson topic 1", "Lesson topic 2", "Lesson topic 3"]
          }
        ]
        
        The modules should progress logically from fundamentals to more advanced topics.
        Important: Return only the JSON array with no additional formatting, comments, or markdown code blocks.
      `;
      
      const moduleResponse = await this.generateAIContent(modulePrompt, {
        temperature: 0.7,
        maxOutputTokens: 800
      });
      
      // Parse the structure - clean up the response to remove any markdown formatting
      let cleanedResponse = moduleResponse;
      
      // Remove markdown code block formatting if present
      if (moduleResponse.includes('```')) {
        // Extract content between code blocks
        const codeBlockMatch = moduleResponse.match(/```(?:json)?\s*([\s\S]*?)```/);
        if (codeBlockMatch && codeBlockMatch[1]) {
          cleanedResponse = codeBlockMatch[1].trim();
        }
      }
      
      let moduleStructure = [];
      try {
        moduleStructure = JSON.parse(cleanedResponse);
        if (!Array.isArray(moduleStructure)) {
          throw new Error("Invalid module structure format");
        }
      } catch (e) {
        console.error("Error parsing module structure:", e);
        // Create a fallback structure
        moduleStructure = [];
        for (let i = 0; i < moduleCount; i++) {
          moduleStructure.push({
            title: i === 0 ? `Introduction to ${topic}` : `Advanced ${topic} - Part ${i}`,
            description: `This module covers important concepts related to ${topic}.`,
            lessons: [`${topic} basics`, `${topic} techniques`, `${topic} applications`]
          });
        }
      }
      
      // Format the modules with IDs
      return moduleStructure.map((module, index) => ({
        id: `M${101 + index}`,
        module_title: module.title || `Module ${index + 1}`,
        module_description: module.description || `Learn about ${topic}`,
        order: index + 1,
        lesson_topics: Array.isArray(module.lessons) ? module.lessons : [`${topic} basics`, `${topic} techniques`]
      }));
    } catch (error) {
      console.error("Error generating module structure:", error);
      // Provide fallback module structure
      const moduleStructure = [];
      for (let i = 0; i < 3; i++) {
        moduleStructure.push({
          id: `M${101 + i}`,
          module_title: i === 0 ? `Introduction to ${topic}` : `Advanced ${topic} - Part ${i}`,
          module_description: `This module covers important concepts related to ${topic}.`,
          order: i + 1,
          lesson_topics: [`${topic} basics`, `${topic} techniques`, `${topic} applications`]
        });
      }
      return moduleStructure;
    }
  }

  /**
   * Populate modules with appropriate content based on learning type
   * @param {Array} moduleStructure - The structural outline of modules
   * @param {string} topic - The main topic of the course
   * @param {string} level - The target skill level
   * @param {string} learningType - The preferred learning type
   */
  async populateModulesWithContent(moduleStructure, topic, level, learningType) {
    try {
      // 1. Fetch relevant videos for the topic to potentially include
      let relevantVideos = [];
      try {
        const topicVideos = await this.getVideosByTopic(topic);
        if (topicVideos && topicVideos.length > 0) {
          relevantVideos = [...topicVideos];
        }
        
        // If we need more, try keyword search
        if (relevantVideos.length < moduleStructure.length * 2) {
          const allVideos = await this.getAllVideos();
          const keywords = topic.toLowerCase().split(/\s+/).filter(word => word.length > 3);
          
          const keywordVideos = allVideos.filter(video => {
            if (relevantVideos.some(rv => rv.id === video.id)) {
              return false; // Skip videos we already have
            }
            
            const content = `${video.title || ''} ${video.description || ''} ${video.topic || ''} ${video.topicTaught || ''}`.toLowerCase();
            return keywords.some(keyword => content.includes(keyword));
          });
          
          relevantVideos = [...relevantVideos, ...keywordVideos].slice(0, 15);
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
      
      // Now populate each module with content
      const populatedModules = [];
      
      for (const module of moduleStructure) {
        let lessonCounter = 0;
        const lessons = [];
        
        // Add an introduction reading for each module
        const readingMaterial = await this.generateReadingContent(
          `${module.module_title || 'Introduction'}: ${module.module_description || `About ${topic}`}`, 
          level
        );
        
        // Ensure we have valid reading material
        const validReadingMaterial = readingMaterial || {
          title: `Introduction to ${topic}`,
          content: `This is an introduction to ${topic}`,
          estimatedReadingTime: 5
        };
        
        lessons.push({
          id: `L${101 + lessonCounter++}`,
          lesson_title: `Introduction to ${module.module_title || topic}`,
          lesson_slug: this.generateSlug(`Introduction to ${module.module_title || topic}`),
          content_type: 'reading',
          order: lessons.length + 1,
          description: `An introduction to ${module.module_title || topic}`,
          reading_material: validReadingMaterial,
          duration: `${validReadingMaterial.estimatedReadingTime || 5} mins`
        });
        
        // Add lessons based on lesson topics
        if (module.lesson_topics && Array.isArray(module.lesson_topics)) {
          for (const lessonTopic of module.lesson_topics) {
            // Based on learning type, prioritize different content types
            // Find videos that match this lesson topic
            const matchingVideos = this.findMatchingVideos(relevantVideos, lessonTopic);
            
            if (learningType === 'Visual' && matchingVideos.length > 0) {
              // Add video lesson
              const video = matchingVideos[0]; // Take the first matching video
              
              // Remove the used video to avoid duplication
              relevantVideos = relevantVideos.filter(v => v.id !== video.id);
              
              lessons.push({
                id: `L${101 + lessonCounter++}`,
                lesson_title: video.title || lessonTopic,
                lesson_slug: this.generateSlug(video.title || lessonTopic),
                content_type: 'video',
                videoID: video.videoID || video.id,
                video_url: video.url,
                duration: video.duration || "10 mins",
                order: lessons.length + 1,
                description: video.description || `Video lesson on ${lessonTopic}`,
                thumbnail: video.thumbnail || ''
              });
            } else if (learningType === 'Practical') {
              // Add practice exercise
              const practiceExercise = await this.generatePracticeExercise(lessonTopic, level);
              
              lessons.push({
                id: `L${101 + lessonCounter++}`,
                lesson_title: practiceExercise.title,
                lesson_slug: this.generateSlug(practiceExercise.title),
                content_type: 'practice',
                order: lessons.length + 1,
                description: `Hands-on practice with ${lessonTopic}`,
                practice_exercise: practiceExercise,
                duration: `${practiceExercise.estimatedCompletionTime || 15} mins`
              });
              
              // Add video as supplementary content if available
              if (matchingVideos.length > 0) {
                const video = matchingVideos[0];
                relevantVideos = relevantVideos.filter(v => v.id !== video.id);
                
                lessons.push({
                  id: `L${101 + lessonCounter++}`,
                  lesson_title: `Video: ${video.title || lessonTopic}`,
                  lesson_slug: this.generateSlug(`Video ${video.title || lessonTopic}`),
                  content_type: 'video',
                  videoID: video.videoID || video.id,
                  video_url: video.url,
                  duration: video.duration || "10 mins",
                  order: lessons.length + 1,
                  description: `Supplementary video on ${lessonTopic}`,
                  thumbnail: video.thumbnail || ''
                });
              }
            } else {
              // Theoretical learning - prioritize readings, then add video
              const readingContent = await this.generateReadingContent(lessonTopic, level);
              
              lessons.push({
                id: `L${101 + lessonCounter++}`,
                lesson_title: readingContent.title,
                lesson_slug: this.generateSlug(readingContent.title),
                content_type: 'reading',
                order: lessons.length + 1,
                description: `Comprehensive reading on ${lessonTopic}`,
                reading_material: readingContent,
                duration: `${readingContent.estimatedReadingTime || 5} mins`
              });
              
              // Add video as supplementary content if available
              if (matchingVideos.length > 0) {
                const video = matchingVideos[0];
                relevantVideos = relevantVideos.filter(v => v.id !== video.id);
                
                lessons.push({
                  id: `L${101 + lessonCounter++}`,
                  lesson_title: `Video: ${video.title || lessonTopic}`,
                  lesson_slug: this.generateSlug(`Video ${video.title || lessonTopic}`),
                  content_type: 'video',
                  videoID: video.videoID || video.id,
                  video_url: video.url,
                  duration: video.duration || "10 mins",
                  order: lessons.length + 1,
                  description: `Supplementary video on ${lessonTopic}`,
                  thumbnail: video.thumbnail || ''
                });
              }
            }
          }
        }
        
        // Add a case study or summary to each module
        const caseStudy = await this.generateCaseStudy(module.module_title || topic, level);
        
        lessons.push({
          id: `L${101 + lessonCounter++}`,
          lesson_title: caseStudy.title,
          lesson_slug: this.generateSlug(caseStudy.title),
          content_type: 'case_study',
          order: lessons.length + 1,
          description: `A real-world case study on ${module.module_title || topic}`,
          case_study: caseStudy,
          duration: `${caseStudy.estimatedCompletionTime || 15} mins`
        });
        
        // Validate each lesson and remove any undefined values
        const validLessons = lessons.map((lesson, idx) => {
          const cleanLesson = { ...lesson };
          
          // Remove any undefined values
          Object.keys(cleanLesson).forEach(key => {
            if (cleanLesson[key] === undefined) {
              delete cleanLesson[key];
            }
          });
          
          return {
            ...cleanLesson,
            order: idx + 1
          };
        });
        
        // Add module to the list with its validated lessons
        populatedModules.push({
          ...module,
          lessons: validLessons
        });
      }
      
      return populatedModules;
    } catch (error) {
      console.error("Error populating modules with content:", error);
      // Return a minimal valid structure in case of error
      return [{
        id: `M101`,
        module_title: `Introduction to ${topic}`,
        module_description: `Learn about ${topic}`,
        order: 1,
        lessons: [{
          id: 'L101',
          lesson_title: `Getting Started with ${topic}`,
          lesson_slug: `getting-started-with-${topic.toLowerCase().replace(/\s+/g, '-')}`,
          content_type: 'reading',
          order: 1,
          description: `An introduction to ${topic}`,
          reading_material: {
            title: `Introduction to ${topic}`,
            content: `This is an introduction to ${topic}.`,
            estimatedReadingTime: 5
          },
          duration: '5 mins'
        }]
      }];
    }
  }

  /**
   * Find videos that match a specific lesson topic
   * @param {Array} videos - Available videos
   * @param {string} lessonTopic - The topic of the lesson
   */
  findMatchingVideos(videos, lessonTopic) {
    if (!videos || !Array.isArray(videos) || videos.length === 0) {
      return [];
    }
    
    const topic = lessonTopic?.toLowerCase() || '';
    const keywords = topic.split(/\s+/).filter(word => word.length > 3);
    
    // Calculate relevance score for each video
    const scoredVideos = videos.map(video => {
      const content = `${video.title || ''} ${video.description || ''} ${video.topic || ''} ${video.topicTaught || ''}`.toLowerCase();
      
      // Calculate exact topic match
      const exactMatchScore = content.includes(topic) ? 5 : 0;
      
      // Calculate keyword matches
      let keywordScore = 0;
      keywords.forEach(keyword => {
        if (content.includes(keyword)) {
          keywordScore += 1;
        }
      });
      
      return {
        video,
        score: exactMatchScore + keywordScore
      };
    });
    
    // Sort by relevance score and return videos with score > 0
    return scoredVideos
      .filter(item => item.score > 0)
      .sort((a, b) => b.score - a.score)
      .map(item => item.video);
  }

  /**
   * Enhanced method to replace generatePersonalizedCourseWithFirestore
   * @param {string} userId - The user's ID
   * @param {Object} preferences - User's course preferences
   */
  async generatePersonalizedCourseWithFirestore(userId, preferences) {
    if (!preferences || !preferences.topic) {
      throw new Error("Topic is required to generate a course");
    }
    
    try {
      return await this.generateAutonomousCourse(userId, preferences);
    } catch (error) {
      console.error("Error in generatePersonalizedCourseWithFirestore:", error);
      
      // Fallback: Create a basic course with minimal content if autonomous generation fails
      const cleanPreferences = {
        title: preferences.title || `Learn ${preferences.topic}`,
        topic: preferences.topic,
        level: preferences.level || 'All Levels',
        language: preferences.language || 'English',
        duration: preferences.duration || 'Short (1-2 weeks)'
      };
      
      const basicCourseData = {
        course_title: cleanPreferences.title,
        category: cleanPreferences.topic.split(' ')[0],
        level: cleanPreferences.level,
        language: cleanPreferences.language,
        overview: `A course to help you learn about ${cleanPreferences.topic}.`,
        skills: [`Learn ${cleanPreferences.topic}`, `Apply ${cleanPreferences.topic} concepts`],
        status: "published",
        createdTime: serverTimestamp(),
        modifiedTime: serverTimestamp(),
        isGeneratedCourse: true
      };
      
      // Save minimalist course to Firestore
      const courseRef = await addDoc(collection(db, 'users', userId, 'GeneratedCourses'), basicCourseData);
      
      // Add a basic module
      const basicModule = {
        id: 'M101',
        moduleID: 'M101',
        module_title: `Introduction to ${cleanPreferences.topic}`,
        overview: `Learn the fundamentals of ${cleanPreferences.topic}`,
        order: 1,
        duration: '30 mins'
      };
      
      const moduleRef = doc(
        collection(db, 'users', userId, 'GeneratedCourses', courseRef.id, 'modules'),
        basicModule.id
      );
      
      await setDoc(moduleRef, basicModule);
      
      // Add a basic lesson to the module
      const basicLesson = {
        id: 'L101',
        lesson_title: `Getting Started with ${cleanPreferences.topic}`,
        lesson_slug: `getting-started-with-${cleanPreferences.topic.toLowerCase().replace(/\s+/g, '-')}`,
        content_type: 'reading',
        order: 1,
        description: `An introduction to ${cleanPreferences.topic}`,
        reading_material: {
          title: `Introduction to ${cleanPreferences.topic}`,
          content: `This is an introduction to ${cleanPreferences.topic}.`,
          estimatedReadingTime: 5
        },
        duration: '5 mins'
      };
      
      const lessonRef = doc(
        collection(db, 'users', userId, 'GeneratedCourses', courseRef.id, 'modules', basicModule.id, 'lessons'),
        basicLesson.id
      );
      
      await setDoc(lessonRef, basicLesson);
      
      // Return basic course data
      return {
        id: courseRef.id,
        ...basicCourseData,
        modules: [basicModule]
      };
    }
  }
}

const courseBuilder = new CourseBuilder();
export default courseBuilder;

import React, { useRef } from 'react';
import { Send, Loader2 } from 'lucide-react';

const ChatInput = ({ chatInput, setChatInput, handleChatSubmit, isLoading }) => {
  const inputRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleChatSubmit(e);
    }
  };

  return (
    <form onSubmit={handleChatSubmit} className="mt-4 border-t pt-4">
      <div className="relative mb-16">
        <textarea
          ref={inputRef}
          rows="3"
          className="w-full px-4 py-3 border-2 rounded-2xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none pr-28 bg-gray-50 hover:bg-white transition-colors duration-200 resize-none"
          placeholder="Ask any questions"
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          style={{ minHeight: '80px', maxHeight: '160px' }}
        />
        <button
          type="submit"
          className={`absolute bg-black right-2 bottom-2 px-4 py-2 text-white rounded-xl transition-colors duration-200 flex items-center space-x-2 ${
            chatInput.trim() ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'
          } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isLoading || !chatInput.trim()}
        >
          {isLoading ? (
            <div className="flex items-center space-x-2">
              <Loader2 className="w-5 h-5 animate-spin" />
              <span className="text-sm">Sending...</span>
            </div>
          ) : (
            <div className="flex items-center space-x-2">
              <Send className="w-5 h-5" />
              <span className="text-sm">Send</span>
            </div>
          )}
        </button>
      </div>
    </form>
  );
};

export default ChatInput;

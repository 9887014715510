
import React from 'react';


import "aos/dist/aos.css";
export default function Previous() {
    return (
        <React.Fragment>
            <>
          
            <section className="overflow-hidden">
      <div
        className="pt-14 pb-24"
        style={{
          background: "linear-gradient(131deg, #F8EADA 26.82%, #BCDDF8 96.89%)",
        }}
        data-aos="fade-down"
      >
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap max-w-7xl mx-auto -m-3">
            <div className="w-full md:w-1/2 p-3" data-aos="fade-right" data-aos-delay="200">
              <div className="bg-white rounded-4xl p-10 h-full lg:h-64">
                <div className="flex flex-wrap -m-3">
                  <div className="w-full lg:w-1/2 p-3">
                    <div className="flex flex-col justify-between gap-6 xl:gap-12">
                      <h6 className="font-heading text-4xl font-semibold max-w-xs tracking-tight">
                        Choose from a list of 200+ courses
                      </h6>
                      <a className="inline-flex items-center gap-3 mb-4 group mr-auto" href="/all-courses" target='_blank'>
                        <span className="text-neutral-600  font-semibold group-hover:text-neutral-800 tracking-tight transition duration-200" href="/all-courses" target='_blank'>
                          Learn more now
                        </span>
                        <div className="w-6 h-6 rounded-full border border-neutral-50 group-hover:border-neutral-200 transition duration-200 flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6 1L5.2825 1.6965L9.086 5.5H1V6.5H9.086L5.293 10.293L6 11L11 6L6 1Z"
                              fill="#19191B"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 p-3">
                    <img
                      className="mx-auto object-cover lg:mr-0 h-44"
                      src="mirga-assets/images/hero/circle2.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-3" data-aos="fade-left" data-aos-delay="400">
              <div className="bg-white rounded-4xl p-10 h-full lg:h-64">
                <div className="flex flex-wrap -m-3">
                  <div className="w-full lg:w-1/2 p-3">
                    <div className="flex flex-col justify-between gap-6 xl:gap-12">
                      <h6 className="font-heading text-4xl font-semibold max-w-xs tracking-tight">
                        Learning plan that works for you.
                      </h6>
                      {/*
                      <a className="inline-flex items-center gap-3 group mr-auto" href="/">
                        <span className="text-neutral-600 font-semibold group-hover:text-neutral-800 tracking-tight transition duration-200">
                          Learn more now
                        </span>
                        <div className="w-6 h-6 rounded-full border border-neutral-50 group-hover:border-neutral-200 transition duration-200 flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6 1L5.2825 1.6965L9.086 5.5H1V6.5H9.086L5.293 10.293L6 11L11 6L6 1Z"
                              fill="#19191B"
                            />
                          </svg>
                        </div>
                      </a>
                      */}
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 p-3">
                    <img
                      className="mx-auto object-cover lg:mr-0 h-44"
                      src="mirga-assets/images/hero/diagram.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

{/*

<section className="bg-neutral-50 py-16">
          <div className="container mx-auto px-4">
            <p className="text-xl font-medium mb-14 text-center">
              Supported By
            </p>
            <div className="flex flex-wrap items-center justify-center -m-8">
             
              <div className="w-auto p-8">
                <img
                  className="h-9 mix-blend-multiply"
                  src="https://upload.wikimedia.org/wikipedia/commons/5/51/Google_Cloud_logo.svg"
                  alt=""
                />
              </div>
             
              <div className="w-auto p-8">
                <img
                  className="h-9"
                  src="https://pub-f8c0307ce82b4885975558b04e13a858.r2.dev/2018/02/Microsoft-for-Startups.jpg"
                  alt=""
                />
              </div>
             
              <div className="w-auto p-8">
                <img
                  className="h-9"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU4KCyXfaLDmrFfWYcYGSCGpNx1nhaBBc_fQ&s"
                  alt=""
                />
              </div>
              
              
            </div>
          </div>
        </section>
       */}


            </>
        </React.Fragment>
    );
}


import React, { useState, useEffect, useMemo } from 'react';
import { FaUser, FaCog, FaPencilAlt, FaTasks, FaSave, FaCamera, FaPlus, FaTimes } from 'react-icons/fa';
import { doc, getDoc, updateDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { useUser } from '../../utils/UserContext';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const SettingsPage = () => {
  const { user } = useUser();
  const [activeTab, setActiveTab] = useState('account');
  const [isLoading, setIsLoading] = useState(true);
  const [accountDetails, setAccountDetails] = useState({
    avatar: '',
    fullName: '',
    dob: '',
    email: user?.email || '',
    phoneNumber: '',
    location: '',
    country: '',
    educationDetails: '',
    workDetails: '',
  });
  const [profileDescription, setProfileDescription] = useState('');
  const [personalization, setPersonalization] = useState({
    currentSkills: [],
    dreamRole: '',
    dreamCompany: '',
    skillsToGain: [],
  });
  const [preferences, setPreferences] = useState({
    learningStyle: '',
    studyHours: 0,
    notesLength: '',
    tone: '',
    depthLevel: '',
    learningLanguage: '',
  });
  const [isChanged, setIsChanged] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;
      
      setIsLoading(true);
      try {
        const userDocRef = doc(db, 'users', user.uid);
        
        // Fetch user profile
        const userProfileRef = doc(userDocRef, 'UserProfile', user.uid);
        const userProfileSnapshot = await getDoc(userProfileRef);
        
        // Fetch personal info
        const personalInfoRef = doc(userDocRef, 'PersonalInfo', user.uid);
        const personalInfoSnapshot = await getDoc(personalInfoRef);
        
        // Fetch preferences
        const preferencesRef = doc(userDocRef, 'Preferences', user.uid);
        const preferencesSnapshot = await getDoc(preferencesRef);
        
        // Fetch personalization
        const personalizationRef = doc(userDocRef, 'Personalization', user.uid);
        const personalizationSnapshot = await getDoc(personalizationRef);

        if (userProfileSnapshot.exists()) {
          const profileData = userProfileSnapshot.data();
          setAccountDetails(prev => ({
            ...prev,
            avatar: profileData.profilePhoto || '',
            fullName: `${profileData.firstName || ''} ${profileData.lastName || ''}`.trim(),
            email: profileData.emailAddress || user.email || '',
            phoneNumber: profileData.phoneNumber || '', // Get phone from UserProfile
          }));
          setProfileDescription(profileData.introduction || '');
        }

        if (personalInfoSnapshot.exists()) {
          const personalData = personalInfoSnapshot.data();
          setAccountDetails(prev => ({
            ...prev,
            dob: personalData.dob || '',
            location: personalData.state || '',
            country: personalData.country || '', // Get country from PersonalInfo
            educationDetails: personalData.education || '',
            workDetails: personalData.work || ''
          }));
        }

        if (preferencesSnapshot.exists()) {
          const prefsData = preferencesSnapshot.data();
          setPreferences({
            learningStyle: prefsData.learningStyle || '',
            studyHours: prefsData.studyHours || 0,
            notesLength: prefsData.notesLength || '',
            tone: prefsData.tone || '',
            depthLevel: prefsData.depthLevel || '',
            learningLanguage: prefsData.learningLanguage || '',
          });
        }

        if (personalizationSnapshot.exists()) {
          const personalizeData = personalizationSnapshot.data();
          setPersonalization({
            currentSkills: personalizeData.currentSkills || [],
            dreamRole: personalizeData.dreamRole || '',
            dreamCompany: personalizeData.dreamCompany || '',
            skillsToGain: personalizeData.skillsToGain || [],
          });
        }

      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchUserData();
    }
  }, [user]);

  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'static';
      document.body.style.width = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'static';
      document.body.style.width = 'auto';
    };
  }, [isPopupOpen]);

  const handleAccountSave = async () => {
    if (!user) return;
    
    try {
      const userDocRef = doc(db, 'users', user.uid);
      
      // Extract first and last name
      const nameParts = accountDetails.fullName.trim().split(' ');
      const firstName = nameParts[0] || '';
      const lastName = nameParts.slice(1).join(' ') || '';
      
      // Update profile data - Phone number goes in UserProfile
      const userProfileRef = doc(userDocRef, 'UserProfile', user.uid);
      await updateDoc(userProfileRef, {
        firstName,
        lastName,
        introduction: profileDescription,
        phoneNumber: accountDetails.phoneNumber, // Save phone with country code here
        updatedAt: serverTimestamp()
      });
      
      // Update personal info - Country goes in PersonalInfo
      const personalInfoRef = doc(userDocRef, 'PersonalInfo', user.uid);
      await updateDoc(personalInfoRef, {
        dob: accountDetails.dob,
        state: accountDetails.location,
        country: accountDetails.country, // Save country here
        education: accountDetails.educationDetails,
        work: accountDetails.workDetails,
        updatedAt: serverTimestamp()
      });
      
      setIsChanged(false);
      alert("Account information updated successfully");
    } catch (error) {
      console.error("Error updating account information:", error);
      alert("Failed to update account information");
    }
  };

  const handleProfileSave = async () => {
    if (!user) return;
    
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userProfileRef = doc(userDocRef, 'UserProfile', user.uid);
      
      await updateDoc(userProfileRef, {
        introduction: profileDescription,
        updatedAt: serverTimestamp()
      });
      
      alert("Profile updated successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile");
    }
  };
  
  const handlePersonalizationSave = async () => {
    if (!user) return;
    
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const personalizationRef = doc(userDocRef, 'Personalization', user.uid);
      
      // Check if personalization document exists
      const personalizationSnapshot = await getDoc(personalizationRef);
      
      const personalizationData = {
        currentSkills: personalization.currentSkills,
        dreamRole: personalization.dreamRole,
        dreamCompany: personalization.dreamCompany,
        skillsToGain: personalization.skillsToGain,
        updatedAt: serverTimestamp()
      };
      
      if (personalizationSnapshot.exists()) {
        await updateDoc(personalizationRef, personalizationData);
      } else {
        await setDoc(personalizationRef, personalizationData);
      }
      
      alert("Personalization preferences saved successfully");
    } catch (error) {
      console.error("Error updating personalization:", error);
      alert("Failed to update personalization preferences");
    }
  };
  
  const handlePreferencesSave = async () => {
    if (!user) return;
    
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const preferencesRef = doc(userDocRef, 'Preferences', user.uid);
      
      // Check if preferences document exists
      const preferencesSnapshot = await getDoc(preferencesRef);
      
      const preferencesData = {
        learningStyle: preferences.learningStyle,
        studyHours: parseInt(preferences.studyHours) || 0,
        notesLength: preferences.notesLength,
        tone: preferences.tone,
        depthLevel: preferences.depthLevel,
        learningLanguage: preferences.learningLanguage,
        updatedAt: serverTimestamp()
      };
      
      if (preferencesSnapshot.exists()) {
        await updateDoc(preferencesRef, preferencesData);
      } else {
        await setDoc(preferencesRef, preferencesData);
      }
      
      alert("Learning preferences saved successfully");
    } catch (error) {
      console.error("Error updating preferences:", error);
      alert("Failed to update learning preferences");
    }
  };

  const handleProfilePhotoUpload = async () => {
    if (!user) return;
    
    const fileInput = document.getElementById('profilePhotoInput');
    const file = fileInput.files[0];
    if (file) {
      try {
        const storageRef = ref(storage, `usersProfilePicture/${user.uid}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        
        setAccountDetails(prev => ({
          ...prev,
          avatar: downloadURL
        }));

        const userDocRef = doc(db, 'users', user.uid);
        const userProfileRef = doc(userDocRef, 'UserProfile', user.uid);
        await updateDoc(userProfileRef, {
          profilePhoto: downloadURL,
        });

        setIsPopupOpen(false);
        alert("Profile photo updated successfully");
      } catch (error) {
        console.error('Error uploading profile photo:', error);
        alert("Failed to upload profile photo");
      }
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <div className="flex justify-center items-center h-64">
        <div className="loader"></div>
        <p className="ml-2">Loading...</p>
      </div>;
    }

    switch (activeTab) {
      case 'account':
        return <AccountSection 
                 accountDetails={accountDetails} 
                 setAccountDetails={setAccountDetails}
                 handleSave={handleAccountSave}
                 setIsChanged={setIsChanged}
                 setIsPopupOpen={setIsPopupOpen}
                 isChanged={isChanged} />;
      case 'profile':
        return <ProfileSection 
                 profileDescription={profileDescription} 
                 setProfileDescription={setProfileDescription}
                 handleSave={handleProfileSave} />;
      case 'personalize':
        return <PersonalizeSection 
                 personalization={personalization} 
                 setPersonalization={setPersonalization}
                 handleSave={handlePersonalizationSave} />;
      case 'preferences':
        return <PreferencesSection 
                 preferences={preferences} 
                 setPreferences={setPreferences}
                 handleSave={handlePreferencesSave} />;
      default:
        return <AccountSection 
                 accountDetails={accountDetails} 
                 setAccountDetails={setAccountDetails}
                 handleSave={handleAccountSave}
                 setIsChanged={setIsChanged}
                 setIsPopupOpen={setIsPopupOpen}
                 isChanged={isChanged} />;
    }
  };

  // Popup for photo upload
  const renderPhotoUploadPopup = () => {
    if (!isPopupOpen) return null;
    
    return (
      <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 overflow-hidden z-50'>
        <div className='bg-white p-8 rounded-lg shadow-2xl relative border border-gray-300 max-w-md w-full mx-4 sm:mx-0'>
          <button
            type="button"
            className="absolute top-0 right-0 m-4 text-gray-600 hover:text-red-600 transition duration-300 mr-2 mt-2"
            onClick={() => setIsPopupOpen(false)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className='text-2xl font-semibold mb-6 text-center'>Change Profile Picture</h2>
          <input id="profilePhotoInput" type="file" accept="image/*" className="block w-full text-sm text-gray-900 border-gray-300 cursor-pointer bg-gray-50 focus:outline-none" />
          <div className='flex justify-end mt-6'>
            <button
              type="button"
              className="bg-blue-800 text-white px-4 py-2 rounded-full hover:bg-blue-900 transition duration-300"
              onClick={handleProfilePhotoUpload}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen">
      {/* Top Navigation */}
      <nav className="bg-white shadow-sm border-b border-gray-200 -mt-4">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between">
            <h1 className="text-2xl md:text-3xl font-bold text-gray-800 py-4">Settings</h1>
            <div className="w-full md:w-auto overflow-x-auto pb-1">
              <div className="flex h-14">
                {[
                  { id: 'account', label: 'Account', icon: <FaUser className="mr-1" /> },
                  { id: 'profile', label: 'Profile', icon: <FaPencilAlt className="mr-1" /> },
                  { id: 'personalize', label: 'Personalize', icon: <FaTasks className="mr-1" /> },
                  { id: 'preferences', label: 'Preferences', icon: <FaCog className="mr-1" /> }
                ].map(tab => (
                  <div key={tab.id} className="relative h-full flex items-center justify-center flex-1 min-w-[70px]">
                    <div className="justify-center px-1">
                      <button
                        className={`flex items-center min-w-0 font-medium gap-1 px-1 sm:px-3 py-2 text-xs sm:text-sm h-8 transition-colors duration-300 ${
                          activeTab === tab.id 
                            ? 'text-gray-800' 
                            : 'text-gray-500 hover:text-gray-800'
                        }`}
                        onClick={() => setActiveTab(tab.id)}
                      >
                        {tab.icon}
                        <span className="truncate leading-tight">{tab.label}</span>
                      </button>
                      {activeTab === tab.id && (
                        <div className="absolute bottom-0 left-0 right-0 h-[3px] rounded-t-sm bg-blue-600" />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Content Section */}
      <div className="container mx-auto p-4 sm:p-6 bg-white my-5 sm:my-6 shadow-sm rounded-lg">
        {renderContent()}
      </div>

      {/* Photo Upload Popup */}
      {renderPhotoUploadPopup()}
    </div>
  );
};

const AccountSection = ({ accountDetails, setAccountDetails, handleSave, setIsChanged, setIsPopupOpen, isChanged }) => {
  // Country codes for phone numbers - wrapped in useMemo to prevent recreation on each render
  const countryCodes = useMemo(() => [
    { code: '+1', country: 'US/Canada' },
    { code: '+44', country: 'UK' },
    { code: '+91', country: 'India' },
    { code: '+61', country: 'Australia' },
    { code: '+49', country: 'Germany' },
    { code: '+86', country: 'China' },
    { code: '+33', country: 'France' },
    { code: '+81', country: 'Japan' },
    { code: '+7', country: 'Russia' },
    { code: '+55', country: 'Brazil' },
  ], []); // Empty dependency array means this only gets created once

  // Countries list - also wrapped in useMemo
  const countries = useMemo(() => [
    'United States', 'Canada', 'United Kingdom', 'Australia', 'India', 
    'Germany', 'France', 'Japan', 'China', 'Brazil', 'South Africa', 
    'Mexico', 'Italy', 'Spain', 'Russia', 'Singapore', 'New Zealand'
  ], []);

  // Format date for display
  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB'); // DD/MM/YYYY format
  };

  // Parse phone number to extract country code
  const [selectedCountryCode, setSelectedCountryCode] = useState('+1');
  const [phoneWithoutCode, setPhoneWithoutCode] = useState('');

  useEffect(() => {
    // Parse existing phone number when component mounts
    if (accountDetails.phoneNumber) {
      // Try to extract country code
      const foundCode = countryCodes.find(c => accountDetails.phoneNumber.startsWith(c.code));
      if (foundCode) {
        setSelectedCountryCode(foundCode.code);
        setPhoneWithoutCode(accountDetails.phoneNumber.substring(foundCode.code.length));
      } else {
        setPhoneWithoutCode(accountDetails.phoneNumber);
      }
    }
  }, [accountDetails.phoneNumber, countryCodes]); // Add countryCodes to dependency array

  // Handle phone number change
  const handlePhoneChange = (newPhoneWithoutCode) => {
    setPhoneWithoutCode(newPhoneWithoutCode);
    const fullPhone = `${selectedCountryCode}${newPhoneWithoutCode}`;
    setAccountDetails({ ...accountDetails, phoneNumber: fullPhone });
    setIsChanged(true);
  };

  // Handle country code change
  const handleCountryCodeChange = (newCode) => {
    setSelectedCountryCode(newCode);
    const fullPhone = `${newCode}${phoneWithoutCode}`;
    setAccountDetails({ ...accountDetails, phoneNumber: fullPhone });
    setIsChanged(true);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-blue-800">Account Information</h2>
      <p className="mb-6 text-gray-600">Manage your personal details and preferences</p>
      
      {/* Profile photo section - unchanged */}
      <div className="mb-8 gap-4 flex flex-col sm:flex-row items-center">
        <div className="relative mb-4 sm:mb-0">
          <img 
            src={accountDetails.avatar || "https://via.placeholder.com/150"} 
            alt="Profile" 
            className="w-24 h-24 rounded-full object-cover border-2 border-blue-800"
          />
          <label className="absolute bottom-0 right-0 bg-blue-800 text-white p-2 rounded-full cursor-pointer shadow-md hover:bg-blue-700 transition">
            <FaCamera />
            <button type="button" className="hidden" onClick={() => setIsPopupOpen(true)} />
          </label>
        </div>
        <div className="sm:ml-6 text-center sm:text-left">
          <h3 className="text-lg font-semibold">Profile Photo</h3>
          <p className="text-sm text-gray-600">Upload a new profile picture</p>
          <button 
            className="mt-2 text-sm text-blue-600 hover:text-blue-800"
            onClick={() => setIsPopupOpen(true)}>
            Change Photo
          </button>
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
        {/* Full Name - unchanged */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">Full Name</label>
          <input 
            type="text" 
            value={accountDetails.fullName} 
            onChange={(e) => {
              setAccountDetails({ ...accountDetails, fullName: e.target.value });
              setIsChanged(true);
            }} 
            className="w-full p-2 sm:p-3 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
            placeholder="Enter your full name"
          />
        </div>
        
        {/* Date of Birth - Updated format */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">Date of Birth</label>
          <input 
            type="date" 
            value={accountDetails.dob} 
            onChange={(e) => {
              setAccountDetails({ ...accountDetails, dob: e.target.value });
              setIsChanged(true);
            }} 
            className="w-full p-2 sm:p-3 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
          />
          {accountDetails.dob && (
            <p className="text-xs text-gray-500 mt-1">
              Display Format: {formatDateForDisplay(accountDetails.dob)}
            </p>
          )}
        </div>
        
        {/* Email - unchanged */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">Email Address</label>
          <input 
            type="email" 
            value={accountDetails.email} 
            disabled
            className="w-full p-2 sm:p-3 rounded border border-gray-300 bg-gray-200 text-gray-500 text-sm sm:text-base"
          />
          <p className="text-xs text-gray-500 mt-1">Email cannot be changed</p>
        </div>
        
        {/* Phone Number - Updated with country code */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">Phone Number</label>
          <div className="flex">
            <select
              value={selectedCountryCode}
              onChange={(e) => handleCountryCodeChange(e.target.value)}
              className="w-24 p-2 sm:p-3 mr-2 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
            >
              {countryCodes.map(country => (
                <option key={country.code} value={country.code}>
                  {country.code} {country.country}
                </option>
              ))}
            </select>
            <input 
              type="tel" 
              value={phoneWithoutCode} 
              onChange={(e) => handlePhoneChange(e.target.value)} 
              className="flex-1 p-2 sm:p-3 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
              placeholder="Enter your phone number"
            />
          </div>
        </div>
        
        {/* Location - Changed to City */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">City/State</label>
          <input 
            type="text" 
            value={accountDetails.location} 
            onChange={(e) => {
              setAccountDetails({ ...accountDetails, location: e.target.value });
              setIsChanged(true);
            }} 
            className="w-full p-2 sm:p-3 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
            placeholder="Your city or state"
          />
        </div>
        
        {/* Country - Added */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">Country</label>
          <select 
            value={accountDetails.country} 
            onChange={(e) => {
              setAccountDetails({ ...accountDetails, country: e.target.value });
              setIsChanged(true);
            }}
            className="w-full p-2 sm:p-3 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
          >
            <option value="">Select your country</option>
            {countries.map(country => (
              <option key={country} value={country}>{country}</option>
            ))}
          </select>
        </div>
        
        {/* Education - unchanged */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">Education</label>
          <input 
            type="text" 
            value={accountDetails.educationDetails} 
            onChange={(e) => {
              setAccountDetails({ ...accountDetails, educationDetails: e.target.value });
              setIsChanged(true);
            }} 
            className="w-full p-2 sm:p-3 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
            placeholder="Your highest education"
          />
        </div>
        
        {/* Work - unchanged */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">Work</label>
          <input 
            type="text" 
            value={accountDetails.workDetails} 
            onChange={(e) => {
              setAccountDetails({ ...accountDetails, workDetails: e.target.value });
              setIsChanged(true);
            }} 
            className="w-full p-2 sm:p-3 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
            placeholder="Current role & company"
          />
        </div>
      </div>
      
      {/* Save button - unchanged */}
      <div className="mt-6 sm:mt-8 flex justify-end">
        <button 
          className={`w-full sm:w-auto bg-blue-800 text-white px-4 sm:px-6 py-2 rounded-lg flex items-center justify-center transition ${isChanged ? 'hover:bg-blue-700' : 'opacity-50 cursor-not-allowed'}`}
          onClick={handleSave}
          disabled={!isChanged}
        >
          <FaSave className="mr-2" /> Save Changes
        </button>
      </div>
    </div>
  );
};

const ProfileSection = ({ profileDescription, setProfileDescription, handleSave }) => (
  <div>
    <h2 className="text-2xl font-bold mb-4 text-blue-800">Your Profile</h2>
    <p className="mb-6 text-gray-600 text-sm sm:text-base">
      Introduce yourself for personalized answers. Share any information that will help customize your learning experience.
    </p>
    
    <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200">
      <label className="block text-gray-700 font-medium mb-2">Tell us about yourself</label>
      <textarea
        className="w-full p-3 sm:p-4 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none min-h-[150px] sm:min-h-[200px] transition text-sm sm:text-base"
        placeholder="Example: I am a software engineer with 5 years of experience in web development..."
        value={profileDescription}
        onChange={(e) => setProfileDescription(e.target.value)}
      ></textarea>
    </div>
    
    <div className="mt-6 sm:mt-8 flex justify-end">
      <button 
        className="w-full sm:w-auto bg-blue-800 text-white px-4 sm:px-6 py-2 rounded-lg flex items-center justify-center hover:bg-blue-700 transition"
        onClick={handleSave}
      >
        <FaSave className="mr-2" /> Save Profile
      </button>
    </div>
  </div>
);

const PersonalizeSection = ({ personalization, setPersonalization, handleSave }) => {
  // Custom skill input
  const [newSkill, setNewSkill] = useState('');
  const [newSkillToGain, setNewSkillToGain] = useState('');
  
  const addCustomSkill = () => {
    if (newSkill.trim() && !personalization.currentSkills.includes(newSkill.trim())) {
      setPersonalization({
        ...personalization,
        currentSkills: [...personalization.currentSkills, newSkill.trim()]
      });
      setNewSkill('');
    }
  };
  
  const removeSkill = (skill) => {
    setPersonalization({
      ...personalization,
      currentSkills: personalization.currentSkills.filter(s => s !== skill)
    });
  };
  
  const addCustomSkillToGain = () => {
    if (newSkillToGain.trim() && !personalization.skillsToGain.includes(newSkillToGain.trim())) {
      setPersonalization({
        ...personalization,
        skillsToGain: [...personalization.skillsToGain, newSkillToGain.trim()]
      });
      setNewSkillToGain('');
    }
  };
  
  const removeSkillToGain = (skill) => {
    setPersonalization({
      ...personalization,
      skillsToGain: personalization.skillsToGain.filter(s => s !== skill)
    });
  };
  
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-blue-800">Personalize Your Experience</h2>
      <p className="mb-6 text-gray-600 text-sm sm:text-base">Tell us more about your skills and aspirations.</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
        {/* Current Skills - Modified for custom input */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">Current Skills</label>
          
          <div className="flex mb-3">
            <input 
              type="text" 
              value={newSkill} 
              onChange={(e) => setNewSkill(e.target.value)} 
              className="flex-1 p-2 sm:p-3 rounded-l border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
              placeholder="Add a skill..."
              onKeyPress={(e) => e.key === 'Enter' && addCustomSkill()}
            />
            <button 
              onClick={addCustomSkill}
              className="bg-blue-800 text-white p-2 sm:p-3 rounded-r hover:bg-blue-700 transition"
            >
              <FaPlus />
            </button>
          </div>
          
          <div className="flex flex-wrap gap-2 mt-2">
            {personalization.currentSkills.map(skill => (
              <div 
                key={skill}
                className="px-2 sm:px-3 py-1 mb-2 rounded-full bg-blue-800 text-white flex items-center"
              >
                <span className="text-xs sm:text-sm">{skill}</span>
                <button 
                  onClick={() => removeSkill(skill)}
                  className="ml-2 text-white hover:text-red-200 transition"
                >
                  <FaTimes size={12} />
                </button>
              </div>
            ))}
          </div>
        </div>
        
        {/* Dream Role - unchanged */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">Dream Role</label>
          <input 
            type="text" 
            value={personalization.dreamRole} 
            onChange={(e) => setPersonalization({ ...personalization, dreamRole: e.target.value })} 
            className="w-full p-2 sm:p-3 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
            placeholder="e.g. Senior Developer, Tech Lead"
          />
        </div>
        
        {/* Dream Company - unchanged */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">Dream Company</label>
          <input 
            type="text" 
            value={personalization.dreamCompany} 
            onChange={(e) => setPersonalization({ ...personalization, dreamCompany: e.target.value })} 
            className="w-full p-2 sm:p-3 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
            placeholder="e.g. Google, Microsoft, Own startup"
          />
        </div>
        
        {/* Skills to Gain - Modified for custom input */}
        <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <label className="block text-gray-700 font-medium mb-2">Skills to Gain</label>
          
          <div className="flex mb-3">
            <input 
              type="text" 
              value={newSkillToGain} 
              onChange={(e) => setNewSkillToGain(e.target.value)} 
              className="flex-1 p-2 sm:p-3 rounded-l border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-sm sm:text-base"
              placeholder="Add a skill to learn..."
              onKeyPress={(e) => e.key === 'Enter' && addCustomSkillToGain()}
            />
            <button 
              onClick={addCustomSkillToGain}
              className="bg-blue-800 text-white p-2 sm:p-3 rounded-r hover:bg-blue-700 transition"
            >
              <FaPlus />
            </button>
          </div>
          
          <div className="flex flex-wrap gap-2 mt-2">
            {personalization.skillsToGain.map(skill => (
              <div 
                key={skill}
                className="px-2 sm:px-3 py-1 mb-2 rounded-full bg-blue-800 text-white flex items-center"
              >
                <span className="text-xs sm:text-sm">{skill}</span>
                <button 
                  onClick={() => removeSkillToGain(skill)}
                  className="ml-2 text-white hover:text-red-200 transition"
                >
                  <FaTimes size={12} />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Save button - unchanged */}
      <div className="mt-6 sm:mt-8 flex justify-end">
        <button 
          className="w-full sm:w-auto bg-blue-800 text-white px-4 sm:px-6 py-2 rounded-lg flex items-center justify-center hover:bg-blue-700 transition"
          onClick={handleSave}
        >
          <FaSave className="mr-2" /> Save Preferences
        </button>
      </div>
    </div>
  );
};

const PreferencesSection = ({ preferences, setPreferences, handleSave }) => (
  <div>
    <h2 className="text-2xl font-bold mb-4 text-blue-800">Learning Preferences</h2>
    <p className="mb-6 text-gray-600 text-sm sm:text-base">Customize how you want to receive course materials.</p>
    
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
      <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
        <label className="block text-gray-700 font-medium mb-2">Learning Style</label>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
          {['Visual', 'Practical', 'Theoretical'].map(style => (
            <div 
              key={style}
              onClick={() => setPreferences({ ...preferences, learningStyle: style })}
              className={`px-3 py-2 rounded-lg cursor-pointer transition text-center mb-2 sm:mb-0 ${
                preferences.learningStyle === style
                  ? 'bg-blue-800 text-white' 
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              } text-sm sm:text-base sm:flex-1`}
            >
              {style}
            </div>
          ))}
        </div>
      </div>
      
      <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
        <label className="block text-gray-700 font-medium mb-2">Study Hours Per Week</label>
        <div className="flex items-center">
          <input 
            type="range" 
            min="1" 
            max="40" 
            value={preferences.studyHours} 
            onChange={(e) => setPreferences({ ...preferences, studyHours: parseInt(e.target.value) })} 
            className="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer"
          />
          <span className="ml-4 bg-blue-800 text-white rounded-full w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center text-sm sm:text-base">
            {preferences.studyHours}
          </span>
        </div>
      </div>
      
      <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
        <label className="block text-gray-700 font-medium mb-2">Notes Length</label>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
          {['Short', 'Medium', 'Detailed'].map(length => (
            <div 
              key={length}
              onClick={() => setPreferences({ ...preferences, notesLength: length })}
              className={`px-3 py-2 rounded-lg cursor-pointer transition text-center mb-2 sm:mb-0 ${
                preferences.notesLength === length
                  ? 'bg-blue-800 text-white' 
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              } text-sm sm:text-base sm:flex-1`}
            >
              {length}
            </div>
          ))}
        </div>
      </div>
      
      <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
        <label className="block text-gray-700 font-medium mb-2">Tone</label>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
          {['Formal', 'Conversational'].map(tone => (
            <div 
              key={tone}
              onClick={() => setPreferences({ ...preferences, tone: tone })}
              className={`px-3 py-2 rounded-lg cursor-pointer transition text-center mb-2 sm:mb-0 ${
                preferences.tone === tone
                  ? 'bg-blue-800 text-white' 
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              } text-sm sm:text-base sm:flex-1`}
            >
              {tone}
            </div>
          ))}
        </div>
      </div>
      
      <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
        <label className="block text-gray-700 font-medium mb-2">Depth Level</label>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
          {['Beginner', 'Intermediate', 'Advanced'].map(level => (
            <div 
              key={level}
              onClick={() => setPreferences({ ...preferences, depthLevel: level })}
              className={`px-3 py-2 rounded-lg cursor-pointer transition text-center mb-2 sm:mb-0 ${
                preferences.depthLevel === level
                  ? 'bg-blue-800 text-white' 
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              } text-sm sm:text-base sm:flex-1`}
            >
              {level}
            </div>
          ))}
        </div>
      </div>
      
      <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
        <label className="block text-gray-700 font-medium mb-2">Learning Language</label>
        <select 
          value={preferences.learningLanguage} 
          onChange={(e) => setPreferences({ ...preferences, learningLanguage: e.target.value })}
          className="w-full p-2 sm:p-3 rounded border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none bg-white text-sm sm:text-base"
        >
          <option value="">Select a language...</option>
          <option value="English">English</option>
          <option value="Spanish">Spanish</option>
          <option value="French">French</option>
          <option value="German">German</option>
          <option value="Mandarin">Mandarin</option>
          <option value="Hindi">Hindi</option>
        </select>
      </div>
    </div>
    
    <div className="mt-6 sm:mt-8 flex justify-end">
      <button 
        className="w-full sm:w-auto bg-blue-800 text-white px-4 sm:px-6 py-2 rounded-lg flex items-center justify-center hover:bg-blue-700 transition"
        onClick={handleSave}
      >
        <FaSave className="mr-2" /> Save Preferences
      </button>
    </div>
  </div>
);

export default SettingsPage;

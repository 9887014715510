import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useUser } from '../../utils/UserContext';

const AICourseDashboard = () => {
  const { user } = useUser();
  const [aiCourses, setAiCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalCourses: 0,
    completedCourses: 0,
    inProgressCourses: 0,
    totalLessons: 0,
    completedLessons: 0
  });

  useEffect(() => {
    const fetchAICourses = async () => {
      if (!user) return;

      try {
        setLoading(true);
        
        // Query for courses created by this user that are marked as personalized/AI-generated
        const coursesQuery = query(
          collection(db, 'courses'),
          where('createdBy', '==', user.uid),
          where('isPersonalized', '==', true)
        );
        
        const coursesSnapshot = await getDocs(coursesQuery);
        const coursesData = [];
        
        // Fetch enrollment status for each course
        for (const courseDoc of coursesSnapshot.docs) {
          const courseData = { id: courseDoc.id, ...courseDoc.data() };
          
          // Check if the user is enrolled in this course
          const enrollmentRef = doc(db, 'enrollments', `${user.uid}_${courseDoc.id}`);
          const enrollmentDoc = await getDoc(enrollmentRef);
          
          if (enrollmentDoc.exists()) {
            courseData.progress = enrollmentDoc.data().progress || 0;
            courseData.lastAccessed = enrollmentDoc.data().lastAccessed;
            courseData.status = enrollmentDoc.data().status;
          } else {
            courseData.progress = 0;
            courseData.status = 'not_started';
          }
          
          // Fetch module count
          const modulesRef = collection(db, 'courses', courseDoc.id, 'modules');
          const modulesSnapshot = await getDocs(modulesRef);
          courseData.moduleCount = modulesSnapshot.docs.length;
          
          // Count lessons
          let lessonCount = 0;
          for (const moduleDoc of modulesSnapshot.docs) {
            const lessonsRef = collection(db, 'courses', courseDoc.id, 'modules', moduleDoc.id, 'lessons');
            const lessonsSnapshot = await getDocs(lessonsRef);
            lessonCount += lessonsSnapshot.docs.length;
          }
          courseData.lessonCount = lessonCount;
          
          coursesData.push(courseData);
        }
        
        setAiCourses(coursesData);
        
        // Calculate stats
        const totalCourses = coursesData.length;
        const completedCourses = coursesData.filter(course => course.progress === 100).length;
        const inProgressCourses = coursesData.filter(course => course.progress > 0 && course.progress < 100).length;
        const totalLessons = coursesData.reduce((sum, course) => sum + (course.lessonCount || 0), 0);
        const completedLessons = coursesData.reduce((sum, course) => {
          const lessonsInCourse = course.lessonCount || 0;
          return sum + (lessonsInCourse * (course.progress / 100));
        }, 0);
        
        setStats({
          totalCourses,
          completedCourses,
          inProgressCourses,
          totalLessons,
          completedLessons: Math.round(completedLessons)
        });
        
      } catch (error) {
        console.error('Error fetching AI courses:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAICourses();
  }, [user]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-800"></div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">AI Course Dashboard</h1>
            <p className="text-gray-600 mt-1">
              Manage and track your AI-generated personalized courses
            </p>
          </div>
          <Link
            to="/build-ai-course"
            className="mt-4 md:mt-0 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
            Build New AI Course
          </Link>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Total AI Courses
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {stats.totalCourses}
                </dd>
              </dl>
            </div>
          </div>
          
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Completed Courses
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-green-600">
                  {stats.completedCourses}
                </dd>
              </dl>
            </div>
          </div>
          
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">
                  In Progress
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-blue-600">
                  {stats.inProgressCourses}
                </dd>
              </dl>
            </div>
          </div>
          
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Lessons Completed
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {stats.completedLessons} / {stats.totalLessons}
                </dd>
              </dl>
            </div>
          </div>
        </div>

        {/* Courses List */}
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {aiCourses.length > 0 ? (
              aiCourses.map((course) => (
                <li key={course.id}>
                  <div className="px-4 py-5 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-12 w-12 rounded-md bg-gradient-to-r from-blue-500 to-indigo-600 flex items-center justify-center text-white font-bold">
                          {course.course_title.substring(0, 2).toUpperCase()}
                        </div>
                        <div className="ml-4">
                          <h3 className="text-lg font-medium text-gray-900">
                            {course.course_title}
                          </h3>
                          <div className="flex items-center text-sm text-gray-500 mt-1">
                            <span>{course.level}</span>
                            <span className="mx-2">•</span>
                            <span>{course.moduleCount} modules</span>
                            <span className="mx-2">•</span>
                            <span>{course.lessonCount} lessons</span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-end">
                        <div className="flex items-center mb-2">
                          <span className="text-sm font-medium text-gray-900 mr-2">
                            {course.progress}%
                          </span>
                          <div className="w-24 bg-gray-200 rounded-full h-2.5">
                            <div 
                              className={`h-2.5 rounded-full ${
                                course.progress === 100 
                                  ? 'bg-green-600' 
                                  : 'bg-blue-600'
                              }`}
                              style={{ width: `${course.progress}%` }}
                            ></div>
                          </div>
                        </div>
                        
                        <div className="flex space-x-2">
                          <Link
                            to={`/courses/${course.course_title.replace(/\s+/g, '-')}`}
                            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            View
                          </Link>
                          
                          <Link
                            to={`/courses/${course.course_title.replace(/\s+/g, '-')}`}
                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            {course.progress > 0 ? 'Continue' : 'Start'}
                          </Link>
                        </div>
                      </div>
                    </div>
                    
                    {course.lastAccessed && (
                      <div className="mt-2 text-xs text-gray-500">
                        Last accessed: {new Date(course.lastAccessed).toLocaleString()}
                      </div>
                    )}
                  </div>
                </li>
              ))
            ) : (
              <div className="px-4 py-10 sm:px-6 text-center">
                <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-gray-900">No AI courses yet</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by creating a new AI-generated course.
                </p>
                <div className="mt-6">
                  <Link
                    to="/build-ai-course"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                    Build New AI Course
                  </Link>
                </div>
              </div>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AICourseDashboard;

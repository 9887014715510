import React from "react";

export function Card({ children, className }) {
  return (
    <div className={`p-6 bg-white shadow-lg rounded-2xl ${className}`}>
      {children}
    </div>
  );
}

export function CardContent({ children, className }) {
  return <div className={`flex flex-col items-center text-center ${className}`}>{children}</div>;
}

const components = { Card, CardContent };

export default components;

export const sampleJobs = [
  {
    id: 1,
    title: "Junior Front-End Developer",
    company: "Inncelerator",
    logo: "https://media.licdn.com/dms/image/v2/C560BAQEjRRbSkuAIhw/company-logo_200_200/company-logo_200_200/0/1630669885456?e=2147483647&v=beta&t=yTsu6_DplWlkBjE50hu44B45_udgV50MJlHk2ZR1kBw",
    description: "As a Junior Developer, assist in building and maintaining web applications using SvelteJS and Next.js, collaborate with senior developers, and enhance your skills in modern web development.",
    category: "Front-End Developer",
    date: "01/25/2025",
    location: "Delhi-Remote",
    requirements: ["2 years of experience with SvelteJS & Next.js", "Proficiency in JavaScript, HTML, CSS, and responsive UI design", "Familiarity with APIs, performance optimization, and Git"],
    type: "Job",
    responsibilities: ["Web Application Development", "Feature Collaboration", "Code Maintenance", "Quality Assurance", "Debugging Issue Troubleshooting"],
    eligibility: ["minimum 2 year experience in SvelteJS & Next.js"],
    skills: ["React", "Next.js"],
    averageSalary: "₹6,00,000 - ₹8,00,000"
  },
  {
    id: 2,
    title: "Junior Web Developer",
    company: "Bit Binders",
    logo: "https://media.licdn.com/dms/image/v2/D560BAQG4mSx5wBnqsg/company-logo_200_200/company-logo_200_200/0/1735897184685/bit_binders_it_solution_logo?e=2147483647&v=beta&t=aOAbfriFZVKSaLysMvmnViACvt7mbrD42gCnO_eK3X8",
    description: "We are looking for a passionate and skilled Junior Web Developer to join our growing team at Bit Binders. If you are enthusiastic about web technologies, eager to learn, and ready to work on exciting projects, we’d love to hear from you!",
    category: "Web Development",
    date: "01/18/2025",
    location: "Hauz Khas, Delhi",
    requirements: ["Bachelor’s degree in Computer Science, Web Development, or a related field", "Basic understanding of web languages (HTML5, CSS3, JavaScript) and frameworks (Bootstrap, React, or similar)", "Familiarity with content management systems", "Strong problem-solving skills and attention to detail", "Ability to work in a team environment and communicate effectively"],
    type: "Job",
    responsibilities: ["Develop, test, and maintain responsive websites using HTML, CSS, JavaScript, and modern frameworks", "Collaborate with designers and senior developers to implement website features and enhancements", "Assist in website debugging, troubleshooting, and performance optimization", "Ensure websites are mobile-friendly, user-centric, and aligned with best SEO practices", "Stay updated on emerging web development trends and technologies"],
    eligibility: ["Bachelor's (Preferred)"],
    skills: ["HTML", "CSS", "JavaScript"],
    averageSalary: "₹4,00,000 - ₹6,00,000"
  },
  {
    id: 3,
    title: "Data Analyst",
    company: "SKILLSKONNECT",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa2kjrXSWG0j7e8w9ijakXE5PVR5y7vlxyEw&s",
    description: "UnlistedSharesIndia seeks a skilled Data Analyst to specialize in data exploration, transaction analysis, and insights generation within the unlisted shares ecosystem.",
    category: "Data Analyst",
    date: "01/30/2025",
    location: "Janakpuri, Delhi",
    requirements: ["Experience: 1 year in financial data analysis or a related field", "Education: Bachelor’s degree in Finance, Economics, Statistics, or a related field", "Skills: Proficiency in Excel, SQL, and data visualization tools (Tableau, Power BI, etc.)", "Strong analytical and problem-solving skills", "Ability to work with large datasets and derive actionable insights"],
    type: "Job",
    responsibilities: ["Data Discovery and Extraction", "Source and verify critical data sets related to unlisted shares", "Develop data retrieval mechanisms ensuring accuracy and reliability", "Data Analysis and Insights", "Analyze data to identify trends, patterns, and insights", "Generate reports and visualizations to communicate findings", "Data Management and Quality Assurance", "Maintain data integrity and quality", "Ensure data compliance with regulatory standards", "Data-driven Decision Making", "Provide data-driven insights to support business decisions", "Collaborate with cross-functional teams to drive data-driven strategies"],
    eligibility: ["Bachelor's (Required)", "Data analytics: 1 year (Required)"],
    skills: ["Python", "SQL", "Tableau"],
    averageSalary: "₹5,00,000 - ₹7,00,000"
  },
  {
    id: 4,
    title: "Research Analyst",
    company: "Incisive Research",
    logo: "https://media.licdn.com/dms/image/v2/C4D0BAQE01cEjrHhO9Q/company-logo_200_200/company-logo_200_200/0/1631331640038?e=2147483647&v=beta&t=3L1lLJPqBe01m5XleDdOyCHP3sP_baYNkisWO6tR-XA",
    description: "The role involves making outbound calls into different geographies in order to collect data through Primary Sources. Responsible to generate leads using various search engines. Willing to work in flexible shifts",
    category: "Research Analyst",
    date: "01/13/2025",
    location: "Delhi",
    requirements: ["Fresher's to 2 years experienced in a market research firm", "Proficient in MS Office", "Strong communication skills", "Ability to work in a team", "Willing to work in flexible shifts"],
    type: "Job",
    responsibilities: ["Research and collect data through primary sources", "Generate leads using various search engines", "Conduct outbound calls to collect data", "Maintain data accuracy and quality", "Work in a team environment", "Meet daily targets and deadlines"],
    eligibility: ["UG - Any Graduate / PG - Any Postgraduate with a minimum 50% overall percentage (Final Year students can also apply)"],
    skills: ["Basics of MS Office"],
    averageSalary: "₹3,00,000 - ₹5,00,000"
  },
  {
    id: 5,
    title: "Threat Intelligence Researche",
    company: "cloudsek",
    logo: "https://www.gartner.com/pi/vendorimages/cloudsek_1616565006131.png",
    description: "CloudSEK, one of India’s most trusted Cyber security product companies, is on a mission to build the world’s fastest and most reliable AI technology that identifies and resolves digital threats in real-time. The central proposition is leveraging Artificial Intelligence and Machine Learning to create a quick and reliable analysis and alert system that provides rapid detection across multiple internet sources, precise threat analysis, and prompt resolution with minimal human intervention.",
    category: "Cyber Security",
    date: "01/29/2025",
    location: "Delhi-NCR",
    requirements: ["Experience: 2-5 years of experience in the relevant field, particularly in threat intelligence and cybersecurity", "Technical Skills: Strong understanding of web application testing and application testing", "Soft Skills: Excellent communication skills, both written and verbal, with a proven ability to write clear, concise, and impactful reports", "Adaptability: Ability to work in a fast-paced environment and manage multiple tasks simultaneously"],
    type: "Job",
    responsibilities: ["Threat Intelligence Research: Spearhead research initiatives focused on the Indian subcontinent, identifying and analyzing emerging threats, vulnerabilities, and threat actors", "Web Application Testing: Conduct thorough testing of web applications to identify vulnerabilities and provide actionable insights", "Customer Engagement: Work closely with premier organizations in the country, providing expert guidance and support in threat intelligence", "Report Writing: Prepare detailed reports on findings and recommendations with a high degree of accuracy and clarity", "On-site Visits: Based in Delhi, you will work primarily from our office but may be required to visit customer offices within the city as needed"],
    eligibility: ["2-5 years of experience in the relevant field, particularly in threat intelligence and cybersecurity"],
    skills: ["Cyber Security", "Threat Intelligence"],
    averageSalary: "₹8,00,000 - ₹12,00,000"
  },
  {
    id: 6,
    title: "T&T | Cyber:D&R | AM | SPLUNK | Delhi |",
    company: "Deloitte",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0uAHOowCHXR4MvE9bcyKGFRsDRCvukR35Hg&s",
    description: "Demonstrates proven expertise as in administering Splunk Enterprise Security (SIEM)",
    category: "Cyber Security",
    date: "01/15/2025",
    location: "Delhi",
    requirements: ["Cyber Security certification and knowledge including SOC services", "General networking and system troubleshooting skills", "Proficiency in client and server operating systems including Linux and Windows", "Good knowledge of information security and IT operations domain", "Hands-on experience in creating dashboard and reports using SPL queries and XML"],
    type: "Job",
    responsibilities: ["Comfortable in writing regular expression to extract fields from custom log sources", "Expertise in developing custom use cases using Splunk search language to correlate"],
    eligibility: ["Advanced knowledge of Splunk"],
    skills: ["Cyber Security", "Splunk"],
    averageSalary: "₹10,00,000 - ₹15,00,000"
  },
  {
    id: 7,
    title: "Content & E-Commerce Management Work From Home Internship",
    company: "SPACE For Early Childhood Education",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOFHeIkwXO9KlZgU8K1cXLwNIuWMtsvpU_eA&s",
    description: "Are you passionate about early childhood education and digital content creation? We are looking for a Content & E-Commerce Management intern to join our team at SPACE For Early Childhood Education. As an intern, you will have the opportunity to work on exciting projects, gain hands-on experience, and contribute to the growth of our e-commerce platform.",
    category: "Digital Marketing",
    date: "01/20/2025",
    location: "Remote",
    requirements: ["MS-Excel", "MS-Word"],
    type: "Internship",
    responsibilities: ["Create engaging content for our website and social media platforms", "Assist in managing our online store and updating product listings", "Conduct market research and analyze data to optimize e-commerce performance", "Collaborate with the marketing team to develop digital marketing strategies", "Maintain and organize digital files and documents using MS-Excel and MS-Word"],
    eligibility: ["Available for duration of 3 months"],
    skills: ["English Proficiency (Spoken)", "English Proficiency (Written)", "MS-Excel", "MS-Word"],
    averageSalary: "₹2,00,000 - ₹3,00,000"
  },
  {
    id: 8,
    title: "Content & E-Commerce Management",
    company: "Oytra",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScjkeYMq5u9UguwKwJBfQ-TQYODGNNbi1qFA&s",
    description: "As a 'Content & E-Commerce Management' intern at Oytra, you will have the exciting opportunity to work on creating engaging content and managing our e-commerce platform. We are looking for someone with a strong proficiency in written English and experience using Canva to join our team.",
    category: "Content Writing",
    date: "01/10/2025",
    location: "Remote",
    requirements: ["Canva", "English Proficiency (Spoken)", "English Proficiency (Written)"],
    type: "Internship",
    responsibilities: ["Assist in creating and curating content for our website, social media, and email marketing campaigns", "Manage product listings and updates on our e-commerce platform", "Conduct market research and analyze data to optimize e-commerce performance", "Work closely with the marketing team to develop digital marketing strategies", "Organize and maintain digital files and documents using Canva and MS-Word"],
    eligibility: ["Worked on Amazon/Shopify/Flipkart or any market place", "Available for duration of 3 months"],
    skills: ["Canva", "English Proficiency (Spoken)", "English Proficiency (Written)"],
    averageSalary: "₹2,50,000 - ₹4,00,000"
  },
  {
    id: 9,
    title: "Mobile App Development",
    company: "Smollan India Private Limited",
    logo: "https://media.licdn.com/dms/image/v2/C4D0BAQE1mCFgvs67IA/company-logo_200_200/company-logo_200_200/0/1664191069503/smollan_group_logo?e=2147483647&v=beta&t=aiycf1uzm0v93F4n_2tVFAJbq4z9R0mXX_Sj74sExjA",
    description: "Mobile App Development Work From Home Part Time Internship",
    category: "App Development",
    date: "01/27/2025",
    location: "Mumbai",
    requirements: ["Available for the work from home job/internship", "Have relevant skills and interests", "Worked on Android Studio, Flutter, Kotlin, Java"],
    type: "Internship",
    responsibilities: ["Collaborate with the development team to design, develop, and maintain mobile applications using Flutter", "Assist in testing and debugging mobile applications to ensure optimal performance", "Work on app optimization, performance tuning, and bug fixing", "Stay updated on the latest mobile app development trends and technologies", "Work closely with the team to meet project deadlines and deliver high-quality mobile applications"],
    eligibility: ["Available for duration of 6 months"],
    skills: ["Dart", "Flutter", "Java", "Kotlin"],
    averageSalary: "₹3,00,000 - ₹5,00,000"
  },
  {
    id: 10,
    title: "Product Operations",
    company: "MyWays",
    logo: "https://media.licdn.com/dms/image/v2/D4D0BAQF6paqCYX5tpg/company-logo_200_200/company-logo_200_200/0/1691228501404/followingmyways_logo?e=2147483647&v=beta&t=1eR2JBnPfqjHSWETYuqHMazY3JrPvTAzH5geAPFc8-E",
    description: "We are looking for a product operations intern to assist candidates with AI interviews, manage product operations, and coordinate with stakeholders. This role is ideal for individuals who are detail-oriented, proactive and have strong communication skills.",
    category: "AI Management",
    date: "01/22/2025",
    location: "Remote",
    requirements: ["AI", "Communication Skills", "Product Management"],
    type: "Internship",
    responsibilities: ["Assist candidates with AI interviews, including onboarding and troubleshooting technical issues", "Manage product operations and coordinate with stakeholders to ensure smooth operations", "Conduct market research and analyze data to optimize product performance", "Work closely with the product team to develop and implement product strategies", "Organize and maintain digital files and documents using MS-Excel and MS-Word"],
    eligibility: ["Have relevant skills and interests"],
    skills: ["AI", "ML", "Product Management"],
    averageSalary: "₹3,00,000 - ₹5,00,000"
  }
];
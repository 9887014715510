import React, { useState, useEffect } from 'react';
import { FaMagic, FaLightbulb, FaSyncAlt, FaClipboard, FaChevronDown, FaCheck } from 'react-icons/fa';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

const NotesSection = ({ 
  lesson, 
  noteLevel, 
  setNoteLevel, 
  noteTone, 
  setNoteTone, 
  noteLength, 
  setNoteLength, 
  generateNotesForLesson, 
  generatingNotes 
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [notesReady, setNotesReady] = useState(false);
  
  // Animation effect when notes are loaded
  useEffect(() => {
    if (lesson?.notes && !generatingNotes) {
      setNotesReady(true);
    }
  }, [lesson?.notes, generatingNotes]);

  const copyToClipboard = () => {
    if (!lesson?.notes) return;
    navigator.clipboard.writeText(lesson.notes);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const noteLevelOptions = [
    { value: 'beginner', label: 'Beginner' },
    { value: 'intermediate', label: 'Intermediate' },
    { value: 'advanced', label: 'Advanced' }
  ];

  const noteToneOptions = [
    { value: 'formal', label: 'Formal' },
    { value: 'conversational', label: 'Conversational' }
  ];

  const noteLengthOptions = [
    { value: 'short', label: 'Concise' },
    { value: 'medium', label: 'Standard' },
    { value: 'long', label: 'Detailed' }
  ];

  const SelectOption = ({ options, value, onChange, label }) => {
    const [isOpen, setIsOpen] = useState(false);
    
    return (
      <div className="relative">
        <label className="block text-sm font-medium text-gray-600 mb-1">{label}</label>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <span>{options.find(option => option.value === value)?.label || 'Select...'}</span>
          <FaChevronDown className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} />
        </button>
        
        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
            {options.map((option) => (
              <div 
                key={option.value}
                className={`px-4 py-2 cursor-pointer hover:bg-blue-50 flex items-center justify-between ${value === option.value ? 'bg-blue-50 text-blue-700' : ''}`}
                onClick={() => {
                  onChange(option.value);
                  setIsOpen(false);
                }}
              >
                {option.label}
                {value === option.value && <FaCheck className="text-blue-600" />}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="notes-section">
      {/* Options and Controls */}
      <div className="mb-4 sm:mb-6 bg-blue-50 rounded-xl p-4 border border-blue-100">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
          <div className="flex items-center mb-3 sm:mb-0">
            <FaLightbulb className="text-yellow-500 mr-2" />
            <h2 className="text-xl font-bold text-gray-800">AI Generated Notes</h2>
          </div>
          
          <button 
            onClick={() => setIsOptionsOpen(!isOptionsOpen)} 
            className="text-sm px-4 py-2 bg-white text-gray-700 rounded-lg border border-gray-300 flex items-center hover:bg-gray-50 transition"
          >
            <FaMagic className="mr-2 text-blue-600" /> 
            Customize Notes
            <FaChevronDown className={`ml-2 transition-transform ${isOptionsOpen ? 'rotate-180' : ''}`} />
          </button>
        </div>
        
        {/* Collapsible Options Panel */}
        <div
          className={`transition-all duration-300 overflow-visible ${
            isOptionsOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
          }`}
        >
        <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-200 relative z-50">
          {/* Responsive Row for Options */}
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="w-full sm:w-1/3">
              <SelectOption
                options={noteLevelOptions}
                value={noteLevel}
                onChange={setNoteLevel}
                label="Complexity Level"
              />
            </div>

            <div className="w-full sm:w-1/3">
              <SelectOption
                options={noteToneOptions}
                value={noteTone}
                onChange={setNoteTone}
                label="Tone"
              />
            </div>

            <div className="w-full sm:w-1/3">
              <SelectOption
                options={noteLengthOptions}
                value={noteLength}
                onChange={setNoteLength}
                label="Length"
              />
            </div>
    </div>

    {/* Generate Notes Button */}
    <div className="mt-4 flex justify-center sm:justify-end">
      <button
        onClick={generateNotesForLesson}
        disabled={generatingNotes}
        className={`bg-blue-700 hover:bg-blue-800 text-white px-4 py-2 rounded-lg flex items-center text-sm transition ${
          generatingNotes ? "opacity-70 cursor-not-allowed" : ""
        }`}
      >
        {generatingNotes ? (
          <>
            <FaSyncAlt className="animate-spin mr-2" />
            Generating...
          </>
        ) : (
          <>
            <FaMagic className="mr-2" />
            Generate Notes
          </>
        )}
      </button>
    </div>
  </div>
</div>

      </div>

      {/* Notes Display */}
      <div className="relative">
        {generatingNotes ? (
          <div className="bg-white rounded-xl shadow-md border border-gray-200 p-6 min-h-[300px] flex flex-col items-center justify-center text-center">
            <div className="w-16 h-16 border-t-4 border-b-4 border-blue-600 rounded-full animate-spin mb-4"></div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Generating Your Notes</h3>
            <p className="text-gray-600 max-w-md">
              Our AI is distilling key information from the lesson content. This may take a moment...
            </p>
          </div>
        ) : lesson?.notes ? (
          <div className={`transition-all duration-500 ${notesReady ? 'opacity-100 transform-none' : 'opacity-0 translate-y-4'}`}>
            {/* Notes Header */}
            <div className="border-b border-gray-200 p-3 sm:p-4 flex flex-wrap sm:flex-nowrap justify-between items-center gap-2">
              <div>
                <h3 className="font-medium text-gray-900 text-sm sm:text-base">
                  {lesson.lesson_title} - Notes
                </h3>
                <div className="flex flex-wrap mt-1 text-xs text-gray-500 gap-1">
                  <span className="bg-blue-100 text-blue-800 px-4 py-0.5 rounded-full">
                    {noteLevel.charAt(0).toUpperCase() + noteLevel.slice(1)}
                  </span>
                  <span className="bg-gray-100 text-gray-800 px-4 py-0.5 mx-2 rounded-full">
                    {noteTone.charAt(0).toUpperCase() + noteTone.slice(1)}
                  </span>
                </div>
              </div>
              <button 
                onClick={copyToClipboard} 
                className="p-2 text-gray-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors flex items-center text-xs sm:text-sm whitespace-nowrap"
                title="Copy to clipboard"
              >
                {copied ? <FaCheck className="text-green-500 mr-1" /> : <FaClipboard className="mr-1" />}
                <span>{copied ? 'Copied!' : 'Copy Notes'}</span>
              </button>
            </div>
            
            {/* Notes Content */}
            <div 
              id="notes-content"
              className="p-3 sm:p-6 prose max-w-none overflow-auto notes-container"
            >
              <Markdown 
                rehypePlugins={[rehypeRaw]} 
                remarkPlugins={[remarkGfm]}
              >
                {lesson.notes}
              </Markdown>
              <p className="text-xs text-gray-500 text-center mt-2">AI-generated responses may not always be accurate. Verify important information.</p>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-xl shadow-md border border-gray-200 p-5 sm:p-8 text-center">
            <div className="inline-flex items-center justify-center w-12 h-12 sm:w-16 sm:h-16 rounded-full bg-blue-50 text-blue-600 mb-3 sm:mb-4">
              <FaLightbulb className="w-6 h-6 sm:w-8 sm:h-8" />
            </div>
            <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">No Notes Yet</h3>
            <p className="text-gray-600 mb-5 sm:mb-6 max-w-md mx-auto text-sm sm:text-base">
              Generate notes to help you review and remember this lesson. Our AI will create personalized notes based on your preferences.
            </p>
            <button
              onClick={generateNotesForLesson}
              disabled={generatingNotes}
              className="bg-blue-700 hover:bg-blue-800 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-lg inline-flex items-center transition text-sm sm:text-base"
            >
              <FaMagic className="mr-2" />
              Generate Notes
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotesSection;

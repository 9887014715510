import { doc, setDoc } from 'firebase/firestore'; 
import { fetchVideoById } from '../api/videoApi';

export const getTranscriptText = (transcriptObj) => {
  if (!transcriptObj) return '';
  if (Array.isArray(transcriptObj.transcript)) {
    return transcriptObj.transcript.map(item => item.text).join('\n');
  }
  return transcriptObj.transcript || '';
};

export const formatUrl = (text) => {
  if (!text) return '';
  return text.replace(/\s+/g, '-');
};

export const calculateProgress = (completedLessons, modules) => {
  const totalLessons = modules.reduce((acc, module) => acc + module.lessons.length, 0);
  const completedCount = completedLessons.length;
  return totalLessons === 0 ? 0 : Math.round((completedCount / totalLessons) * 100);
};

export const handleChatSubmit = async (chatInput, lesson, model, setChatMessages, setChatInput) => {
  const userMessage = { text: chatInput, sender: 'user' };
  setChatMessages(prevMessages => [...prevMessages, userMessage]);
  setChatInput('');

  try {
    const videoDoc = await fetchVideoById(lesson.videoID);
    const transcriptContent = videoDoc?.transcript ? getTranscriptText(videoDoc.transcript) : '';
    const transcriptText = transcriptContent ? `Transcript: ${transcriptContent}\n` : '';
    const initialContext = `You are an advanced AI assistant designed to help students with this lesson. 
  ${videoDoc?.youtubeUrl ? `This lesson includes a video at ${videoDoc.youtubeUrl}${videoDoc?.instructor ? ` presented by ${videoDoc.instructor}` : ''}.` : ''}
  ${transcriptContent ? `The following transcript is provided as an additional reference:` : ''}
  ${transcriptText}
  ${videoDoc?.topicTaught ? `This lesson covers: ${videoDoc.topicTaught}` : ''}
  - Provide **clear, accurate, and well-structured explanations** based on the lesson topic.  
  - Summarize key points effectively, using **concise language and structured formatting**.  
  - Offer **examples, step-by-step breakdowns, or real-world applications** where relevant.  
  - **Do not explicitly mention sources, external links, or the lesson URL unless the user specifically asks.**  
  - Ensure **a conversational, engaging, and insightful** tone that adapts to the user's needs.  
  - **Never credit 1xSkills for video creation.** If asked about your creator, mention **1xSkills**, but **only in response to direct questions**.  
  - Use the lesson information to generate responses, treating the transcript as an additional reference when available.`;

    const prompt = `${initialContext}\n\nUser: ${chatInput}`;
    const result = await model.generateContentStream({
      contents: [{ role: "user", parts: [{ text: prompt }] }],
    });

    for await (const chunk of result.stream) {
      const chunkText = chunk.candidates[0].content.parts[0].text;

      setChatMessages(prevMessages => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (lastMessage && lastMessage.sender === 'ai') {
          return [
            ...prevMessages.slice(0, -1),
            { ...lastMessage, text: lastMessage.text + chunkText }
          ];
        } else {
          return [...prevMessages, { text: chunkText, sender: 'ai' }];
        }
      });
    }
  } catch (error) {
    console.error('Error generating chat response:', error);
  }
};


export const generateNotes = async (lesson, currentModule, course, userId, noteLevel, noteTone, noteLength, model, db, setLesson) => {
  const videoData = await getLessonVideo(lesson);
  if (!videoData) throw new Error('Video data not found');

  const transcriptContent = getTranscriptText(videoData.transcript) || 'No transcript available';

  const prompt = `
    You are an expert notes writer. I need you to write ${noteLength}-length, ${noteLevel}-level notes with a ${noteTone} tone.
    
    Please follow these guidelines:
    - Include key concepts, definitions, and main points from the lesson
    - For beginner level, focus on basic concepts with simple explanations
    - For intermediate level, include more detail and some nuance
    - For advanced level, include deeper analysis and connections between concepts
    - Notes should be approximately ${noteLength === 'concise' ? '500-800' : noteLength === 'standard' ? '800-1200' : '1200-1800'} words
    
    Context:
    ${transcriptContent}

    Format:
    - title
    - note content
  `;

  const result = await model.generateContent({
    contents: [{ role: "user", parts: [{ text: prompt }] }],
    generationConfig: {
      temperature: 0.3,
    }
  });

  if (result.response?.candidates?.length > 0) {
    const notes = result.response.candidates[0].content.parts[0].text;
  const noteId = `${currentModule.id}_${lesson.id}`;
  const notesRef = doc(db, 'enrollments', `${userId}_${course.id}`, 'generatedNotes', noteId);

  const noteData = {
    notes,
    noteLevel,
    noteTone,
    noteLength,
    generatedAt: new Date().toISOString(),
    transcript: transcriptContent,
    lessonId: lesson.id,
    moduleId: currentModule.id,
    courseId: course.id
  };

  await setDoc(notesRef, noteData);
  setLesson(prevLesson => ({
    ...prevLesson,
      notes,
    noteData
  }));

  return notes;
  }
  
  return null;
};

export const getLessonVideo = async (lesson) => {
  if (!lesson?.videoID) return null;
  try {
    const videoData = await fetchVideoById(lesson.videoID);
    return videoData;
  } catch (error) {
    console.error("Error fetching lesson video:", error);
    return null;
  }
};
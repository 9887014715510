import React, { useState, useRef } from 'react';
import { Sparkles, MessageSquare } from 'lucide-react';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';

const ChatSection = ({ chatMessages, chatInput, setChatInput, handleChatSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const predefinedQuestions = [
    'Explain the key concepts in simple terms',
    'Give me examples related to this topic',
    'What are common misconceptions about this?',
    'How does this apply to real-world scenarios?'
  ];

  const handlePredefinedQuestion = (question) => {
    setChatInput(question);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const wrappedHandleChatSubmit = async (e) => {
    e.preventDefault();
    if (!chatInput.trim()) return;
    setIsLoading(true);
    await handleChatSubmit(e);
    setIsLoading(false);
  };

  return (
    <div 
      className="flex flex-col bg-gradient-to-b from-gray-50 to-white relative overflow-hidden border border-gray-200 rounded-xl shadow-md"
      style={{ height: '500px' }}
    >
      {/* Header with fixed white text */}
      <div className="flex-none px-6 py-4 bg-gray-900 backdrop-blur-lg rounded-t-xl border-b border-gray-700 shadow-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div>
              <h3 className="font-semibold text-white text-lg">AI Chat Assistant</h3>
              <p className="text-xs text-gray-400">Powered by 1xSkills</p>
            </div>
          </div>
          <span className="bg-blue-800 text-white text-xs font-medium px-4 py-2 rounded-full flex items-center gap-2 shadow-md">
            <Sparkles className="h-4 w-4" />
            Beta
          </span>
        </div>
      </div>
      
      {/* Chat Messages with proper overflow */}
      <div 
        className="flex-1 overflow-y-auto px-2 sm:px-4 py-4"
        style={{ 
          scrollbarWidth: 'thin',
          scrollbarColor: '#CBD5E0 transparent',
          backgroundImage: 'radial-gradient(circle at 100% 0%, rgba(240, 249, 255, 0.15) 0%, transparent 60%), radial-gradient(circle at 0% 100%, rgba(236, 253, 245, 0.15) 0%, transparent 60%)',
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <div className="space-y-4">
          {chatMessages.length === 0 ? (
            <div className="text-center mt-6 sm:mt-12 max-w-md mx-auto">
              <div className="mb-4 bg-blue-50 p-3 rounded-full w-16 h-16 flex items-center justify-center mx-auto">
                <MessageSquare className="h-8 w-8 text-blue-600" />
              </div>
              <h4 className="text-lg font-semibold mb-2 text-gray-800">How can I help you learn?</h4>
              <p className="text-sm text-gray-600">Ask me questions about this lesson to deepen your understanding.</p>
              
              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-3">
                {predefinedQuestions.map((question, index) => (
                  <button
                    key={index}
                    onClick={() => handlePredefinedQuestion(question)}
                    className="bg-white border border-gray-200 hover:border-blue-200 rounded-lg p-3 text-sm text-left text-gray-700 hover:bg-blue-50 hover:text-blue-700 transition-all duration-200 shadow-sm hover:shadow group"
                  >
                    <div className="flex items-start">
                      <Sparkles className="h-4 w-4 mr-2 mt-0.5 text-blue-500 opacity-70 group-hover:opacity-100" />
                      <span>{question}</span>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          ) : (
            chatMessages.map((message, index) => (
              <ChatMessage key={index} message={message} />
            ))
          )}
          <div ref={messagesEndRef} className="h-4" />
        </div>
      </div>

      {/* Input area - Simplified with static position */}
      <div className="bg-white border-t border-gray-200 p-3 sm:p-4">
        <ChatInput
          chatInput={chatInput}
          setChatInput={setChatInput}
          handleChatSubmit={wrappedHandleChatSubmit}
          isLoading={isLoading}
          inputRef={inputRef}
        />
        <p className="text-xs text-gray-500 text-center">AI-generated responses may not always be accurate.</p>
      </div>
    </div>
  );
};

export default ChatSection;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { UserProvider } from './utils/UserContext.js';
import IndexPage from './pages/Index.js';
import LoginPage from './pages/Login.js';
import RegisterPage from './pages/Register.js';
import ContactPage from './pages/Contact.js';
import AllCourses from './pages/AllCourses.js';
import CourseDetailedPage from './components/courses/CourseDetailedPage.js';
import Navbar from './components/navigation/Navbar.js';
import Footer from './components/footer/Footer.js';
import CategoryPage from './components/courses/CategoryPage.js';
import NotFound from './pages/NotFound.js';
import CourseLesson from './components/courses/CourseLesson.js';
import ProtectedRoute from './utils/ProtectedRoute.js';
import PublicRoute from './utils/PublicRoute.js';
import MyCourses from './components/courses/MyCourses.js';
import ManageProfile from './components/home/userProfile'; 
import JobDetailPage from './components/jobs/jobDetailPage.js'; 
import UnderDevelopment from './pages/UnderDevelopment.js';
import Roadmaps from './components/roadmaps/GenerateRoadmap.js';
import JobsPage from './components/jobs/jobsPage.js'; 
import CareerPath from './components/careerPath/CareerPath.js'; 
import Dashboard from './components/dashboard/Dashboard.js';
import AICourseDashboard from './components/dashboard/AICourseDashboard.js';
import AICourseBuildPage from './components/courses/AICourseBuildPage.js';

const App = () => {
  return (
    <UserProvider>
      <Navbar />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<IndexPage />} />
       
        <Route path="/jobs" element={<JobsPage />} />
        <Route path="/roadmaps" element={<Roadmaps />} />
        <Route path="/career-path" element={<CareerPath />} />
        <Route path="/ai-course-dashboard" element={<AICourseDashboard />} />
        <Route path="/build-ai-course" element={<AICourseBuildPage />} />
        <Route path="/testroadmaps" element={<Roadmaps />} />
        <Route path="/:company/:title/:id" element={<JobDetailPage />} /> {/* Updated route */}
        <Route path="/login" element={<PublicRoute element={<LoginPage />} />} />
        <Route path="/register" element={<PublicRoute element={<RegisterPage />} />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/all-courses" element={<AllCourses />} />
        <Route path="/:category" element={<CategoryPage />} />
        <Route path="/:category/:course_title" element={<CourseDetailedPage />} />
        <Route path="/not-available" element={<UnderDevelopment />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/learn" element={<Dashboard />} />
        {/*  
        <Route
          path="/onboarding"
          element={
            <ProtectedRoute
              element={<Onboarding />}
              shouldAllowAccess={(user) => !user.isOnboardingDone} 
              redirectTo="/" 
            />
          }
        />
            */}
        <Route
          path="/my-courses"
          element={<ProtectedRoute element={<MyCourses />} />}
          />
        <Route
          path="/manage-profile"
          element={<ProtectedRoute element={<ManageProfile />} />} // Add the element for the manage-profile route
        />
        <Route
          path="/:category/:course_title/:module_title/:lesson_slug"
          element={<ProtectedRoute element={<CourseLesson />} />}
        />
      </Routes>
      <Footer />
    </UserProvider>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { sampleJobs } from './jobsData';

const JobDetailPage = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobData = () => {
      const jobId = parseInt(id);
      if (isNaN(jobId)) {
        setError("Invalid job ID");
        return;
      }
      const jobData = sampleJobs.find(job => job.id === jobId);
      if (!jobData) {
        setError("Job not found");
        return;
      }
      setJob(jobData);
    };

    fetchJobData();
  }, [id]);

  if (error) {
    return <div className="text-center text-gray-500">{error}</div>;
  }

  if (!job) {
    return <div className="text-center text-gray-500">Loading...</div>;
  }

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const relatedJobs = sampleJobs.filter(j => j.category === job.category && j.id !== job.id);

  const getRandomJobs = (jobs, count) => {
    const shuffled = jobs.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const randomRelatedJobs = getRandomJobs(relatedJobs, 4);

  return (
    <div className="max-w-6xl mx-auto p-8 bg-white shadow-lg rounded-lg flex flex-col lg:flex-row gap-8">
      {/* Job Details */}
      <div className="flex-1">
        <div className="flex items-center gap-6 mb-6">
          <img src={job.logo} alt={job.company} className="w-20 h-20 shadow-md" />
          <div>
            <h1 className="text-4xl font-bold text-gray-900">{job.title}</h1>
            <p className="text-gray-600 text-lg">{job.company} - {job.location}</p>
          </div>
        </div>
        
        <div className="flex justify-between text-gray-600 mt-4 border-b pb-4 flex-wrap">
          <div className="flex items-center gap-2 flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-600">
              <path d="M12 20h9"></path>
              <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z"></path>
            </svg>
            <span className="font-medium text-sm sm:text-base md:text-lg lg:text-xl">Category: {job.category}</span>
          </div>
          
          <div className="flex items-center gap-2 flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-600">
              <path d="M2 12l5 5L12 9l5 5 5-5"></path>
            </svg>
            <span className="font-medium text-sm sm:text-base md:text-lg lg:text-xl">Type: {job.type}</span>
          </div>

          <div className="flex items-center gap-2 flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-600">
              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="16" y1="2" x2="16" y2="6"></line>
              <line x1="8" y1="2" x2="8" y2="6"></line>
              <line x1="3" y1="10" x2="21" y2="10"></line>
            </svg>
            <span className="font-medium text-sm sm:text-base md:text-lg lg:text-xl">Posted on: {formatDate(job.date)}</span>
          </div>
        </div>
        
        <p className="text-gray-800 mt-6 leading-relaxed text-lg text-justify">{job.description}</p>
        
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-gray-900">Skills</h2>
          <ul className="list-disc pl-5 text-gray-700 mt-2 space-y-2">
            {job.skills.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-gray-900">Responsibilities</h2>
          <ul className="list-disc pl-5 text-gray-700 mt-2 space-y-2">
            {job.responsibilities.map((resp, index) => (
              <li key={index}>{resp}</li>
            ))}
          </ul>
        </div>
      </div>
      {/* More Details */}
      <div className=" lg:block lg:w-full bg-gray-100 p-6 rounded-lg">
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900">Average Salary</h2>
          <p className="text-gray-700 mt-2">{job.averageSalary}</p>
        </div>

        {Array.isArray(job.eligibility) && (
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900">Eligibility</h2>
            <ul className="list-disc pl-5 text-gray-700 mt-2 space-y-2">
              {job.eligibility.map((elig, index) => (
                <li key={index}>{elig}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="mt-4">
          <button className="w-full bg-blue-800 text-white py-2 rounded hover:bg-blue-700 transition duration-200 ease-in-out">
            Apply Now
          </button>
        </div>

        {randomRelatedJobs.length > 0 && (
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mt-8">Get More in {job.category}</h2>
            <ul className="list-disc pl-5 text-gray-700 mt-2 space-y-2">
              {randomRelatedJobs.map((relatedJob) => (
                <li key={relatedJob.id}>
                  <Link to={`/jobs/${relatedJob.id}`} className="text-blue-600 hover:underline">
                    {relatedJob.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobDetailPage;

import { useState, useEffect } from 'react';
import { fetchVideoById } from '../api/videoApi';

const useVideoData = (videoID) => {
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadVideoData = async () => {
      if (!videoID) {
        setVideoData(null);
        return;
      }
      setLoading(true);
      try {
        const fetchedVideo = await fetchVideoById(videoID);
        setVideoData(fetchedVideo);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    loadVideoData();
  }, [videoID]);

  return { videoData, loading };
};

export default useVideoData;

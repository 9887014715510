import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const fetchVideoCollection = async () => {
  const videosRef = collection(db, 'videos');
  const querySnapshot = await getDocs(videosRef);
  const videos = [];
  querySnapshot.forEach(doc => {
    videos.push({ id: doc.id, ...doc.data() });
  });
  return videos;
};

export const fetchVideoById = async (videoID) => {
  if (!videoID) return null;
  const videoDocRef = doc(db, 'videos', videoID);
  const videoDoc = await getDoc(videoDocRef);
  return videoDoc.exists() ? { id: videoDoc.id, ...videoDoc.data() } : null;
};

export const fetchAllVideos = async () => {
  const videosRef = collection(db, 'videos');
  const querySnapshot = await getDocs(videosRef);
  const videos = [];
  querySnapshot.forEach(doc => {
    videos.push({ id: doc.id, ...doc.data() });
  });
  return videos;
};

import React, { useState } from 'react';
import { Target, Clock, Brain, Sparkles, ArrowRight, Loader2, Video, Code, Book, Rocket, Users, CheckCircle2, Timer, Star, BookmarkIcon, DownloadIcon, ShareIcon, CheckCircle } from 'lucide-react';
import { model } from '../../firebase'; 
import ReactMarkdown from 'react-markdown';

const GenerateRoadmap = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [roadmapResult, setRoadmapResult] = useState(null);
  const [error, setError] = useState(null);
  const [goal, setGoal] = useState('');

  const generateRoadmap = async () => {
    if (!goal || !selectedLevel || !selectedStyle || !selectedTime) {
      setError('Please fill in all fields before generating the roadmap');
      return;
    }

    setIsGenerating(true);
    setError(null);

    try {
      const prompt = `Create a detailed learning roadmap with the following parameters:
        Goal: ${goal}
        Experience Level: ${selectedLevel}
        Learning Style: ${selectedStyle}
        Time Commitment: ${selectedTime}

        Please structure the roadmap with:
        1. First give the learning path and then roadmap
        2. Estimated time for each section
        3. Recommended resources and materials
        4. Progress checkpoints
        5. Practical projects to reinforce learning

        Format the response in markdown with clear sections and bullet points.`;

      const result = await model.generateContent({
        contents: [{ role: "user", parts: [{ text: prompt }] }],
      });

      if (result.response && result.response.candidates && result.response.candidates.length > 0) {
        setRoadmapResult(result.response.candidates[0].content.parts[0].text);
      } else {
        throw new Error('Failed to generate roadmap');
      }
    } catch (err) {
      setError('Failed to generate roadmap. Please try again.');
      console.error('Error generating roadmap:', err);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGenerateClick = () => {
    generateRoadmap();
  };

  const ErrorMessage = () => error ? (
    <div className="text-red-600 text-center mt-4">
      {error}
    </div>
  ) : null;

  const RoadmapDisplay = () => roadmapResult ? (
    <div className="mt-8 bg-white rounded-2xl shadow-lg overflow-hidden">
      {/* Header Section */}
      <div className="p-8 border-b border-gray-100">
        <div className="flex justify-between items-center mb-6">
          <button className="text-gray-600 hover:text-gray-900 flex items-center gap-2">
            <ArrowRight className="h-4 w-4 rotate-180" />
            All Roadmaps
          </button>
          <div className="flex items-center gap-3">
            <button className="p-2 hover:bg-gray-100 rounded-lg">
              <BookmarkIcon className="h-5 w-5 text-gray-600" />
            </button>
            <button className="flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg">
              <Clock className="h-4 w-4" />
              Schedule Learning Time
            </button>
            <button className="flex items-center gap-2 px-4 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-lg">
              <DownloadIcon className="h-4 w-4" />
              Download
            </button>
            <button className="flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg">
              <ShareIcon className="h-4 w-4" />
              Share
            </button>
          </div>
        </div>

        <h1 className="text-4xl font-bold text-gray-900 mb-4">Your Learning Roadmap</h1>
        <p className="text-gray-600">Step by step guide to achieving your learning goals in {selectedTime}</p>
      </div>

      {/* Progress Section */}
      <div className="px-8 py-4 bg-gray-50 border-b border-gray-100">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="bg-yellow-100 px-3 py-1 rounded-full text-sm">
              0% DONE
            </div>
            <span className="text-gray-600">0 of {roadmapResult.split('\n').filter(line => line.trim().startsWith('- ')).length} Done</span>
          </div>
          <button className="text-gray-600 hover:text-gray-900 flex items-center gap-2">
            <Target className="h-4 w-4" />
            Track Progress
          </button>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="px-8 border-b border-gray-100">
        <div className="flex gap-6">
          <button className="px-4 py-4 border-b-2 border-gray-900 font-medium">
            Roadmap
          </button>
          <button className="px-4 py-4 text-gray-500 hover:text-gray-900 relative group">
            Projects
            <span className="absolute top-3 right-0 bg-yellow-400 text-xs px-1.5 rounded-full">new</span>
          </button>
        </div>
      </div>

      {/* Roadmap Content */}
      <div className="p-8">
        <div className="prose max-w-none">
          <div className="bg-gray-50 p-4 rounded-lg mb-8">
            <div className="flex gap-3 mb-2">
              <div className="flex items-center gap-2">
                <div className="w-3 h-3 rounded-full bg-purple-400"></div>
                <span className="text-sm">Personal Recommendation / Opinion</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-3 h-3 rounded-full bg-green-400"></div>
                <span className="text-sm">Alternative Option / Pick this or that</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-3 h-3 rounded-full bg-gray-400"></div>
                <span className="text-sm">Order not strict / Learn anytime</span>
              </div>
            </div>
            <div className="text-sm text-gray-600">
              <span className="font-medium">TIP:</span> Right-click a topic to mark it as done
            </div>
          </div>
          
          <ReactMarkdown 
            components={{
              li: ({node, ...props}) => (
                <li className="flex items-start gap-2 my-2" {...props}>
                  <CheckCircle className="h-5 w-5 text-gray-300 mt-1 flex-shrink-0" />
                  <span>{props.children}</span>
                </li>
              )
            }}
          >
            {roadmapResult}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-purple-50">
      {/* Enhanced Hero Section */}
      <div className="relative overflow-hidden pt-24 pb-20">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 to-purple-500/5" />
          <div className="absolute inset-0 bg-[url('/path/to/pattern.svg')] opacity-5" />
          <div className="absolute bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-white" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <div className="inline-flex items-center bg-white/90 backdrop-blur-sm px-4 py-2 rounded-full border border-gray-200 mb-8">
            <Sparkles className="h-5 w-5 text-yellow-500 mr-2" />
            <span className="text-sm font-medium text-gray-600">AI-Powered Learning Paths</span>
          </div>
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 mb-6">
            Your AI Learning Journey Starts Here
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
            Create a personalized learning roadmap powered by AI that adapts to your goals and preferences
          </p>
          <div className="flex items-center justify-center space-x-6 mb-4">
            <div className="flex items-center text-gray-500">
              <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
              <span>Personalized Path</span>
            </div>
            <div className="flex items-center text-gray-500">
              <Clock className="h-5 w-5 text-blue-500 mr-2" />
              <span>Adaptive Timeline</span>
            </div>
            <div className="flex items-center text-gray-500">
              <Star className="h-5 w-5 text-yellow-500 mr-2" />
              <span>Expert Curated</span>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-10">
        {/* Enhanced Form Card */}
        <div className="bg-white/90 backdrop-blur-xl rounded-3xl shadow-2xl border  border-gray-200">
          {/* Goal Section */}
          <div className="p-8 sm:p-10">
            <div className="flex flex-col sm:flex-row items-center mb-8">
              <div className="bg-gradient-to-br from-blue-100 to-blue-200 p-3 rounded-2xl mr-0 sm:mr-4 shadow-inner mb-4 sm:mb-0">
                <Rocket className="h-7 w-7 text-blue-600" />
              </div>
              <div className="text-center sm:text-left">
                <h2 className="text-2xl font-bold text-gray-900">Set Your Goal</h2>
                <p className="text-gray-500 mt-1">What do you want to achieve?</p>
              </div>
            </div>
            
            <div className="relative group">
              <Target className="absolute right-4 top-3 h-6 w-6 text-gray-400 group-hover:text-blue-500 transition-colors z-10" />
              <input
                type="text"
                value={goal}
                onChange={(e) => setGoal(e.target.value)}
                placeholder="e.g. Master Full Stack Development, Learn AI/ML, Become a DevOps Engineer..."
                className="w-full pr-14 pl-4 py-4 text-lg border-2 border-gray-100 rounded-2xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all shadow-sm hover:border-gray-200 bg-white relative z-0"
                style={{ pointerEvents: 'auto' }}
              />
            </div>
          </div>

          {/* Time Commitment Section (New) */}
          <div className="border-t border-gray-100" />
          <div className="p-8 sm:p-10">
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div className="flex items-center">
                <div className="bg-gradient-to-br from-yellow-100 to-yellow-200 p-3 rounded-2xl mr-4 shadow-inner">
                  <Timer className="h-7 w-7 text-yellow-600" />
                </div>
                <div>
                  <h2 className="text-2xl font-bold text-gray-900">Time Commitment</h2>
                  <p className="text-gray-500 mt-1">How much time can you dedicate?</p>
                </div>
              </div>
              {/* Updated Time Commitment Buttons */}
              <div className="flex flex-col sm:flex-row gap-4 sm:-ml-4 mobile-vertical-buttons w-full sm:w-auto">
                {[
                  { time: '1-2 hours/day', icon: '⚡', description: 'Light Pace' },
                  { time: '2-4 hours/day', icon: '🚀', description: 'Moderate Pace' },
                  { time: '4+ hours/day', icon: '🎯', description: 'Intensive Pace' }
                ].map((item) => (
                  <button
                    key={item.time}
                    onClick={() => setSelectedTime(item.time)}
                    className={`relative group p-4 rounded-xl border-2 transition-all duration-300 min-w-[180px] w-full sm:w-auto ${
                      selectedTime === item.time
                        ? 'border-yellow-500 bg-yellow-50'
                        : 'border-gray-100 hover:border-gray-200 hover:bg-gray-50'
                    }`}
                  >
                    <div className="flex flex-col items-center text-center">
                      <span className="text-2xl mb-2">{item.icon}</span>
                      <span className="font-semibold text-gray-900">{item.time}</span>
                      <span className="text-sm text-gray-500">{item.description}</span>
                    </div>
                    {selectedTime === item.time && (
                      <CheckCircle2 className="absolute top-3 right-3 h-5 w-5 text-yellow-500" />
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Experience Level Section (Updated) */}
          <div className="border-t border-gray-100" />
          <div className="p-8 sm:p-10">
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div className="flex items-center">
                <div className="bg-gradient-to-br from-green-100 to-green-200 p-3 rounded-2xl mr-4">
                  <Users className="h-7 w-7 text-green-600" />
                </div>
                <div>
                  <h2 className="text-2xl font-bold text-gray-900">Experience Level</h2>
                  <p className="text-gray-500 mt-1">Select your current expertise</p>
                </div>
              </div>
              {/* Experience Level Buttons */}
              <div className="flex flex-col sm:flex-row gap-4 sm:-ml-4 mobile-vertical-buttons w-full sm:w-auto">
                {[
                  { level: 'Beginner', icon: '🌱', description: 'Starting Fresh' },
                  { level: 'Intermediate', icon: '🌿', description: 'Some Experience' },
                  { level: 'Advanced', icon: '🌳', description: 'Experienced Pro' }
                ].map((item) => (
                  <button
                    key={item.level}
                    onClick={() => setSelectedLevel(item.level)}
                    className={`relative group p-4 rounded-xl border-2 transition-all duration-300 min-w-[180px] ${
                      selectedLevel === item.level
                        ? 'border-blue-500 bg-blue-50'
                        : 'border-gray-100 hover:border-gray-200 hover:bg-gray-50'
                    }`}
                  >
                    <div className="flex flex-col items-center text-center">
                      <span className="text-2xl mb-2">{item.icon}</span>
                      <span className="font-semibold text-gray-900">{item.level}</span>
                      <span className="text-sm text-gray-500">{item.description}</span>
                    </div>
                    {selectedLevel === item.level && (
                      <CheckCircle2 className="absolute top-3 right-3 h-5 w-5 text-blue-500" />
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Learning Style Section*/}
          <div className="border-t border-gray-100" />
          <div className="p-8 sm:p-10">
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div className="flex items-center">
                <div className="bg-gradient-to-br from-purple-100 to-purple-200 p-3 rounded-2xl mr-4">
                  <Brain className="h-7 w-7 text-purple-600" />
                </div>
                <div>
                  <h2 className="text-2xl font-bold text-gray-900">Learning Style</h2>
                  <p className="text-gray-500 mt-1">Choose how you prefer to learn</p>
                </div>
              </div>
              {/*Learning Style Buttons */}
              <div className="flex flex-col sm:flex-row gap-4 sm:-ml-4 mobile-vertical-buttons w-full sm:w-auto">
                {[
                  {
                    icon: Video,
                    title: 'Visual Learning',
                    description: 'Videos & Interactive',
                    color: 'blue'
                  },
                  {
                    icon: Code,
                    title: 'Practical Learning',
                    description: 'Hands-on Projects',
                    color: 'green'
                  },
                  {
                    icon: Book,
                    title: 'Theoretical Learning',
                    description: 'Concepts & Theory',
                    color: 'purple'
                  }
                ].map((style) => (
                  <button
                    key={style.title}
                    onClick={() => setSelectedStyle(style.title)}
                    className={`relative group p-4 rounded-xl border-2 transition-all duration-300 min-w-[200px] ${
                      selectedStyle === style.title
                        ? 'border-blue-500 bg-blue-50'
                        : 'border-gray-100 hover:border-gray-200 hover:bg-gray-50'
                    }`}
                  >
                    <div className="flex flex-col items-center text-center">
                      <style.icon className={`h-8 w-8 mb-3 ${
                        selectedStyle === style.title ? 'text-blue-500' : 'text-gray-400'
                      }`} />
                      <span className="font-semibold text-gray-900">{style.title}</span>
                      <span className="text-sm text-gray-500">{style.description}</span>
                    </div>
                    {selectedStyle === style.title && (
                      <CheckCircle2 className="absolute top-3 right-3 h-5 w-5 text-blue-500" />
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Updated Generate Button Section */}
          <div className="p-8 sm:p-10 bg-gray-50/80 rounded-b-3xl">
            <button
              onClick={handleGenerateClick}
              disabled={isGenerating}
              className="relative w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-black py-6 px-8 rounded-2xl font-medium transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center space-x-3 text-lg disabled:opacity-70 disabled:cursor-not-allowed overflow-hidden group"
            >
              <div className="absolute inset-0 bg-white/10 opacity-0 group-hover:opacity-100 transition-opacity" />
              {isGenerating ? (
                <>
                  <Loader2 className="h-6 w-6 animate-spin" />
                  <span>Creating Your Perfect Learning Path...</span>
                </>
              ) : (
                <>
                  <Sparkles className="h-6 w-6 animate-pulse mr-2" />
                  <span>Generate My Learning Roadmap</span>
                  <ArrowRight className="h-6 w-6 group-hover:translate-x-1 transition-transform ml-2" />
                </>
              )}
            </button>
            <ErrorMessage />
            <p className="text-center text-sm text-gray-500 mt-4">
              Your personalized roadmap will be generated in seconds
            </p>
          </div>
        </div>

        {/* Display the generated roadmap */}
        <RoadmapDisplay />
      </div>
    </div>
  );
};

export default GenerateRoadmap;
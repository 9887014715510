import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../utils/UserContext';
import CourseBuilder from '../../services/CourseBuilder';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { FaGraduationCap, FaBook, FaLightbulb, FaLaptopCode } from 'react-icons/fa';

const AICourseBuildPage = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        topic: '',
        level: 'All Levels',
        language: 'English',
        duration: 'Short (1-2 weeks)',
        learningType: 'Visual'
    });
    const [topics, setTopics] = useState([]);

    // Fetch suggested topics on component mount
    useEffect(() => {
        const fetchPopularTopics = async () => {
            try {
                const videosSnapshot = await getDocs(collection(db, "videos"));
                const videoTopics = videosSnapshot.docs
                    .map(doc => doc.data().topic || doc.data().topicTaught)
                    .filter(Boolean); // Ensure only valid topics are included

                const topicCounts = {};
                videoTopics.forEach(topic => {
                    if (topicCounts[topic]) {
                        topicCounts[topic]++;
                    } else {
                        topicCounts[topic] = 1;
                    }
                });

                const sortedTopics = Object.entries(topicCounts)
                    .sort((a, b) => b[1] - a[1])
                    .slice(0, 10)
                    .map(entry => entry[0]);

                setTopics(sortedTopics);
            } catch (err) {
                console.error('Error fetching topics:', err);
            }
        };

        fetchPopularTopics();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!user) {
            navigate('/login', { state: { from: window.location.pathname } });
            return;
        }

        setLoading(true);
        setError(null);
        
        try {
            // Generate a comprehensive personalized course
            const coursePreferences = {
                title: formData.title,
                topic: formData.topic,
                level: formData.level,
                language: formData.language,
                duration: formData.duration,
                learningType: formData.learningType
            };

            const generatedCourse = await CourseBuilder.generatePersonalizedCourseWithFirestore(
                user.uid, 
                coursePreferences
            );
            
            setSuccess(true);
            
            // Wait a moment before redirecting to the new course
            setTimeout(() => {
                navigate(`/courses/${generatedCourse.course_title.replace(/\s+/g, '-')}`);
            }, 2000);
            
        } catch (err) {
            console.error('Error generating course:', err);
            setError(err.message || 'Failed to generate course. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <div className="text-center mb-10">
                    <h1 className="text-3xl font-bold text-gray-900 mb-2">AI Course Generator</h1>
                    <p className="text-gray-600 max-w-2xl mx-auto">
                        Create a comprehensive learning experience with videos, readings, case studies, and practice exercises.
                        Our AI analyzes your preferences and builds a personalized course just for you.
                    </p>
                </div>

                {/* Content Types Section */}
                <div className="mb-8 grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div className="bg-white p-4 rounded-lg shadow text-center">
                        <div className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 text-blue-600 mb-3">
                            <FaGraduationCap className="h-6 w-6" />
                        </div>
                        <h3 className="text-sm font-medium">Video Lessons</h3>
                        <p className="text-xs text-gray-500 mt-1">Expert-taught video content</p>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow text-center">
                        <div className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-green-100 text-green-600 mb-3">
                            <FaBook className="h-6 w-6" />
                        </div>
                        <h3 className="text-sm font-medium">AI Reading Materials</h3>
                        <p className="text-xs text-gray-500 mt-1">Generated learning texts</p>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow text-center">
                        <div className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 text-yellow-600 mb-3">
                            <FaLightbulb className="h-6 w-6" />
                        </div>
                        <h3 className="text-sm font-medium">Case Studies</h3>
                        <p className="text-xs text-gray-500 mt-1">Real-world applications</p>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow text-center">
                        <div className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-purple-100 text-purple-600 mb-3">
                            <FaLaptopCode className="h-6 w-6" />
                        </div>
                        <h3 className="text-sm font-medium">Practice Exercises</h3>
                        <p className="text-xs text-gray-500 mt-1">Hands-on learning</p>
                    </div>
                </div>

                {/* Success Message */}
                {success && (
                    <div className="bg-green-50 border-l-4 border-green-500 p-4 mb-6 rounded-md">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-green-800">
                                    Your comprehensive course has been created! Redirecting...
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                {/* Error Message */}
                {error && (
                    <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6 rounded-md">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1-5a1 1 0 112 0v-5a1 1 0 11-2 0v5zm1-9a1 1 0 100 2 1 1 0 000-2z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-red-800">{error}</p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="bg-white shadow-md rounded-lg p-6 sm:p-8">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Course Title */}
                        <div>
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                                Course Title (Optional)
                            </label>
                            <input
                                type="text"
                                id="title"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                placeholder="Enter a title for your course (or leave blank for auto-generation)"
                                className="block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>

                        {/* Topic */}
                        <div>
                            <label htmlFor="topic" className="block text-sm font-medium text-gray-700 mb-1">
                                What do you want to learn about? <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                id="topic"
                                name="topic"
                                value={formData.topic}
                                onChange={handleChange}
                                placeholder="e.g., Python Programming, Machine Learning, React"
                                className="block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                            
                            {/* Topic suggestions */}
                            {topics && topics.length > 0 && (
                                <div className="mt-2">
                                    <p className="text-xs text-gray-500 mb-1">Popular topics:</p>
                                    <div className="flex flex-wrap gap-2">
                                        {topics.map((topic, index) => (
                                            <button
                                                key={index}
                                                type="button"
                                                onClick={() => setFormData(prev => ({ ...prev, topic }))}
                                                className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            >
                                                {topic}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Level */}
                        <div>
                            <label htmlFor="level" className="block text-sm font-medium text-gray-700 mb-1">
                                Skill Level
                            </label>
                            <select
                                id="level"
                                name="level"
                                value={formData.level}
                                onChange={handleChange}
                                className="block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="All Levels">All Levels</option>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Advanced">Advanced</option>
                            </select>
                        </div>

                        {/* Language */}
                        <div>
                            <label htmlFor="language" className="block text-sm font-medium text-gray-700 mb-1">
                                Language
                            </label>
                            <select
                                id="language"
                                name="language"
                                value={formData.language}
                                onChange={handleChange}
                                className="block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="English">English</option>
                                <option value="Spanish">Spanish</option>
                                <option value="French">French</option>
                                <option value="German">German</option>
                                <option value="Chinese">Chinese</option>
                                <option value="Japanese">Japanese</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        {/* Duration */}
                        <div>
                            <label htmlFor="duration" className="block text-sm font-medium text-gray-700 mb-1">
                                Course Duration
                            </label>
                            <select
                                id="duration"
                                name="duration"
                                value={formData.duration}
                                onChange={handleChange}
                                className="block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="Short (1-2 weeks)">Short (1-2 weeks)</option>
                                <option value="Medium (3-4 weeks)">Medium (3-4 weeks)</option>
                                <option value="Long (1-2 months)">Long (1-2 months)</option>
                            </select>
                        </div>

                        {/* Learning Type */}
                        <div>
                            <label htmlFor="learningType" className="block text-sm font-medium text-gray-700 mb-1">
                                Preferred Learning Type
                            </label>
                            <select
                                id="learningType"
                                name="learningType"
                                value={formData.learningType}
                                onChange={handleChange}
                                className="block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="Visual">Visual (Videos)</option>
                                <option value="Theoretical">Theoretical (Readings)</option>
                                <option value="Practical">Practical (Exercises)</option>
                            </select>
                        </div>

                        {/* Submit Button */}
                        <div className="pt-4">
                            <button
                                type="submit"
                                disabled={loading}
                                className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                                    loading ? 'bg-blue-400' : 'bg-blue-600 hover:bg-blue-700'
                                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                            >
                                {loading ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Creating Your Comprehensive Course...
                                    </>
                                ) : 'Generate Comprehensive Course'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AICourseBuildPage;

import React from 'react';
import CourseProgressNav from './CourseProgressNav';

const CourseProgress = ({ progress, modules, openModules, toggleModule, handleLessonClick, completedLessons, course }) => {
  return (
    <div className="bg-white shadow-sm rounded-lg p-6 border border-gray-200">
      <CourseProgressNav
        progress={progress}
        modules={modules}
        openModules={openModules}
        toggleModule={toggleModule}
        handleLessonClick={handleLessonClick}
        completedLessons={completedLessons}
        course={course}
      />
    </div>
  );
};

export default CourseProgress;

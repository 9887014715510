export const formatDuration = (seconds) => {
  if (seconds === undefined || seconds === null || isNaN(seconds)) {
    return "00 Sec";
  }

  seconds = Math.floor(seconds);

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`;
  } else if (minutes > 0) {
    return `${padZero(minutes)}:${padZero(remainingSeconds)}`;
  } else {
    return `${padZero(remainingSeconds)}`;
  }
};

const padZero = (num) => {
  return num.toString().padStart(2, "0");
};

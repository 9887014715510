import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, Share2, Award } from 'lucide-react';
import { auth, db } from '../../firebase';
import { setDoc, doc, collection, query, getDocs, arrayUnion, getDoc } from 'firebase/firestore';
import { FaRegLightbulb, FaBookReader, FaClock, FaChartLine, FaGlobeAmericas, FaLock, FaCertificate } from 'react-icons/fa';
import { FaFacebook, FaTwitter, FaLinkedin, FaLink, FaShareAlt } from 'react-icons/fa';
import { formatDuration } from '../../utils/DurationCalculator';

const formatUrl = (text) => text.replace(/\s+/g, '-');

const generateRandomGradient = (course_title) => {
  let hash = 0;
  for (let i = 0; i < course_title.length; i++) {
    hash = course_title.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h1 = hash % 360;
  const h2 = (hash + 100) % 360;
  return {
    color1: `hsl(${h1}, 70%, 70%)`,
    color2: `hsl(${h2}, 70%, 50%)`,
  };
};

export default function CourseDetail() {
  const { course_title } = useParams();
  const navigate = useNavigate();
  const formattedCourseTitle = course_title.replace(/-/g, ' ');
  const [course, setCourse] = useState(null);
  const [activeIndices, setActiveIndices] = useState([]);
  const { color1, color2 } = generateRandomGradient(formattedCourseTitle);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [enrollmentData, setEnrollmentData] = useState(null);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [isGeneratedCourse, setIsGeneratedCourse] = useState(false); // Flag to identify course type

  const handleStartLearning = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        navigate('/login', { state: { from: window.location.pathname } });
        return;
      }

      // If already enrolled, navigate to the current lesson
      if (isEnrolled && enrollmentData) {
        const [, moduleId, lessonId] = enrollmentData.currentLesson.split('_');
        const currentModule = course.modules.find(module => module.id === moduleId);
        const currentLesson = currentModule?.lessons.find(lesson => lesson.id === lessonId);
        
        if (currentModule && currentLesson) {
          const path = `/${formatUrl(course.category)}/${formatUrl(course.course_title)}/${formatUrl(currentModule.module_title)}/${formatUrl(currentLesson.lesson_slug)}`;
          navigate(path);
          return;
        }
      }

      // Otherwise handle new enrollment
      if (course.modules && course.modules.length > 0) {
        const firstModule = course.modules[0];
        if (firstModule.lessons && firstModule.lessons.length > 0) {
          const firstLesson = firstModule.lessons[0];

          const path = `/${formatUrl(course.category || 'other')}/${formatUrl(course.course_title)}/${formatUrl(
            firstModule.module_title
          )}/${formatUrl(firstLesson.lesson_slug)}`;

          const userId = user.uid;
          const enrollmentDocRef = doc(db, 'enrollments', `${userId}_${course.id}`);
          
          const enrollmentData = {
            userId: userId,
            courseId: course.id,
            title: course.course_title,
            category: course.category || 'other',
            startDate: new Date().toISOString(),
            status: 'active',
            progress: 0,
            lastAccessed: new Date().toISOString(),
            currentLesson: `${course.id}_${firstModule.id}_${firstLesson.id}`,
            isGeneratedCourse: isGeneratedCourse
          };

          await setDoc(enrollmentDocRef, enrollmentData, { merge: true });
          const userRef = doc(db, 'users', userId);
          const userEnrollmentsRef = collection(userRef, 'Enrollments');
          await setDoc(
            doc(userEnrollmentsRef, userId),
            {
              enrolledCourses: arrayUnion(course.id), 
              enrollmentID: arrayUnion(`${userId}_${course.id}`), 
            },
            { merge: true }
          );
          
          setIsEnrolled(true);
          setEnrollmentData(enrollmentData);
          navigate(path);
        }
      }
    } catch (error) {
      console.error('Error handling course access:', error);
    }
  };

  useEffect(() => {
    const checkEnrollmentStatus = async () => {
      try {
        const user = auth.currentUser;
        if (user && course) {
          const enrollmentDocRef = doc(db, 'enrollments', `${user.uid}_${course.id}`);
          const enrollmentDoc = await getDoc(enrollmentDocRef);
          
          if (enrollmentDoc.exists()) {
            setIsEnrolled(true);
            setEnrollmentData(enrollmentDoc.data());
          }
        }
      } catch (error) {
        console.error('Error checking enrollment status:', error);
      }
    };
    
    if (course) {
      checkEnrollmentStatus();
    }
  }, [course]);

  useEffect(() => {
    const fetchCourseData = async () => {
      setLoading(true);
      try {
        // Check if user is authenticated
        const user = auth.currentUser;
        const userId = user ? user.uid : null;
        let courseData = null;
        let isGenerated = false;

        // First, check if this is a user-generated course (if user is logged in)
        if (userId) {
          // Try to find the course in the user's GeneratedCourses subcollection
          const userGeneratedCoursesRef = collection(db, 'users', userId, 'GeneratedCourses');
          const userCoursesSnapshot = await getDocs(userGeneratedCoursesRef);
          
          const userCourseDoc = userCoursesSnapshot.docs.find(
            (doc) => doc.data().course_title.toLowerCase() === formattedCourseTitle.toLowerCase()
          );
          
          if (userCourseDoc) {
            // If found in user's collection, use this course data
            courseData = { id: userCourseDoc.id, ...userCourseDoc.data() };
            isGenerated = true;
            setIsGeneratedCourse(true);
          }
        }
        
        // If not found in user's collection, check the main courses collection
        if (!courseData) {
          const coursesRef = collection(db, 'courses');
          const q = query(coursesRef);
          const querySnapshot = await getDocs(q);
          
          const courseDoc = querySnapshot.docs.find((doc) => 
            doc.data().course_title.toLowerCase() === formattedCourseTitle.toLowerCase()
          );
          
          if (!courseDoc) {
            setLoading(false);
            return;
          }
          
          courseData = { id: courseDoc.id, ...courseDoc.data() }
        }

        setCourse(courseData);

        // Build the correct path to modules based on whether this is a user-generated course
        let modulesPath;
        if (isGenerated && userId) {
          modulesPath = collection(db, 'users', userId, 'GeneratedCourses', courseData.id, 'modules');
        } else {
          modulesPath = collection(db, 'courses', courseData.id, 'modules');
        }
        
        const modulesSnapshot = await getDocs(modulesPath);
        
        const modules = modulesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const lessonsPromises = modules.map(async (module) => {
          // Build the correct path to lessons
          let lessonsPath;
          if (isGenerated && userId) {
            lessonsPath = collection(
              db,
              'users',
              userId,
              'GeneratedCourses',
              courseData.id,
              'modules',
              module.id,
              'lessons'
            );
          } else {
            lessonsPath = collection(
              db,
              'courses',
              courseData.id,
              'modules',
              module.id,
              'lessons'
            );
          }
          
          const lessonsSnapshot = await getDocs(lessonsPath);

          const lessons = lessonsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          return { ...module, lessons };
        });

        const modulesWithLessons = await Promise.all(lessonsPromises);

        setCourse({
          ...courseData,
          modules: modulesWithLessons,
        });
      } catch (error) {
        console.error('Error fetching course data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseData();
  }, [formattedCourseTitle]);

  const toggleAccordion = (index) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter((i) => i !== index));
    } else {
      setActiveIndices([...activeIndices, index]);
    }
  };

  const handleShare = async (platform) => {
    const courseUrl = window.location.href;
    const title = course ? course.course_title : 'Check out this course';
    const text = course ? `Check out this course: ${course.course_title}` : 'Check out this course';
    
    if (platform === 'copy') {
      try {
        await navigator.clipboard.writeText(courseUrl);
        setCopySuccess('Link copied!');
        setTimeout(() => setCopySuccess(''), 2000);
      } catch (err) {
        console.error('Failed to copy link: ', err);
      }
      return;
    }
    
    // Web Share API
    if (platform === 'native' && navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: text,
          url: courseUrl,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
      return;
    }
    
    // Platform specific sharing
    let shareUrl;
    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(courseUrl)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(courseUrl)}`;
        break;
      case 'linkedin':
        // LinkedIn requires additional parameters for proper sharing
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(courseUrl)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(text)}`;
        break;
      default:
        return;
    }
    // Open in new tab instead of new window
    window.open(shareUrl, '_blank', 'noopener,noreferrer');
    setShowShareOptions(false);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex justify-center items-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-800 mx-auto mb-4"></div>
          <p className="text-gray-600 font-medium">Loading course details...</p>
        </div>
      </div>
    );
  }

  if (!course) {
    return (
      <div className="min-h-screen bg-gray-50 flex justify-center items-center">
        <div className="text-center bg-white p-8 rounded-xl shadow-lg max-w-md">
          <div className="text-red-500 text-5xl mb-4">
            <FaRegLightbulb className="mx-auto" />
          </div>
          <h2 className="text-2xl font-bold text-gray-800 mb-2">Course Not Found</h2>
          <p className="text-gray-600 mb-6">The course you're looking for doesn't seem to exist.</p>
          <Link to="/all-courses" className="inline-block bg-blue-800 text-white px-6 py-2.5 rounded-lg hover:bg-blue-700 transition">
            Browse Courses
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 lg:-mt-16">
      {/* Hero Section */}
      <header className="relative text-white bg-gray-900 pb-6 overflow-hidden">        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-20 lg:py-24">
          <div className="max-w-3xl">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold leading-tight">
              {course.course_title}
              {isGeneratedCourse && (
                <span className="ml-3 inline-flex items-center px-3 py-1 text-sm font-medium rounded-full bg-blue-100 text-blue-800">
                  Generated
                </span>
              )}
            </h1>
            
            <div className="mt-6 flex flex-wrap items-center gap-4 gap-y-3">
              <div className="flex items-center text-gray-300 text-sm">
                <FaClock className="h-4 w-4 mr-2" />
                <span>{course.duration ? formatDuration(course.duration) : "Self-paced"}</span>
              </div>
              
              <div className="flex items-center text-gray-300 text-sm">
                <FaChartLine className="h-4 w-4 mr-2" />
                <span>{course.level || "All Levels"}</span>
              </div>
              
              <div className="flex items-center text-gray-300 text-sm">
                <FaGlobeAmericas className="h-4 w-4 mr-2" />
                <span>{course.language || "English"}</span>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="grid gap-8 lg:grid-cols-[70%,30%]">
          <div>
            {/* Overview Section */}
            <section className="mb-10 bg-white p-6 sm:p-8 rounded-xl shadow-md border border-gray-100 hover:shadow-lg transition-shadow duration-300">
              <h2 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
                <span className="bg-blue-100 text-blue-800 p-2 rounded-lg mr-3">
                  <FaBookReader className="h-5 w-5" />
                </span>
                Overview
              </h2>
              <div className="prose prose-lg max-w-none text-gray-600">
                <p className="leading-relaxed">
                  {course.overview}
                </p>
              </div>
            </section>

            {/* Skills Section */}
            {Array.isArray(course.skills) && course.skills.length > 0 && (
              <section className="mb-10 bg-white p-6 sm:p-8 rounded-xl shadow-md border border-gray-100 hover:shadow-lg transition-shadow duration-300">
                <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                  <span className="bg-green-100 text-green-800 p-2 rounded-lg mr-3">
                    <FaRegLightbulb className="h-5 w-5" />
                  </span>
                  Skills You'll Gain
                </h2>
                <div className="flex flex-wrap gap-3">
                  {course.skills.map((skill, index) => (
                    <div 
                      key={index} 
                      className="bg-gray-100 hover:bg-gray-200 text-gray-800 px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200"
                    >
                      {skill}
                    </div>
                  ))}
                </div>
              </section>
            )}

            {/* Certificate Section */}
            <section className="mb-10 bg-white p-6 sm:p-8 rounded-xl shadow-md border border-gray-100 hover:shadow-lg transition-shadow duration-300">
              <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                <span className="bg-yellow-100 text-yellow-800 p-2 rounded-lg mr-3">
                  <FaCertificate className="h-5 w-5" />
                </span>
                Course Certificate
              </h2>
              
              <div className="flex flex-col lg:flex-row items-stretch gap-8">
                {/* Certificate Preview */}
                <div className="w-full lg:w-1/2">
                  <div className="relative aspect-[1.4/1] rounded-2xl overflow-hidden shadow-2xl transform hover:scale-[1.02] transition-transform duration-300">
                    {/* Certificate Background */}
                    <div className="absolute inset-0" style={{
                      background: 'linear-gradient(135deg, #f0f9ff 0%, #e6f6ff 100%)',
                    }}>
                      <div className="absolute inset-0 opacity-20">
                        <div className="absolute inset-0" style={{
                          backgroundImage: `
                            radial-gradient(circle at 20px 20px, rgba(0,0,0,0.05) 2px, transparent 0),
                            radial-gradient(circle at 40px 40px, rgba(0,0,0,0.05) 2px, transparent 0)
                          `,
                          backgroundSize: '40px 40px'
                        }}></div>
                      </div>
                    </div>

                    {/* Certificate Content */}
                    <div className="relative h-full flex flex-col items-center justify-center p-8">
                      {/* Top Border Design */}
                      <div className="absolute top-0 left-0 right-0 h-3 bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600"></div>
                      
                      {/* Certificate Frame */}
                      <div className="border-[3px] border-blue-800/20 rounded-xl p-6 bg-white/90 backdrop-blur-sm w-[90%] text-center">
                        <div className="mb-4">
                          <Award className="h-16 w-16 mx-auto text-blue-800 opacity-90" />
                        </div>
                        
                        <div className="space-y-4">
                          <div className="text-sm uppercase tracking-[0.2em] text-blue-800/70 font-bold">
                            Certificate of Completion
                          </div>
                          
                          <div className="h-px bg-gradient-to-r from-transparent via-blue-800/20 to-transparent"></div>
                          
                          <div className="text-xl font-serif text-gray-900 font-bold px-4">
                            {course.course_title}
                          </div>
                          
                          <div className="text-sm text-gray-600">
                            This certifies the completion of all course requirements
                          </div>
                        </div>
                      </div>

                      {/* Decorative Elements */}
                      <div className="absolute bottom-6 left-6 right-6 flex justify-between">
                        <div className="h-16 w-16 rounded-full bg-blue-800/5 backdrop-blur-sm border border-blue-800/10"></div>
                        <div className="h-16 w-16 rounded-full bg-purple-800/5 backdrop-blur-sm border border-purple-800/10"></div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Certificate Details */}
                <div className="lg:w-1/2 space-y-6">
                  <div>
                    <h3 className="text-xl font-bold text-gray-800 mb-3">
                      Earn a Professional Certificate
                    </h3>
                    <p className="text-gray-600">
                      Showcase your achievement with a verified certificate that validates your expertise. Perfect for your professional portfolio and career advancement.
                    </p>
                  </div>

                  {/* Certificate Features */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {[
                      { title: "Verified & Unique", text: "Each certificate has a unique verification ID" },
                      { title: "Easy Sharing", text: "Share directly to LinkedIn and other platforms" },
                      { title: "Digital Format", text: "Access your certificate anywhere, anytime" },
                      { title: "Lifetime Valid", text: "Your achievement is permanently recorded" }
                    ].map((feature, index) => (
                      <div key={index} className="bg-gray-50 rounded-xl p-4 hover:bg-gray-100 transition-colors">
                        <div className="flex items-start space-x-3">
                          <span className="text-2xl">{feature.icon}</span>
                          <div>
                            <h4 className="font-semibold text-gray-800">{feature.title}</h4>
                            <p className="text-sm text-gray-600 mt-1">{feature.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Requirements Notice */}
                  {course.certificateRequirements && (
                    <div className="bg-amber-50 rounded-xl p-5 border border-amber-100">
                      <h4 className="text-sm font-semibold text-amber-900 mb-2 flex items-center">
                        <FaRegLightbulb className="mr-2" />
                        Certificate Requirements
                      </h4>
                      <p className="text-sm text-amber-800">{course.certificateRequirements}</p>
                    </div>
                  )}
                </div>
              </div>
            </section>

            {/* Syllabus Section */}
            <section className="mb-6 bg-white p-6 sm:p-8 rounded-xl shadow-md border border-gray-100">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Course Content</h2>
              
              <div className="bg-gray-50 p-4 rounded-lg mb-6">
                <div className="flex flex-wrap justify-between items-center">
                  <div className="mb-2 sm:mb-0">
                    <span className="font-semibold text-gray-700">{course.modules?.length || 0} modules</span>
                    <span className="mx-2 text-gray-400">•</span>
                    <span className="text-gray-700">
                      {course.modules?.reduce((total, module) => total + (module.lessons?.length || 0), 0) || 0} lessons
                    </span>
                  </div>
                  
                  <div className="flex gap-2">
                    <button 
                      className="text-sm text-blue-800 hover:text-blue-600 font-medium"
                      onClick={() => setActiveIndices([...Array(course.modules?.length || 0).keys()])}
                    >
                      Expand All
                    </button>
                    <span className="text-gray-400">|</span>
                    <button 
                      className="text-sm text-blue-800 hover:text-blue-600 font-medium"
                      onClick={() => setActiveIndices([])}
                    >
                      Collapse All
                    </button>
                  </div>
                </div>
              </div>
              
              <div className="space-y-4">
                {Array.isArray(course.modules) && course.modules.length > 0 ? (
                  course.modules.map((module, index) => (
                    <div 
                      key={index} 
                      className="border border-gray-200 rounded-lg overflow-hidden bg-white hover:border-gray-300 transition-colors"
                    >
                      <button
                        onClick={() => toggleAccordion(index)}
                        className="flex items-center justify-between w-full px-6 py-4 text-left bg-gray-50 hover:bg-gray-100 transition-colors"
                      >
                        <div className="flex items-center">
                          <div className="flex-shrink-0 bg-blue-800 text-white w-8 h-8 rounded-full flex items-center justify-center mr-4 text-sm font-bold">
                            {index + 1}
                          </div>
                          <div>
                            <h3 className="font-semibold text-gray-800">{module.module_title}</h3>
                            <p className="text-xs text-gray-500 mt-1">
                              {module.lessons?.length || 0} lessons • {module.completionTime ? formatDuration(module.completionTime) : 'Self-paced'}
                            </p>
                          </div>
                        </div>
                        {activeIndices.includes(index) ? (
                          <ChevronUp className="h-5 w-5 text-gray-500" />
                        ) : (
                          <ChevronDown className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                      
                      {activeIndices.includes(index) && (
                        <div className="px-6 py-3">
                          {Array.isArray(module.lessons) && module.lessons.length > 0 ? (
                            <ul className="divide-y divide-gray-100">
                              {module.lessons.map((lesson, idx) => (
                                <li
                                  key={idx}
                                  className="py-3 flex justify-between items-center group"
                                >
                                  <div className="flex items-center">
                                    <div className="w-6 h-6 mr-3 flex-shrink-0 rounded-full bg-gray-100 flex items-center justify-center text-xs text-gray-600 group-hover:bg-blue-100 transition-colors">
                                      {idx + 1}
                                    </div>
                                    <span className="text-gray-700 group-hover:text-blue-800 transition-colors">
                                      {lesson.lesson_title}
                                    </span>
                                  </div>
                                  <div className="flex items-center">
                                    <span className="text-xs text-gray-500 mr-3">{lesson.duration ? formatDuration(lesson.duration) : '—'}</span>
                                    {!isEnrolled && (
                                      <FaLock className="text-gray-400 h-3 w-3" />
                                    )}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p className="text-gray-500 text-sm py-2">No lessons available for this module.</p>
                          )}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="text-center p-6 bg-gray-50 rounded-lg">
                    <p className="text-gray-500">No syllabus information available for this course.</p>
                  </div>
                )}
              </div>
            </section>
          </div>

          {/* Sidebar */}
          <aside>
            <div className="sticky top-6">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-200 hover:shadow-xl transition-shadow duration-300">
                {/* Course Card with Gradient */}
                <div className="relative h-48" style={{ background: `linear-gradient(135deg, ${color1}, ${color2})` }}>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <h2 className="text-2xl font-bold text-white text-center px-4 drop-shadow-lg">
                      {course.course_title}
                      {isGeneratedCourse && (
                        <div className="text-sm font-normal mt-2 bg-white/20 backdrop-blur-sm rounded-full px-3 py-1 inline-block">
                          AI-Generated Course
                        </div>
                      )}
                    </h2>
                  </div>
                </div>
                
                <div className="p-6">
                  {/* Progress indicator for enrolled users */}
                  {isEnrolled && enrollmentData && (
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-sm font-medium text-gray-700">Your progress</span>
                        <span className="text-sm font-semibold text-blue-800">{enrollmentData.progress || 0}%</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div 
                          className="h-2.5 rounded-full" 
                          style={{ 
                            width: `${enrollmentData.progress || 0}%`,
                            backgroundColor: enrollmentData.progress === 100 ? '#16a34a' : '#3b82f6'
                          }}
                        />
                      </div>
                    </div>
                  )}
                  
                  {/* Action Button */}
                  <button
                    onClick={handleStartLearning}
                    className={`w-full py-3 px-4 rounded-lg font-medium flex items-center justify-center ${
                      isEnrolled 
                        ? 'bg-blue-800 hover:bg-blue-700 text-white' 
                        : 'bg-blue-800 hover:bg-blue-700 text-white'
                    } transition-colors duration-300`}
                  >
                    <FaBookReader className="mr-2" />
                    {isEnrolled ? 'Continue Learning' : 'Start Learning'}
                  </button>
                  
                  {/* Share This Course */}
                  <div className="mt-4 relative">
                    <div className="flex items-center justify-between">
                      <span className="text-sm font-medium text-gray-700">Share this course</span>
                      <div className="relative">
                        <button
                          onClick={() => setShowShareOptions(!showShareOptions)}
                          className="p-2 text-gray-500 hover:text-blue-800 hover:bg-blue-50 rounded-full transition-colors"
                          aria-label="Share this course"
                        >
                          <FaShareAlt className="h-4 w-4" />
                        </button>
                        
                        {showShareOptions && (
                          <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-10 border border-gray-200">
                            <button
                              onClick={() => handleShare('native')}
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            >
                              <Share2 className="h-4 w-4 mr-3" />
                              Share
                            </button>
                            <button
                              onClick={() => handleShare('facebook')}
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            >
                              <FaFacebook className="h-4 w-4 mr-3 text-blue-600" />
                              Facebook
                            </button>
                            <button
                              onClick={() => handleShare('twitter')}
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            >
                              <FaTwitter className="h-4 w-4 mr-3 text-blue-400" />
                              Twitter
                            </button>
                            <button
                              onClick={() => handleShare('linkedin')}
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            >
                              <FaLinkedin className="h-4 w-4 mr-3 text-blue-700" />
                              LinkedIn
                            </button>
                            <button
                              onClick={() => handleShare('copy')}
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            >
                              <FaLink className="h-4 w-4 mr-3 text-gray-500" />
                              Copy Link
                              {copySuccess && <span className="ml-2 text-xs text-green-600">{copySuccess}</span>}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    
                    {/* Social Share Icons */}
                    <div className="flex mt-2 space-x-2">
                      <button
                        onClick={() => handleShare('facebook')}
                        className="p-2 rounded-full bg-gray-100 hover:bg-blue-100 text-gray-600 hover:text-blue-600 transition-colors"
                        aria-label="Share on Facebook"
                      >
                        <FaFacebook className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleShare('twitter')}
                        className="p-2 rounded-full bg-gray-100 hover:bg-blue-100 text-gray-600 hover:text-blue-400 transition-colors"
                        aria-label="Share on Twitter"
                      >
                        <FaTwitter className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleShare('linkedin')}
                        className="p-2 rounded-full bg-gray-100 hover:bg-blue-100 text-gray-600 hover:text-blue-700 transition-colors"
                        aria-label="Share on LinkedIn"
                      >
                        <FaLinkedin className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleShare('copy')}
                        className="p-2 rounded-full bg-gray-100 hover:bg-blue-100 text-gray-600 hover:text-blue-800 transition-colors"
                        aria-label="Copy link"
                      >
                        <FaLink className="h-4 w-4" />
                      </button>
                    </div>
                    {copySuccess && (
                      <div className="mt-1 text-xs text-green-600 text-center">{copySuccess}</div>
                    )}
                  </div>
                  
                  {/* Course Stats */}
                  <div className="mt-6 space-y-3">
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-gray-600">Level:</span>
                      <span className="font-medium text-gray-800">{course.level || 'All Levels'}</span>
                    </div>
                    
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-gray-600">Duration:</span>
                      <span className="font-medium text-gray-800">{course.duration ? formatDuration(course.duration) : 'Self-paced'}</span>
                    </div>
                    
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-gray-600">Certificate:</span>
                      <span className="font-medium text-gray-800">{course.certificates || 'No'}</span>
                    </div>
                    
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-gray-600">Language:</span>
                      <span className="font-medium text-gray-800">{course.language || 'English'}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </main>
    </div>
  );
}
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import HeroSection from '../components/hero/HeroSection';
import CTASection from '../components/cta/CTASection';
import FeaturePoint from '../components/home/FeaturePoint';
import LearnPractice from '../components/home/LearnPractice';
import PromotionalLine from '../components/home/PromotionalLine';

// Advanced SEO metadata
const meta = {
  title: 'The Fastest Way To Learn | 1xSkills',
  meta: [
    // Primary meta tags
    { name: 'description', content: '1xSkills is an AI powered learning campanion for your career success.' },
    { name: 'keywords', content: 'learn fast, online courses, career development, skill acquisition, programming skills, tech skills, online learning platform' },
    
    // Open Graph tags for social sharing
    { property: 'og:title', content: 'The Fastest Way To Learn | 1xSkills' },
    { property: 'og:description', content: '1xSkills is an AI powered learning campanion for your career success.' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://1xskills.com' },
    { property: 'og:image', content: 'https://1xskills.com/images/og-image.jpg' },
    { property: 'og:image:alt', content: '1xSkills - Fast Learning Platform' },
    { property: 'og:site_name', content: '1xSkills' },
    { property: 'og:locale', content: 'en_US' },
    
    // Twitter Card tags
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@1xSkills' },
    { name: 'twitter:creator', content: '@1xSkills' },
    { name: 'twitter:title', content: 'The Fastest Way To Learn | 1xSkills' },
    { name: 'twitter:description', content: '1xSkills is an AI powered learning campanion for your career success.' },
    { name: 'twitter:image', content: 'https://1xskills.com/images/twitter-image.jpg' },
    
    // Additional SEO tags
    { name: 'robots', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' },
    { name: 'author', content: '1xSkills Team' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
    { httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8' },
    { name: 'theme-color', content: '#1E40AF' }, // Match your brand color
    
    // Mobile app capability tags
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
    { name: 'apple-mobile-web-app-title', content: '1xSkills' }
  ],
  link: [
    // Canonical URL to prevent duplicate content issues
    { rel: 'canonical', href: 'https://1xskills.com' },
    
  ]
};

export default function Index() {
  // JSON-LD structured data for rich search results
  const jsonLd = {
    "@context": "https://schema.org",
    "@graph": [
      // Website schema
      {
        "@type": "WebSite",
        "@id": "https://1xskills.com/#website",
        "url": "https://1xskills.com/",
        "name": "1xSkills",
        "description": "The Fastest Way To Learn",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://1xskills.com/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      },
      // Organization schema
      {
        "@type": "Organization",
        "@id": "https://1xskills.com/#organization",
        "name": "1xSkills",
        "url": "https://1xskills.com/",
        "logo": {
          "@type": "ImageObject",
          "url": "/1xskills-logo.webp",
          "width": 600,
          "height": 60
        },
        "sameAs": [
          "https://www.facebook.com/1xskills",
          "https://twitter.com/1xSkills",
          "https://www.linkedin.com/company/1xskills",
          "https://www.instagram.com/1xskills/"
        ]
      },
      // Educational website specific schema
      {
        "@type": "EducationalOrganization",
        "@id": "https://1xskills.com/#education",
        "name": "1xSkills",
        "description": "1xSkills is an AI powered learning companion for your career success.",
        "url": "https://1xskills.com/",
      }
    ]
  };

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}>
          {/* JSON-LD structured data for rich search results */}
          <script type="application/ld+json">
            {JSON.stringify(jsonLd)}
          </script>
          
          {/* Preconnect to important third-party domains for performance */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
          
          {/* Additional meta tags for SEO */}
          <html lang="en" />
        </Helmet>
      </HelmetProvider>
      
      {/* Semantic markup with appropriate heading hierarchy */}
      <main>
        <HeroSection />
        <FeaturePoint/>
        <LearnPractice/>
        <PromotionalLine/>
        <CTASection />
      </main>
    </React.Fragment>
  );
}


import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AllCourses from '../components/courses/AllCourses';

// Advanced SEO metadata for courses page
const meta = {
  title: 'Learn In-Demand Skills | Explore All Courses | 1xSkills',
  meta: [
    // Primary meta tags
    { name: 'description', content: 'Discover and enroll in our comprehensive collection of courses designed to help you master new skills. Learn at your own pace with expert-led content and hands-on projects.' },
    { name: 'keywords', content: 'online courses, learning platform, skill development, career advancement, tech courses, programming courses, professional skills, learn online, online education, tech skills' },
    
    // Open Graph tags for social sharing
    { property: 'og:title', content: 'Learn In-Demand Skills | Explore All Courses | 1xSkills' },
    { property: 'og:description', content: 'Discover and enroll in our comprehensive collection of courses designed to help you master new skills. Learn at your own pace with expert-led content.' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://1xskills.com/courses' },
    { property: 'og:image', content: 'https://1xskills.com/images/courses-og-image.jpg' },
    { property: 'og:image:alt', content: '1xSkills Courses Catalog' },
    { property: 'og:site_name', content: '1xSkills' },
    
    // Twitter Card tags
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: 'Learn In-Demand Skills | Explore All Courses | 1xSkills' },
    { name: 'twitter:description', content: 'Discover and enroll in our comprehensive collection of courses designed to help you master new skills. Learn at your own pace with expert-led content.' },
    { name: 'twitter:image', content: 'https://1xskills.com/images/courses-twitter-image.jpg' },
    
    // Additional technical SEO tags
    { name: 'robots', content: 'index, follow, max-image-preview:large' },
  ],
  link: [
    // Canonical URL to prevent duplicate content issues
    { rel: 'canonical', href: 'https://1xskills.com/courses' },
  ],
};

export default function CoursesPage() {
  // JSON-LD structured data for course listing
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Course Catalog",
        "url": "https://1xskills.com/courses"
      }
    ],
    "numberOfItems": 1
  };

  const courseListingSchema = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": "1xSkills Course Directory",
    "description": "Browse our collection of online courses to develop in-demand skills",
    "url": "https://1xskills.com/courses",
    "provider": {
      "@type": "Organization",
      "name": "1xSkills",
      "sameAs": "https://1xskills.com"
    },
    "about": {
      "@type": "Thing",
      "name": "Online Courses"
    },
    "audience": {
      "@type": "Audience",
      "audienceType": "Professional learners and students"
    }
  };
  
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}>
          {/* JSON-LD structured data for rich search results */}
          <script type="application/ld+json">
            {JSON.stringify(jsonLd)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(courseListingSchema)}
          </script>
          
          {/* Additional meta tags for SEO */}
          <html lang="en" />
          
          {/* Preload key resources */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        </Helmet>
      </HelmetProvider>
      
      {/* Main course listing with semantic HTML */}
      <main id="main-content">
        <AllCourses />
      </main>
    </React.Fragment>
  );
}

